import React, { useEffect, useState } from "react";
import "../profile.css";
import "./rentalPoints.css";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import SubtitleBlockSection from "../WidgetsProfile/SubtitleBlockSection/SubtitleBlockSection";
import WindowEditItemTimetable from "./WindowEditItemTimetable";
import { useSelector } from "react-redux";
import { getWeekDayName } from "./functions/getWeekDayName";
import WindowEditTimetableModeration from "../Moderation/WindowEditTimetableModeration";

const TimeWeekDayItem = ({
  dayWeek,
  openingHours,
  description,
  isHoliday,
  onClick,
}) => {
  const weekdayName = getWeekDayName(dayWeek);

  return (
    <div
      className="rentalPointTimetable__week-day-content-box"
      onClick={onClick}
    >
      <div className="rentalPointTimetable__week-day">
        <p className="p-light">
          #{dayWeek} {weekdayName}
        </p>
        {isHoliday ? <p>Holiday</p> : <p>{openingHours}</p>}
        {!isHoliday && description && <p className="p-light">{description}</p>}
      </div>
      <div className="rentalPointTimetable__icon">
        <span className="material-symbols-outlined">edit</span>
      </div>
    </div>
  );
};

const WidgetRentalPointTimetable = ({ mode, timetable }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [indexDay, setIndexDay] = useState(0);

  const [isOpenRentalPoint, setIsOpenRentalPoint] = useState(false);
  const [isOpenModeration, setIsOpenModeration] = useState(false);

  const handleClose = () => {
    setIsOpenRentalPoint(false);
    setIsOpenModeration(false);
  };

  const handleToggle = (index) => {
    if (mode === "new" || mode === "edit") {
      setIsOpenRentalPoint(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setIsOpenModeration(true);
    }
    setIndexDay(index);
  };

  return (
    <>
      <PopupDirrectory
        isOpen={isOpenRentalPoint}
        onClose={handleClose}
        title="Edit timetable"
      >
        <WindowEditItemTimetable
          mode={mode}
          indexDay={indexDay}
          onClose={handleClose}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenModeration}
        onClose={handleClose}
        title="Edit timetable Moderation"
      >
        <WindowEditTimetableModeration
          mode={mode}
          indexDay={indexDay}
          onClose={handleClose}
        />
      </PopupDirrectory>
      <div className="profile-widget__content-box">
        <SubtitleBlockSection
          title="Timetable"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
        <div className="rental-point-timetable">
          {timetable?.map((timetableItem, index) => (
            <TimeWeekDayItem
              key={index}
              dayWeek={index + 1}
              openingHours={
                <span>
                  from:&nbsp;
                  <strong>
                    {timetableItem?.openHours}:{timetableItem?.openMinutes}
                  </strong>
                  &nbsp;to:&nbsp;
                  <strong>
                    {timetableItem?.closeHours}:{timetableItem?.closeMinutes}
                  </strong>
                </span>
              }
              isHoliday={timetableItem?.isHoliday}
              description={
                timetableItem?.description?.descriptionOriginal || ""
              }
              onClick={() =>
                handleToggle(
                  index
                  // timetableItem.open,
                  // timetableItem.close,
                  // timetableItem.isHoliday
                )
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default WidgetRentalPointTimetable;
