import React, { useState } from "react";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import WidgetAlert from "../WidgetsProfile/Alerts/WidgetAlert";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderedDetailV4 from "./WindowOrderedDetailV4";

const WindowOrderDetailBikeInfoV4 = ({ onClose }) => {
  const [windowOrderedDetailV4, setWindowOrderedDetailV4] = useState(false);
  /**
   *
   * Логика компонента
   *
   */
  const handleWindowOrderedDetailV4 = () => {
    setWindowOrderedDetailV4(true);
  };
  const handleClose = () => {
    setWindowOrderedDetailV4(false);
  };
  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={windowOrderedDetailV4}
        onClose={handleClose}
        title={`Order detail`}
      >
        <WindowOrderedDetailV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <h1>Bike info</h1>
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={onClose}
            color="--color-black"
          />

          <Button
            type="small"
            label="Create order"
            active={true}
            onClick={handleWindowOrderedDetailV4}
            // allert={
            //   <WidgetAlert
            //     title={`Are you sure you want to add the page to the directory?`}
            //   />
            // }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderDetailBikeInfoV4;
