import React, { useState, useEffect } from "react";
import PopupDropdownList from "../Popups/PopupDropdownList";
import "./dropdownList.css";

const DropdownList = ({
  options,
  onSelect,
  label,
  description,
  fieldView,
  fieldId,
  initialSelectedOption,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(true);
  };

  const handleCloseDropdownList = () => {
    setIsOpen(false);
  };

  return (
    <>
      {type === "small" ? (
        <div>
          <div className="dropdown-list__small" onClick={handleToggle}>
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "4px 10px",
              }}
              className="p-light"
            >
              {selectedOption ? selectedOption[fieldView] : label}
            </p>
          </div>
          {description && (
            <div
              style={{
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "70%",
                }}
                className="p-light"
              >
                {description}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          className="profile-widget__content-box"
          style={{
            gap: "8px",
          }}
        >
          <div className="dropdown-list" onClick={handleToggle}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "0 16px",
              }}
            >
              {selectedOption ? selectedOption[fieldView] : label}
              <span>
                {isOpen ? (
                  <span className="material-symbols-outlined">unfold_more</span>
                ) : (
                  <span className="material-symbols-outlined">unfold_more</span>
                )}
              </span>
            </div>
          </div>
          {description && (
            <div
              style={{
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "70%",
                }}
                className="p-light"
              >
                {description}
              </p>
            </div>
          )}
        </div>
      )}

      <PopupDropdownList
        isOpen={isOpen}
        onClose={handleCloseDropdownList}
        title={label}
      >
        {isOpen && (
          <ul className="dropdown-list__options">
            {options.map((option) => (
              <li
                key={option[fieldId]}
                onClick={() => handleOptionSelect(option)}
                style={{
                  ...(selectedOption &&
                    selectedOption[fieldId] === option[fieldId] && {
                      backgroundColor: "var(--color-gray-100)",
                      fontWeight: "500",
                    }),
                }}
              >
                {option[fieldView]}
                {option.title.titleOriginal}
              </li>
            ))}
          </ul>
        )}
      </PopupDropdownList>
    </>
  );
};

export default DropdownList;

// import React, { useState, useEffect } from "react";
// import PopupDropdownList from "../Popups/PopupDropdownList";
// import "./dropdownList.css";

// // Функция для доступа к вложенным полям объекта по строке вида "field1.field2.field3"
// const getNestedFieldValue = (obj, field) => {
//   return field.split(".").reduce((acc, part) => acc && acc[part], obj);
// };

// const DropdownList = ({
//   options,
//   onSelect,
//   label,
//   description,
//   fieldView,
//   fieldId,
//   initialSelectedOption,
//   type,
// }) => {
//   console.log("options", options);
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

//   useEffect(() => {
//     setSelectedOption(initialSelectedOption);
//   }, [initialSelectedOption]);

//   const handleOptionSelect = (option) => {
//     setSelectedOption(option);
//     onSelect(option);
//     setIsOpen(false);
//   };

//   const handleToggle = () => {
//     setIsOpen(true);
//   };

//   const handleCloseDropdownList = () => {
//     setIsOpen(false);
//   };

//   return (
//     <>
//       {type === "small" ? (
//         <div>
//           <div className="dropdown-list__small" onClick={handleToggle}>
//             <p
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 width: "100%",
//                 padding: "4px 10px",
//               }}
//               className="p-light"
//             >
//               {selectedOption
//                 ? getNestedFieldValue(selectedOption, fieldView)
//                 : label}
//             </p>
//           </div>
//           {description && (
//             <div
//               style={{
//                 width: "100%",
//               }}
//             >
//               <p
//                 style={{
//                   width: "70%",
//                 }}
//                 className="p-light"
//               >
//                 {description}
//               </p>
//             </div>
//           )}
//         </div>
//       ) : (
//         <div
//           className="profile-widget__content-box"
//           style={{
//             gap: "8px",
//           }}
//         >
//           <div className="dropdown-list" onClick={handleToggle}>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 width: "100%",
//                 padding: "0 16px",
//               }}
//             >
//               {selectedOption
//                 ? getNestedFieldValue(selectedOption, fieldView)
//                 : label}
//               <span>
//                 {isOpen ? (
//                   <span className="material-symbols-outlined">unfold_more</span>
//                 ) : (
//                   <span className="material-symbols-outlined">unfold_more</span>
//                 )}
//               </span>
//             </div>
//           </div>
//           {description && (
//             <div
//               style={{
//                 width: "100%",
//               }}
//             >
//               <p
//                 style={{
//                   width: "70%",
//                 }}
//                 className="p-light"
//               >
//                 {description}
//               </p>
//             </div>
//           )}
//         </div>
//       )}

//       <PopupDropdownList
//         isOpen={isOpen}
//         onClose={handleCloseDropdownList}
//         title={label}
//       >
//         {isOpen && (
//           <ul className="dropdown-list__options">
//             {options.map((option) => (
//               <li
//                 key={option[fieldId]}
//                 onClick={() => handleOptionSelect(option)}
//                 style={{
//                   ...(selectedOption &&
//                     selectedOption[fieldId] === option[fieldId] && {
//                       backgroundColor: "var(--color-gray-100)",
//                       fontWeight: "500",
//                     }),
//                 }}
//               >
//                 {getNestedFieldValue(option, fieldView)}
//               </li>
//             ))}
//           </ul>
//         )}
//       </PopupDropdownList>
//     </>
//   );
// };

// export default DropdownList;
