import React from "react";
import SegmentedControls from "../../Private/Login/Profile/WidgetsProfile/SegmentedControls/SegmentedControls";

const WindowLanguage = () => {
  return (
    <div>
      <SegmentedControls
        options={["users", "rental points", "bikes"]}
        // onSelect={moderationType}
        // selected={setModerationType}
      />
    </div>
  );
};

export default WindowLanguage;
