import React, { useState, useEffect } from "react";
import WigetLanguage from "./WigetLanguage";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import { useLocation } from "react-router-dom";
import WIgetError from "../../Public/UniversalWidgets/WigetError";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../../UIElements/Preloader";

const imgBg = [
  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Flogin3.jpg?alt=media&token=a2fa75ea-bb8d-4439-a00d-4fdf44b79af3&_gl=1*uurao9*_ga*MTYxOTY1MDE1OS4xNjk3Mzc2NDMy*_ga_CW55HF8NVT*MTY5NzM3NjQzMi4xLjEuMTY5NzM4MDk4Mi4xNi4wLjA.",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Flogin10.jpg?alt=media&token=3aec2660-2c9d-412f-97ea-0fac20ce4441&_gl=1*zfkt2e*_ga*MTYxOTY1MDE1OS4xNjk3Mzc2NDMy*_ga_CW55HF8NVT*MTY5NzM5MjU2My4zLjEuMTY5NzM5NTIzOC41Ny4wLjA.",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Flogin15.jpg?alt=media&token=c3da8fa8-90f3-4127-8109-ebceaa39437e&_gl=1*10giymg*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzU0NDU2OC45LjEuMTY5NzU0NDg0Mi4xMC4wLjA.",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Flogin16.jpg?alt=media&token=b03e87c4-fb29-4af6-a832-2b55417595ab&_gl=1*1iy20j8*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzU0NDU2OC45LjEuMTY5NzU0NTExMS4yMS4wLjA.",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Flogin1.jpg?alt=media&token=d43d3e21-cb89-47c8-8d90-a3464238c2fb&_gl=1*w0ybrz*_ga*MTYxOTY1MDE1OS4xNjk3Mzc2NDMy*_ga_CW55HF8NVT*MTY5NzM3NjQzMi4xLjEuMTY5NzM4MDk1Mi40Ni4wLjA.",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Flogin17.jpg?alt=media&token=e80a998c-acfd-41b8-9837-b7f5b5566aec&_gl=1*e8qkd0*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzU0NDU2OC45LjEuMTY5NzU0NTE0Ny41MS4wLjA.",

  "https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2Flogin19.jpg?alt=media&token=5c102bd3-e4ac-4928-bf48-11cebe6d269f&_gl=1*1pfofi4*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5NzU0NDU2OC45LjEuMTY5NzU0NTE3OC4yMC4wLjA.",
];

const UILogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get("page");

  const errorMessage = useSelector((state) => state.userSliceV2.errorMessage);
  const isLoading = useSelector((state) => state.userSliceV2.isLoading);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imgBg.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleResetError = () => {
    // dispatch(setErrorAuthUser(null));
  };

  const currentImageUrl = imgBg[currentImageIndex];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <>
      <WIgetError error={errorMessage} onClose={() => handleResetError()} />

      <div
        style={{
          width: "100%",
          height: "calc(100vh)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage:
            "url(https://firebasestorage.googleapis.com/v0/b/testbikestripster.appspot.com/o/imgStatic%2FLogoBFYTStart3.svg?alt=media&token=d83e1a15-c877-4987-bbb1-1ab9c3b962c2&_gl=1*3biakt*_ga*NzE3MzY1ODU1LjE2ODQ3NTEzMTQ.*_ga_CW55HF8NVT*MTY5ODQwOTc2MC4zOC4xLjE2OTg0MTAxMjUuNTQuMC4w)",
          backgroundSize: "auto 80%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "80% center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 156px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-white)",
              boxSizing: "border-box",
              width: "var(--widthContent)",
              aspectRatio: "16/9",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              // gap: '32px',
              borderRadius: "24px",
              overflow: "hidden",
              padding: "8px",
            }}
          >
            {isLoading ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                }}
              >
                <Preloader />
                <p className="p-light">
                  Please wait while we
                  <br /> process your request.
                </p>
              </div>
            ) : (
              <>
                {page === "signIn" ? (
                  <SignIn />
                ) : page === "signUp" ? (
                  <SignUp />
                ) : null}
              </>
            )}

            <div
              style={{
                gridColumn: "span 2",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "var(--color-gray-200)",
                backgroundImage: `url(${currentImageUrl})`,
                height: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  height: "100%",
                  width: "100%",
                  padding: "40px",
                  boxSizing: "border-box",
                }}
              >
                <img
                  style={{
                    width: "120px",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripsterWhite.svg?alt=media&token=3409bcaf-ad17-47ff-b3c5-eb83971e650a"
                  alt="logoId"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "24px",
                  boxSizing: "border-box",
                  color: "var(--color-white)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "var(--widthContent)",
              boxSizing: "border-box",
              padding: "0 ",
            }}
          >
            <WigetLanguage />

            <p className="p-mini">Bike For You Trip © 2022 — {currentYear}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UILogin;
