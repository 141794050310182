import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import WidgetOrderBikeInfo from "./WidgetOrderBikeInfo";
import Button from "../WidgetsProfile/Buttons/Button";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import WidgetOrderDateInterl from "./WidgetOrderDateInterl";
import WidgetOrderAdditionalServicesList from "./WidgetOrderAdditionalServicesList";
import WidgetOrderAditionalServicesTotal from "./WidgetOrderAditionalServicesTotal";
import Counter from "../WidgetsProfile/Counter/Counter";
import {
  setOrderStatus,
  setOrderTotals,
  setQuantityBikes,
} from "../../../../../reducersToolkit/orderSliceV2";
import { createOrderThunkV2 } from "../../../../../reducersToolkit/thunk/orders/createOrderThunkV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import WindowCalendar from "../WidgetsProfile/Date/WindowCalendar";
import DateInterval from "../WidgetsProfile/Date/DateInterval";

const WindowOrderDetailModeration = ({ mode, onClose, closeParent }) => {
  const dispatch = useDispatch();

  const { tempData, tempPreviewData, tempRootData, rootOrderData } =
    useSelector((state) => state.catalogSliceV4);

  // User
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Order details
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);

  // RentalPoint
  const [rentalPoint, setRentalPoint] = useState(null);

  // Ordered Bike
  const [orderedBike, setOrderedBike] = useState(null);

  const [quantityLimitBike, setQuantityLimitBike] = useState(1);
  const [quantityBike, setQuantityBike] = useState(1);

  // const [countryOptions, setCountryOptions] = useState([]);
  // const [cityOptions, setCityOptions] = useState([]);

  // const [selectedCountry, setSelectedCountry] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);

  // const [countryId, setCountryId] = useState(null);
  // const [cityId, setCityId] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [totals, setTotals] = useState({});
  const [countBikes, setCountBikes] = useState(0);
  console.log("WindowOrderDetailModeration count", countBikes);

  /**
   *
   * Наполнение useState
   *
   */

  useEffect(() => {
    setSelectedUserId(
      tempPreviewData.user_moderation_selectedPreviewCurrentData
        ?.idRootDocument || null
    );
  }, [
    tempPreviewData.user_moderation_selectedPreviewCurrentData?.idRootDocument,
  ]);

  // Rental Point
  useEffect(() => {
    setRentalPoint(tempRootData?.rentalPoint_moderation_rootRerntalPoint);

    setCity(
      tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location?.city ||
        null
    );
    setCountry(
      tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location
        ?.country || null
    );
  }, [tempRootData?.rentalPoint_moderation_rootRerntalPoint]);

  // Bike
  useEffect(() => {
    setOrderedBike(tempData?.bike_moderation_orderedBike || null);
    setQuantityLimitBike(
      tempData?.bike_moderation_orderedBike?.metricsBike?.quantityBikes || 1
    );
  }, [tempData?.bike_moderation_orderedBike]);

  /*********** */

  console.log("orderedBike_rootData 100", tempData.bike_moderation_orderedBike);
  console.log(
    "orderedBike_rootData 200",
    tempRootData?.rentalPoint_moderation_rootRerntalPoint
  );

  // useEffect(() => {
  //   if (newOrder?.orderBike?.directory?.brandId && brands?.length > 0) {
  //     const foundBrand = brands?.find(
  //       (brand) => brand?.id === newOrder?.orderBike?.directory?.brandId
  //     );
  //     setSelectedBrand(foundBrand || null);
  //   }
  // }, [newOrder, brands]);

  // useEffect(() => {
  //   if (newOrder?.orderBike?.directory?.categoryId && categories?.length > 0) {
  //     const foundCategory = categories?.find(
  //       (category) =>
  //         category?.id === newOrder?.orderBike?.directory?.categoryId
  //     );
  //     setSelectedCategory(foundCategory || null);
  //   }
  // }, [newOrder, categories]);

  // useEffect(() => {
  //   // Убедитесь, что countries является массивом, прежде чем вызывать map
  //   const formattedCountries = Array.isArray(countries)
  //     ? countries.map((country) => ({
  //         id: country?.id || "",
  //         title: country?.title?.titleOriginal || "",
  //       }))
  //     : [];

  //   setCountryOptions(formattedCountries);

  //   // Убедитесь, что cities является массивом, прежде чем вызывать map
  //   const formattedCities = Array.isArray(cities)
  //     ? cities.map((city) => ({
  //         id: city.id,
  //         title: city?.title?.titleOriginal,
  //       }))
  //     : [];

  //   setCityOptions(formattedCities);

  //   const initialCountry = formattedCountries?.find(
  //     (country) => country?.id === countryId
  //   );

  //   const initialCity = formattedCities?.find((city) => city?.id === cityId);

  //   setSelectedCountry(initialCountry || null);
  //   setSelectedCity(initialCity || null);
  // }, [countries, cities, countryId, cityId]);

  // useEffect(() => {
  //   setCountryId(newOrder?.orderRentalPoint?.location?.countryId || null);
  //   setCityId(newOrder?.orderRentalPoint?.location?.cityId || null);
  //   setQuantityLimitBike(newOrder?.orderBike?.quantityLimitBike?.quantityBikes || 1);
  //   setAdditionalServices(
  //     newOrder?.orderRentalPoint?.additionalServices?.services || []
  //   );
  //   setCity(newOrder?.orderBike?.rentalPoint?.location?.city || {});
  //   setCountry(newOrder?.orderBike?.rentalPoint?.location?.country || null);
  // }, [newOrder]);

  // useEffect(() => {
  //   dispatch(setQuantityBikes({ mode: "new", quantityBike: quantityBike }));
  // }, [dispatch, quantityBike]);

  // useEffect(() => {
  //   dispatch(setOrderTotals({ mode: "new", totals: totals }));
  // }, [dispatch, mode, totals]);

  const createOrder = () => {
    // dispatch(
    //   createOrderThunkV2({ orderData: newOrder, userId: currentUser.id })
    // );
    closeParent();
  };

  const onChange = (newCount) => {
    setCountBikes(newCount);
  };

  const close = () => {
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <DateInterval mode="new" onClose={onClose} dates={[0, 0]} />
          <Counter
            minValue={1}
            maxValue={2}
            value={1}
            onChange={(newCount) => onChange(newCount)}
            label={`Quntity bikes`}
            description={`Cabbage soup available`}
          />
          <WidgetOrderBikeInfo
            // order={newOrder}
            countBikes={countBikes}
            orderedBike={orderedBike}
            selectedBrand={selectedBrand}
            selectedCategory={selectedCategory}
            orderedAditionalServices={additionalServices}
            setTotals={setTotals}
            quantityLimitBike={quantityLimitBike}
            quantityBike={quantityBike}
            onChange={setQuantityBike}
            // order={newOrder}
          />
        </ContentBox>

        {additionalServices.length > 0 && (
          <ContentBox>
            <WidgetOrderAdditionalServicesList
              mode={mode}
              additionalServices={additionalServices}
            />
          </ContentBox>
        )}
      </div>
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            color="--color-black"
            label="Cancel"
            onClick={close}
            active={true}
          />

          <Button
            type="small"
            label="Create order"
            onClick={createOrder}
            active={true}
            allert={
              <div
                style={{
                  padding: "24px",
                  boxSizing: "border-box",
                  textAlign: "center",
                }}
              >
                <h1>
                  Are you ready to place your order and send it to the rental
                  point for processing?
                </h1>
              </div>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderDetailModeration;
