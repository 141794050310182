import React, { useState } from "react";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import WidgetAlert from "../WidgetsProfile/Alerts/WidgetAlert";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderForUserV4 from "./WindowOrderClientV4";
import WindowOrderDateIntervalV4 from "./WindowOrderDateIntervalV4";

const WindowOrderedDetailV4 = ({ onClose }) => {
  const [windowOrderDateIntervalV4, setWindowOrderDateIntervalV4] =
    useState(false);

  /**
   *
   * Логика компонента
   *
   */

  const handleOpeWindowOrderDateIntervalV4 = () => {
    setWindowOrderDateIntervalV4(true);
  };
  const handleClose = () => {
    setWindowOrderDateIntervalV4(false);
  };
  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={windowOrderDateIntervalV4}
        onClose={handleClose}
        title={`Dates interval`}
      >
        <WindowOrderDateIntervalV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <h1 onClick={handleOpeWindowOrderDateIntervalV4}>Date interval</h1>
        </ContentBox>
        <ContentBox>
          <h1>Order detail</h1>
        </ContentBox>
        <ContentBox>
          <h1>Totals</h1>
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={onClose}
            color="--color-black"
          />

          <Button
            type="small"
            label="Add to order"
            active={true}
            // onClick={() => handleCreateOrUpdateData()}
            // allert={
            //   <WidgetAlert
            //     title={`Are you sure you want to add the page to the directory?`}
            //   />
            // }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderedDetailV4;
