import React, { useState } from "react";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import WindowCategoriesListV4 from "./WindowCategoriesListV4";

const WidgetCategoriesV4 = () => {
  const [isOpenWindowDirectorirsList, setIsOpenWindowDirectorirsList] =
    useState(false);

  const handleClose = () => {
    setIsOpenWindowDirectorirsList(false);
  };

  const handleToggle = () => {
    setIsOpenWindowDirectorirsList(true);
  };

  return (
    <>
      {/* WINDOW POPUP */}
      <PopupDirrectory
        isOpen={isOpenWindowDirectorirsList}
        onClose={handleClose}
        title={`Categories list`}
      >
        <WindowCategoriesListV4 />
      </PopupDirrectory>
      {/* WINDOW POPUP */}

      <div
        onClick={handleToggle}
        style={{
          backgroundColor: "var(--color-gray-100)",
          aspectRatio: "1/1",
          borderRadius: "16px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
          }}
        >
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "2rem",
            }}
          >
            home_storage
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            left: "16px",
            right: "16px",
            bottom: "16px",
          }}
        >
          <p style={{ textTransform: "capitalize" }}>Categories</p>
        </div>
      </div>
    </>
  );
};

export default WidgetCategoriesV4;
