import React, { useEffect, useState } from "react";
import "./SegmentedControls.css";

const SegmentedControls = ({
  options,
  onSelect,
  label,
  selected,
  description,
}) => {
  const [selectedOption, setSelectedOption] = useState(onSelect);

  useEffect(() => {
    // Обновляем выбранный вариант и вызываем функцию selected при изменении onSelect
    setSelectedOption(onSelect);
    if (selected) {
      selected(onSelect);
    }
  }, [onSelect, selected]);

  const handleOptionChange = (option) => {
    const optionTitle = typeof option === "string" ? option : option.title;
    setSelectedOption(optionTitle);
    if (selected) {
      selected(optionTitle);
    }
  };

  // Рассчитываем ширину кнопок на основе количества опций
  const widthButton = Math.round(100 / options.length);

  // Найти текущую опцию
  const currentOption =
    options &&
    options.find((option) =>
      typeof option === "string"
        ? option === selectedOption
        : option.title === selectedOption
    );

  // Рендеринг кнопок управления
  const renderButtons = () =>
    options.map((option, index) => {
      const optionTitle = typeof option === "string" ? option : option.title;
      const optionComponent =
        typeof option === "string" ? null : option.additions;
      return (
        <button
          style={{ width: `${widthButton}%` }}
          key={index}
          className={
            optionTitle === selectedOption
              ? "segmented-controls__marker--on"
              : "segmented-controls__marker--off"
          }
          onClick={() => handleOptionChange(option)}
        >
          <span
            style={{
              width: "100%",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "6px",
            }}
          >
            {optionTitle} {optionComponent && optionComponent}
          </span>
        </button>
      );
    });

  return options.length > 1 ? (
    <div className="profile-widget__content-box">
      {label && <h6>{label}</h6>}
      <div className="segmented-controls__content-box">{renderButtons()}</div>
      {currentOption &&
      typeof currentOption !== "string" &&
      currentOption.description ? (
        <div style={{ width: "100%" }}>
          <p style={{ width: "70%" }} className="p-light">
            {currentOption?.description || description}
          </p>
        </div>
      ) : description ? (
        <div style={{ width: "100%" }}>
          <p style={{ width: "70%" }} className="p-light">
            {description}
          </p>
        </div>
      ) : null}
    </div>
  ) : options.length === 1 ? (
    <div className="profile-widget__content-box">
      <h6>{typeof options[0] === "string" ? options[0] : options[0].title}</h6>
    </div>
  ) : null;
};

export default SegmentedControls;
