import React, { useEffect, useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderItemDetail from "./WindowOrderItemDetail";
import PhotosGallery from "../WidgetsProfile/PhotosGallery/PhotosGallery";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { useDispatch } from "react-redux";
import { setCurrentOrder } from "../../../../../reducersToolkit/orderSliceV2";
import Table from "../WidgetsProfile/Table/Table";

const OrderPreviewItem = ({ onClick, orderItem, orderKey }) => {
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [bikePhotoFiles, setBikePhotoFiles] = useState([]);
  const [quantityBikesOrderm, setQuantityBikesOrder] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostAdditionalServicesm, setTotalCostAdditionalServices] =
    useState(0);
  const [orderId, setOrderId] = useState(0);
  const [bikeName, setBikeName] = useState("");

  useEffect(() => {
    setQuantityBikesOrder(orderItem?.totals?.quantityBikesOrderm || 0);
    setTotal(isNaN(orderItem?.totals?.total) ? 0 : orderItem?.totals?.total);
    setTotalCost(
      isNaN(orderItem?.totals?.totalCost) ? 0 : orderItem?.totals?.totalCost
    );
    setTotalCostAdditionalServices(
      orderItem?.totals?.totalCostAdditionalServicesm || 0
    );
    setOrderId(orderItem?.id);

    setBikePhotoFiles(orderItem?.orderBike?.files?.bikePhotoFiles || []);
    setStartDay(orderItem?.bookingDates?.startDay || 0);
    setEndDay(orderItem?.bookingDates?.endDay || 0);
    setBikeName(
      orderItem?.orderBike?.basicInformation?.title?.titleOriginal || "No name"
    );
  }, [orderItem]);

  return (
    <div
      key={orderKey}
      style={{
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--color-gray-100)",
        borderRadius: "8px",
        padding: "16px 8px 16px 16px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "8px",
          padding: "8px 0",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <h6>{bikeName}</h6>

          <div
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <p className="p-light">
                    {startDay
                      ? new Date(startDay).toLocaleString("en-US", {
                          month: "long",
                        })
                      : "No start date"}
                  </p>
                  <p className="p-light">
                    {startDay ? new Date(startDay).getDate() : ""}
                  </p>
                </div>
                <p className="p-light">—</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <p className="p-light">
                    {endDay
                      ? new Date(endDay).toLocaleString("en-US", {
                          month: "long",
                        })
                      : "No end date"}
                  </p>
                  <p className="p-light">
                    {endDay ? new Date(endDay).getDate() : ""}
                  </p>
                </div>
              </div>
            </div>
            <p className="p-light">#{orderId}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h6>{formatCurrencyUSD(total.toString())}</h6>
          <span className="material-symbols-outlined">chevron_right</span>
        </div>
      </div>
    </div>
  );
};

const WidgetOrdersList = ({ filteredOrders }) => {
  const dispatch = useDispatch();

  const [isOpenWindowOrderItemDetail, setIsOpenWindowOrderItemDetail] =
    useState(false);

  const handleClose = () => {
    setIsOpenWindowOrderItemDetail(false);
  };

  const handleToggle = (data) => {
    setIsOpenWindowOrderItemDetail(true);
    dispatch(setCurrentOrder(data));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          gap: "8px",
          width: "100%",
        }}
      >
        {filteredOrders?.map((orderItem) => (
          <OrderPreviewItem
            key={orderItem.id} // Это для оптимизации рендеринга
            orderKey={orderItem.id} // Это будет доступно в дочернем компоненте
            onClick={() => handleToggle(orderItem)}
            orderItem={orderItem}
          />
        ))}
      </div>

      <PopupDirrectory
        isOpen={isOpenWindowOrderItemDetail}
        onClose={handleClose}
        title="Order Detail"
      >
        <WindowOrderItemDetail />
      </PopupDirrectory>
    </>
  );
};

export default WidgetOrdersList;
