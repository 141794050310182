import React, { useState } from "react";
import WidgetSignIn from "../../Private/Login/Auth/WidgetSignIn";
import WidgetSignUp from "../../Private/Login/Auth/WidgetSignUp";
import SegmentedControls from "../../Private/Login/Profile/WidgetsProfile/SegmentedControls/SegmentedControls";
import ContentBoxPublic from "../PublickWidgets/ContentBox/ContentBoxPublic";

const WindowLogIn = ({ onClose }) => {
  const [logiType, setLoginType] = useState("signIn");

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <ContentBoxPublic>
          <SegmentedControls
            options={[
              {
                title: "signIn",
                description:
                  "Please specify your gender for accurate addressing in our notifications and messages. Your data will remain confidential and secure.",
              },
              {
                title: "signUp",
                description:
                  "Please specify your gender for accurate addressing in our notifications and messages.",
              },
            ]}
            label={logiType && logiType === "signIn" ? "Signin" : "Signup"}
            selected={setLoginType}
            onSelect={logiType}
          />
        </ContentBoxPublic>

        <ContentBoxPublic>
          {logiType && logiType === "signIn" ? (
            <WidgetSignIn onClose={onClose} />
          ) : (
            <WidgetSignUp onClose={onClose} />
          )}
        </ContentBoxPublic>
      </div>
    </>
  );
};

export default WindowLogIn;
