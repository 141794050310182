import React, { useEffect, useState, useCallback, memo } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import Table from "../WidgetsProfile/Table/Table";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";
import PhotosGallery from "../WidgetsProfile/PhotosGallery/PhotosGallery";
import Switcher from "../WidgetsProfile/Switcher/Switcher";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import Button from "../WidgetsProfile/Buttons/Button";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import { useDispatch } from "react-redux";
import { confirmOrderStatusThunk } from "../../../../../reducersToolkit/thunk/orders/confirmOrderStatusThunk";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  return date.toLocaleString("en-US", { month: "short" });
};

const ReplacementPrice = ({ rate, bookingDates }) => {
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(0);
  const startMonth = new Date(startDay).getMonth();
  const endMonth = new Date(endDay).getMonth();
  const maxPrice = Math.max(...rate.map((rateItem) => rateItem.price));
  const calculateHeight = (price) => (price / maxPrice) * 56;

  useEffect(() => {
    setStartDay(bookingDates?.startDay || 0);
    setEndDay(bookingDates?.endDay || 0);
  }, [bookingDates]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      {rate?.map(
        (rateItem, index) =>
          index >= startMonth &&
          index <= endMonth && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "4px",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                }}
              >
                {getMonthName(index)}
              </p>
              <p
                style={{
                  fontWeight: "600",
                }}
              >
                {formatCurrencyUSD(rateItem?.price)}
              </p>
            </div>
          )
      )}
    </div>
  );
};

const Rate = memo(({ rate, startBookingDates, endBookingDates }) => {
  const startMonth = new Date(startBookingDates).getMonth();
  const endMonth = new Date(endBookingDates).getMonth();
  const maxPrice = Math.max(...rate.map((rateItem) => rateItem.price));
  const calculateHeight = (price) => (price / maxPrice) * 56;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
        }}
      >
        {rate?.map((rateItem, index) => (
          <div key={index} style={{ width: "100%" }}>
            <div
              title={`${getMonthName(index)} — ${formatCurrencyUSD(
                rateItem?.price
              )}`}
              style={{
                height: `${calculateHeight(rateItem.price)}px`,
                width: "100%",
                borderRadius: "4px",
                backgroundColor:
                  index >= startMonth && index <= endMonth
                    ? "var(--color-success)"
                    : "var(--color-gray-100)",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "flex-end",
                ...(index >= startMonth && index <= endMonth
                  ? {
                      minHeight: "23px",
                      padding: "2px 4px",
                    }
                  : {
                      minHeight: "8px",
                      padding: "0",
                    }),
              }}
            >
              {index >= startMonth && index <= endMonth && (
                <p className="p-light" style={{ color: "var(--color-white)" }}>
                  {formatCurrencyUSD(rateItem?.price)}
                </p>
              )}
            </div>
            <div
              style={{
                height: "24px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <p className="p-light" style={{ color: "var(--color-black)" }}>
                {index >= startMonth &&
                  index <= endMonth &&
                  getMonthName(index)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

const BikeItemPreview = memo(
  ({
    onClick,
    bikeItem,
    bookingDates,
    brands,
    categories,
    onSwitcherChange,
  }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [bikePhotoFiles, setBikePhotoFiles] = useState([]);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [rate, setRate] = useState([]);
    const [startDay, setStartDay] = useState(0);
    const [endDay, setEndDay] = useState(0);
    const [brand, setBrand] = useState("");
    const [category, setCategory] = useState("");

    const handleCheckboxChange = useCallback(
      (newCheckedState) => {
        setIsChecked(newCheckedState);
        onSwitcherChange(bikeItem, newCheckedState);
      },
      [onSwitcherChange, bikeItem]
    );

    useEffect(() => {
      setBikePhotoFiles(bikeItem?.files?.bikePhotoFiles || []);
      setTitle(bikeItem?.basicInformation?.title?.titleOriginal || "No name");
      setDescription(
        bikeItem?.basicInformation?.description?.descriptionOriginal ||
          "No Description"
      );
      setRate(bikeItem?.rate || []);
    }, [bikeItem]);

    useEffect(() => {
      setStartDay(bookingDates?.startDay || 0);
      setEndDay(bookingDates?.endDay || 0);
    }, [bookingDates]);

    useEffect(() => {
      const foundBrand = brands?.find(
        (brand) => brand.id === bikeItem?.directory?.brandId
      );
      setBrand(foundBrand?.title?.titleOriginal || "No brand");
    }, [brands, bikeItem]);

    useEffect(() => {
      const foundCategory = categories.find(
        (category) => category.id === bikeItem?.directory?.categoryId
      );
      setCategory(foundCategory?.title?.titleOriginal || "No category");
    }, [categories, bikeItem]);

    return (
      <div
        onClick={onClick}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
          height: "100%",
          minHeight: "180px",
          boxSizing: "border-box",
          paddingBottom: "16px",
          borderBottom: "solid 1px var(--color-gray-200)",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "var(--color-gray-100)",
            boxSizing: "border-box",
            borderRadius: "16px",
            position: "relative",
            gridColumn: "1, span 1",
          }}
        >
          <PhotosGallery photos={bikePhotoFiles} />
        </div>

        <div
          style={{
            gridColumn: "2, span 1",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                gap: "8px",
              }}
            >
              <div
                style={{
                  padding: "4px 8px",
                  backgroundColor: "var(--color-gray-100)",
                  borderRadius: "4px",
                }}
              >
                <p className="p-light">{category}</p>
              </div>
              <div
                style={{
                  padding: "4px 8px",
                  backgroundColor: "var(--color-gray-100)",
                  borderRadius: "4px",
                }}
              >
                <p className="p-light">{brand}</p>
              </div>
            </div>

            <h6>{title}</h6>
          </div>
        </div>
        <div
          style={{
            gridColumn: "1 / span 2",
          }}
        >
          <Table
            columns={1}
            data={[
              {
                typeCell: "column",
                parameter: (
                  <Switcher
                    id={bikeItem.id}
                    isChecked={isChecked}
                    onChange={handleCheckboxChange}
                    label="Offer a bike as a replacement"
                  />
                ),
              },
              {
                typeCell: "column",
                title: "Rate",
                parameter: (
                  <Rate
                    rate={rate}
                    startBookingDates={startDay}
                    endBookingDates={endDay}
                  />
                ),
              },
              description && {
                typeCell: "column",
                title: "Description bike",
                parameter: <CollapsableText text={description} />,
              },
            ].filter(Boolean)} // filter(Boolean), чтобы исключить undefined
          />
        </div>
      </div>
    );
  }
);

const WidgetOrderExhenge = ({
  userRentalPoints,
  bikes,
  brands,
  categories,
  bookingDates,
  currentOrder,
  userId,
}) => {
  const dispatch = useDispatch();
  const [userBikes, setUserBikes] = useState([]);
  const [category, setCategory] = useState([]);
  const [orderSegmentedControls, setOrderSegmentedControls] = useState("");
  const [activeBikes, setActiveBikes] = useState([]);
  const [motivation, setMotivation] = useState(null);

  useEffect(() => {
    const allBikesIds = userRentalPoints?.flatMap(
      (rentalPoint) => rentalPoint?.bikesIds
    );

    const filteredBikes = bikes?.filter((bike) =>
      allBikesIds?.includes(bike?.id)
    );

    const allCategoryIds = filteredBikes?.map(
      (bike) => bike?.directory?.categoryId
    );
    const filteredCategories = categories?.filter((category) =>
      allCategoryIds?.includes(category?.id)
    );

    const foundCategory = categories?.find(
      (category) => category.title?.titleOriginal === orderSegmentedControls
    );

    if (foundCategory) {
      const bikesInCategory = filteredBikes?.filter(
        (bike) => bike?.directory?.categoryId === foundCategory?.id
      );
      setUserBikes(bikesInCategory);
    }
    setCategory(filteredCategories);
  }, [userRentalPoints, bikes, categories, orderSegmentedControls]);

  const handleSwitcherChange = useCallback((bikeItem, isChecked) => {
    setActiveBikes((prevActiveBikes) => {
      if (isChecked) {
        return [...prevActiveBikes, bikeItem];
      } else {
        return prevActiveBikes.filter((item) => item.id !== bikeItem.id);
      }
    });
  }, []);

  useEffect(() => {
    setActiveBikes([]);
  }, [orderSegmentedControls]);

  const updateData = {
    orderStatuses: {
      currentStatus: "exchange",

      historyChanges: [
        {
          orderStatus: "exchange",
          motivation: motivation,
          userId: userId,
          exhengeBikes: activeBikes,
          timestamp: Date.now().toString(),
        },
      ],
    },
  };

  const handkeConfirmOrderStatus = () => {
    if (motivation.trim()) {
      dispatch(
        confirmOrderStatusThunk({
          orderId: currentOrder.id,
          updateData: updateData,
        })
      )
        .unwrap()
        .then(() => {
          console.log("Order status updated successfully!");
          setMotivation("");
        })
        .catch((error) => {
          console.error("Failed to update order status: ", error);
        });
    } else {
      console.error("Motivation is empty!");
    }
  };

  const handleMotivationChange = (e, index, newValue) => {
    setMotivation(newValue);
  };

  return (
    <>
      <SegmentedControls
        options={category?.map(
          (itemCategory) => itemCategory?.title?.titleOriginal
        )}
        onSelect={category[0]?.title?.titleOriginal}
        label="Filtering by brand"
        selected={setOrderSegmentedControls}
      />

      <div className="profile-widget__content-box">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "16px",
            }}
          >
            {userBikes?.map((bikeItem) => (
              <BikeItemPreview
                key={bikeItem.id}
                bikeItem={bikeItem}
                bookingDates={bookingDates}
                brands={brands}
                categories={categories}
                onSwitcherChange={handleSwitcherChange}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "var(--color-gray-100)",
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
        }}
      >
        {activeBikes.length > 0 ? (
          <>
            <Table
              title={
                activeBikes.length > 0
                  ? "Bikes for replacement"
                  : "Select bikes to replace"
              }
              columns={1}
              data={activeBikes?.map((bike, index) => ({
                // typeCell: "column",
                title: bike.basicInformation.title.titleOriginal,
                parameter: (
                  <ReplacementPrice
                    rate={bike.rate}
                    bookingDates={bookingDates}
                  />
                ),
              }))}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Textarea
                label="Reason for replacement"
                maxLength={500}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                value={motivation}
                onChange={handleMotivationChange}
              />
              <Button
                label="Confirm"
                active={motivation}
                onClick={handkeConfirmOrderStatus}
                description={
                  !motivation && (
                    <span
                      style={{
                        color: "var(--color-alarm)",
                      }}
                    >
                      Please indicate `Reason For Replacement`
                    </span>
                  )
                }
                allert={
                  <h1
                    style={{
                      padding: "24px",
                      textAlign: "center",
                    }}
                  >
                    Please confirm that you are offering a replacement to the
                    customer.
                  </h1>
                }
              />
            </div>
          </>
        ) : (
          <TitleBlockSection
            title="Select bikes to replace"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
          />
        )}
      </div>
    </>
  );
};

export default WidgetOrderExhenge;
