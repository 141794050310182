import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretAuthErrorV2 from "../../../../../functions/interpretAuthErrorV2";
import { isDataStale } from "../fetchCatalogData/utils";
import { getNestedState } from "../../../../../functions/stateUtils";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// Максимальное количество попыток
const MAX_RETRIES = 3;

// Список кодов ошибок, для которых будут выполняться повторные попытки
const RETRYABLE_ERRORS = [
  "unavailable", // Firestore backend не доступен
  "deadline-exceeded", // Превышено время ожидания запроса
  "internal", // Внутренняя ошибка Firestore
];

// Функция для получения вложенного состояния по строковому пути
// const getNestedState = (state, path) => {
//   return path
//     .split(".")
//     .reduce((acc, part) => (acc ? acc[part] : undefined), state);
// };

export const fetchRootDataThunkV4 = createAsyncThunk(
  "catalog/fetchRootDataThunkV4",
  async (
    {
      idRootDocument,
      rootCollectionPath,
      rootCurrentDocumentState,
      loadingStateName,
      errorStateName,
    },
    { getState, rejectWithValue }
  ) => {
    // Получаем состояние каталога
    const state = getState().catalogSliceV4;

    const currentTimestamp = Date.now();

    // Проверка наличия данных и их актуальности в состоянии по пути rootCollectionPath
    const rootState = getNestedState(state, rootCollectionPath);

    console.log(
      "Проверка наличия данных и их актуальности в состоянии по пути rootCollectionPath",
      rootCollectionPath,
      rootState
    );

    // Проверка на наличие объекта по idRootDocument (работаем с объектами, а не массивами)
    if (rootState && rootState[idRootDocument]) {
      const existingCountry = rootState[idRootDocument];

      // Проверяем, устарели ли данные
      if (!isDataStale(existingCountry.lastUpdated)) {
        console.log("Данные страны актуальны, запрос не требуется.");
        return {
          rootDocumentData: existingCountry, // Существующие данные
          rootCollectionPath, // Путь коллекции
          lastUpdated: existingCountry.lastUpdated, // Время последнего обновления
          rootCurrentDocumentState,
          loadingStateName, // Статус загрузки
          errorStateName, // Статус ошибки
        };
      }
    }

    // Проверка значений `rootCollectionPath` и `idRootDocument`
    if (!rootCollectionPath || typeof rootCollectionPath !== "string") {
      console.error(
        "Некорректное значение rootCollectionPath:",
        rootCollectionPath
      );
      return rejectWithValue("Invalid rootCollectionPath value.");
    }

    if (!idRootDocument || typeof idRootDocument !== "string") {
      console.error("Некорректное значение id:", idRootDocument);
      return rejectWithValue("Invalid id value.");
    }

    // Механизм повторных попыток
    for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
      try {
        console.log(
          `Попытка ${attempt}: Запрос данных для страны с ID ${idRootDocument} из Firestore...`
        );

        // Получаем ссылку на документ в Firestore
        const docRef = doc(
          db,
          ...rootCollectionPath.split("."),
          idRootDocument
        ); // Используем split(".") для разделения пути
        console.log("Ссылка на документ:", docRef);

        // Получаем документ из Firestore
        const docSnapshot = await getDoc(docRef);

        if (!docSnapshot.exists()) {
          console.log(`Документ с ID ${idRootDocument} не найден в коллекции.`);
          return rejectWithValue(
            `Document with ID ${idRootDocument} does not exist.`
          );
        }

        // Получаем данные документа и добавляем idRootDocument внутрь данных
        const rootDocumentData = { ...docSnapshot.data(), idRootDocument };
        console.log(
          "Данные документа успешно получены из Firestore:",
          rootDocumentData
        );

        // Возвращаем данные и время последнего обновления
        return {
          rootDocumentData,
          rootCollectionPath,
          lastUpdated: currentTimestamp,
          rootCurrentDocumentState,
          loadingStateName,
          errorStateName,
        };
      } catch (error) {
        console.error(
          `Ошибка при попытке ${attempt} получения данных из Firestore:`,
          error
        );

        // Проверка, если ошибка является одной из "повторяемых"
        if (RETRYABLE_ERRORS.includes(error.code)) {
          console.log(
            `Ошибка ${error.code} позволяет выполнить повторную попытку.`
          );

          // Если это была последняя попытка, возвращаем ошибку
          if (attempt === MAX_RETRIES) {
            const errorMessage = interpretErrorV4(error.code);
            console.error(
              "Это была последняя попытка получения данных в Firestore:",
              errorMessage
            );
            return rejectWithValue({ errorMessage });
          }

          // Если это не последняя попытка, ждем и пробуем снова
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempt));
        } else {
          // Если ошибка не позволяет повторить попытку, сразу возвращаем ее
          const errorMessage = interpretErrorV4(error.code);
          console.error(
            "Ошибка при повторной попытке получения данных в Firestore:",
            errorMessage
          );
          return rejectWithValue({ errorMessage });
        }
      }
    }
  }
);
