import React from "react";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import WidgetBrandsV4 from "./Brands/WidgetBrandsV4";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetCountriesV4 from "./CountriesV4/WidgetCountriesV4";
import WidgetCategoriesV4 from "./CategoriesV4/WidgetCategoriesV4";

const WidgetDirectory = () => {
  return (
    <ContentBox>
      <TitleBlockSection
        title="Dirrectory"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      />

      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "8px",
          flex: "1",
        }}
      >
        <WidgetCategoriesV4 />
        <WidgetBrandsV4 />
        <WidgetCountriesV4 />
      </div>
    </ContentBox>
  );
};

export default WidgetDirectory;
