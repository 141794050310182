import React, { useEffect, useState } from "react";
import "../profile.css";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import Button from "../WidgetsProfile/Buttons/Button";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import { updateRentalPointsThunkV2 } from "../../../../../reducersToolkit/thunk/rentalPoints/updateRentalPointsThunkV2";
import { deactivateRentalPointThunkV2 } from "../../../../../reducersToolkit/thunk/rentalPoints/deactivateRentalPointThunkV2";
import { activatedRentalPointThunkV2 } from "../../../../../reducersToolkit/thunk/rentalPoints/activatedRentalPointThunkV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import WidgetRentalPointLocationV4 from "./WidgetRentalPointLocationV4";
import WidgetRentalPointFilesBarV4 from "./WidgetRentalPointFilesBarV4";
import { createRentalPointThunkV4 } from "../../../../../reducersToolkit/thunk/rentalPoints/V4/createRentalPointThunkV4";
import WidgetRentalPointBasicInformationV4 from "./WidgetRentalPointBasicInformationV4";
import WidgetAditionalServicesV4 from "./WidgetAditionalServicesV4";
import WidgetRentalPointContactV4 from "./WidgetRentalPointContactV4";
import WidgetRentalPointTimetableV4 from "./WidgetRentalPointTimetableV4";

const WindowRentalPointDetailV4 = ({ mode, onClose, state }) => {
  const dispatch = useDispatch();

  const [basicInformation, setBasicInformation] = useState({});
  const [location, setLocation] = useState({});
  const [countryId, setCountryId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [contacts, setContacts] = useState({});
  const [additionalServices, setAdditionalServices] = useState({});
  const [timetable, setTimetable] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [files, setFiles] = useState({});
  const [previewFiles, setPreviewFiles] = useState({});
  const [userId, setUserId] = useState(0);

  const { isLoadingRentalPoint, selectedRentalPoint, newRentalPoint } =
    useSelector((state) => state.rentalPointsSliiceV4);

  const { loading, error, tempRootData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [currentUserData, setCurrentUserData] = useState(null);

  console.log("App currentUser", tempRootData);

  useEffect(() => {
    setCurrentUserData(tempRootData?.currentUser || null);
  }, [tempRootData?.currentUser]);

  const { isLoadingBikes, bikesList } = useSelector(
    (state) => state.bikesSliceV4
  );

  const { brands, categories } = useSelector((state) => state.directorySliceV2);

  useEffect(() => {
    setUserId(currentUserData?.id || 0);
  }, [currentUserData]);
  useEffect(() => {
    if (mode === "new") {
      setBasicInformation(newRentalPoint.basicInformation);
      setLocation(newRentalPoint.location);
      setCountryId(newRentalPoint.location.country.id || null);
      setCityId(newRentalPoint.location.city.id || null);
      setContacts(newRentalPoint.contacts);
      setAdditionalServices(newRentalPoint.additionalServices);
      setTimetable(newRentalPoint.timetable);

      if (!initialState) setInitialState(newRentalPoint);
    } else if (mode === "edit") {
      setBasicInformation(selectedRentalPoint.basicInformation);
      setLocation(selectedRentalPoint.location);
      setContacts(selectedRentalPoint.contacts);
      setAdditionalServices(selectedRentalPoint.additionalServices);
      setTimetable(selectedRentalPoint.timetable);
      setFiles(newRentalPoint.files);

      if (!initialState) setInitialState(selectedRentalPoint);
    } else if (mode === "moderation") {
      setBasicInformation(newRentalPoint.basicInformation);
      setLocation(newRentalPoint.location);
      setContacts(newRentalPoint.contacts);
      setAdditionalServices(newRentalPoint.additionalServices);
      setTimetable(newRentalPoint.timetable);
      if (!initialState) setInitialState(newRentalPoint);
    }
  }, [newRentalPoint, selectedRentalPoint, mode, initialState]);

  useEffect(() => {
    const hasChanges = () => {
      if (!initialState) return false;
      return (
        JSON.stringify(initialState.basicInformation) !==
          JSON.stringify(basicInformation) ||
        JSON.stringify(initialState.location) !== JSON.stringify(location) ||
        JSON.stringify(initialState.contacts) !== JSON.stringify(contacts) ||
        JSON.stringify(initialState.additionalServices) !==
          JSON.stringify(additionalServices) ||
        JSON.stringify(initialState.timetable) !== JSON.stringify(timetable)
      );
    };

    setIsChanged(hasChanges());
  }, [
    basicInformation,
    location,
    contacts,
    additionalServices,
    timetable,
    initialState,
  ]);
  useEffect(() => {
    console.log(
      "rentalPointData",
      basicInformation,
      location,
      contacts,
      additionalServices,
      timetable,
      previewFiles
    );
  }, [
    basicInformation,
    location,
    contacts,
    additionalServices,
    timetable,
    previewFiles,
  ]);

  const handleCreateRentalPoint = () => {
    if (!countryId || !cityId) {
      console.error("Country and city must be selected.");
      return;
    }

    const rentalPointData = {
      basicInformation,
      location,
      contacts,
      additionalServices,
      timetable,
    };

    if (mode === "new" || mode === "moderation") {
      dispatch(
        createRentalPointThunkV4({
          countryId,
          cityId,
          data: rentalPointData,
          files: previewFiles,
          userId: userId,
        })
      );
      onClose();
    } else if (mode === "edit") {
      // Обновляем существующую точку проката
      dispatch(
        updateRentalPointsThunkV2({
          catalogId: countryId,
          cityId,
          id: selectedRentalPoint.id,
          data: rentalPointData,
        })
      );
      onClose();
    }
  };

  const handleDeactivate = (rentalPointId) => {
    dispatch(deactivateRentalPointThunkV2(rentalPointId));
    onClose();
  };

  const handleActivate = (rentalPointId) => {
    dispatch(activatedRentalPointThunkV2(rentalPointId));
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <WidgetRentalPointBasicInformationV4
          mode={mode}
          basicInformation={basicInformation}
        />
      </ContentBox>

      <ContentBox>
        <WidgetRentalPointFilesBarV4
          mode={mode}
          files={files}
          setPreviewFiles={setPreviewFiles}
        />
      </ContentBox>

      <ContentBox isLoading={isLoadingRentalPoint}>
        <WidgetRentalPointLocationV4 mode={mode} location={location} />
      </ContentBox>

      {mode === "edit" && (
        <ContentBox>
          <WidgetBikesList
            brands={brands}
            categories={categories}
            bikesList={bikesList}
            isLoadingBikes={isLoadingBikes}
          />
        </ContentBox>
      )}

      <ContentBox isLoading={isLoadingRentalPoint}>
        <WidgetAditionalServicesV4
          mode={mode}
          additionalServices={additionalServices}
        />
      </ContentBox>

      <ContentBox isLoading={isLoadingRentalPoint}>
        <WidgetRentalPointContactV4 mode={mode} contacts={contacts} />
      </ContentBox>

      <ContentBox isLoading={isLoadingRentalPoint}>
        <WidgetRentalPointTimetableV4 mode={mode} timetable={timetable} />
      </ContentBox>
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          {state === "deactivated" && (
            <Button
              type="small"
              label="Activate"
              active={true}
              onClick={() => handleActivate(selectedRentalPoint.id)}
              color="--color-success"
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>
                    Are you sure you want to deactivate
                    {basicInformation?.title?.titleOriginal
                      ? ` "${basicInformation?.title?.titleOriginal}"`
                      : ""}
                    ?
                  </h1>
                  <p className="p-light">
                    You will be able to reactivate the rental point at any time
                    convenient for you.
                  </p>
                </div>
              }
            />
          )}
          {state === "activated" && (
            <Button
              type="small"
              label="Deactivate"
              active={true}
              onClick={() => handleDeactivate(selectedRentalPoint.id)}
              color="--color-black"
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>
                    Are you sure you want to deactivate
                    {basicInformation?.title?.titleOriginal
                      ? ` "${basicInformation?.title?.titleOriginal}"`
                      : ""}
                    ?
                  </h1>
                  <p className="p-light">
                    You will be able to reactivate the rental point at any time
                    convenient for you.
                  </p>
                </div>
              }
            />
          )}
          <Button
            type="small"
            label="Cancel"
            onClick={onClose}
            color="--color-black"
            active={true}
          />
          <Button
            type="small"
            label={`${
              mode === "new"
                ? "Create"
                : mode === "edit"
                ? "Save changes"
                : "Moderation a rental point"
            }`}
            onClick={handleCreateRentalPoint}
            active={isChanged}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowRentalPointDetailV4;
