import React, { useState } from "react";
import "../profile.css";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import { useSelector } from "react-redux";
// import WindowUploadFiles from "../DirectoryV3/Directory/WindowUploadFiles";

const ItemImageDirrect = ({ icon, title, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        height: "148px",
        aspectRatio: "3/2",
        borderRadius: "16px",
        backgroundColor: "var(--color-gray-100)",
        cursor: "pointer",
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "start",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      {icon}
      <div
        style={{
          position: "absolute",
          bottom: "16px",
          left: "16px",
          right: "16px",
        }}
      >
        <p className="p-light">{title}</p>
      </div>
    </div>
  );
};

const WidgetRentalPointFilesBar = ({ selectedRentalPoint }) => {
  const [isOpenLogoPopup, setIsOpenLogoPopup] = useState(false);
  const [isOpenlePhotoPopup, setIsOpenPhotoPopup] = useState(false);
  const [isOpenleLicensePopup, setIsOpenleLicensePopup] = useState(false);

  // const { selectedRentalPoint } = useSelector(
  //   (state) => state.rentalPointsSliiceV2
  // );

  const handleClose = () => {
    setIsOpenLogoPopup(false);
    setIsOpenPhotoPopup(false);
    setIsOpenleLicensePopup(false);
  };

  const handleToggleLogoPopup = () => {
    setIsOpenLogoPopup(!isOpenLogoPopup);
  };
  const handleTogglePhotoPopup = () => {
    setIsOpenPhotoPopup(!isOpenlePhotoPopup);
  };
  const handleToggleLicensePopup = () => {
    setIsOpenleLicensePopup(!isOpenleLicensePopup);
  };
  return (
    <>
      <div className="profile-widget__content-box">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "8px",
            // borderRadius: "16px",
            // height: "56px",
            // backgroundColor: "var(--color-white)",
            overflowX: "auto",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <ItemImageDirrect
              icon={
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "2rem" }}
                >
                  diamond
                </span>
              }
              title="Upload rental point logo"
              onClick={handleToggleLogoPopup}
            />

            <ItemImageDirrect
              icon={
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "2rem",
                    // color: "var(--color-gray-600)",
                  }}
                >
                  image
                </span>
              }
              title="Upload rental point photo"
              onClick={handleTogglePhotoPopup}
            />

            <ItemImageDirrect
              icon={
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "2rem" }}
                >
                  license
                </span>
              }
              title="Upload rental point license"
              onClick={handleToggleLicensePopup}
            />
          </div>
        </div>
      </div>

      <PopupDirrectory
        isOpen={isOpenLogoPopup}
        onClose={handleClose}
        title="Upload rental point logo"
      >
        {/* <WindowUploadFiles
          collectionName="rentalPoints"
          documentId={selectedRentalPoint?.id}
          fieldName="files.logoFile"
        /> */}
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenlePhotoPopup}
        onClose={handleClose}
        title="Upload rental point photo"
      >
        {/* <WindowUploadFiles
          collectionName="rentalPoints"
          documentId={selectedRentalPoint?.id}
          fieldName="files.photoFiles"
        /> */}
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenleLicensePopup}
        onClose={handleClose}
        title="Upload rental point license"
      >
        {/* <WindowUploadFiles
          collectionName="rentalPoints"
          documentId={selectedRentalPoint?.id}
          fieldName="files.license"
        /> */}
      </PopupDirrectory>
    </>
  );
};

export default WidgetRentalPointFilesBar;
