import React, { useEffect, useState } from "react";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowUserModerationV4 from "./WindowUserModerationV4";

const WidgetUserModerationV4 = ({ mode, currentUser, comments }) => {
  const [windowUserModeration, setWindowUserModeration] = useState(false);
  const [latestComment, setLatestComment] = useState("");

  useEffect(() => {
    const latestComment = comments?.reduce((latest, current) => {
      return current.timestamp > (latest?.timestamp || 0) ? current : latest;
    }, null);

    setLatestComment(latestComment);
  }, [comments]);

  const handleOpenWindowUserModeration = () => {
    setWindowUserModeration(true);
  };

  const handleClose = () => {
    setWindowUserModeration(false);
  };

  return (
    <>
      {/* POPUP WINDOW */}
      <PopupDirrectory
        isOpen={windowUserModeration}
        onClose={handleClose}
        title="User moderation"
      >
        <WindowUserModerationV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOW */}

      <AddData
        icon={<span className="material-symbols-outlined">add</span>}
        title={
          currentUser?.stateUser?.status === "moderation"
            ? "Moderation"
            : currentUser?.stateUser?.status === "approved"
            ? "Approved"
            : currentUser?.stateUser?.status === "banned" && "Banned"
        }
        description={
          latestComment?.content ||
          (currentUser?.stateUser?.status === "moderation"
            ? "Moderation - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ipsum nisl, euismod ac rutrum eu, laoreet consequat urna."
            : currentUser?.stateUser?.status === "approved"
            ? "Approved - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ipsum nisl, euismod ac rutrum eu, laoreet consequat urna."
            : currentUser?.stateUser?.status === "banned" &&
              "Banned - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ipsum nisl, euismod ac rutrum eu, laoreet consequat urna.")
        }
        onClick={handleOpenWindowUserModeration}
      />
    </>
  );
};

export default WidgetUserModerationV4;
