import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretAuthErrorV2 from "../../../../../functions/interpretAuthErrorV2";
import { isDataStale } from "../fetchCatalogData/utils";

export const fetchLocationDataThunkV4 = createAsyncThunk(
  "directories/fetchLocationDataThunkV4",
  async (_, { getState, rejectWithValue }) => {
    const state = getState().directoriesSliceV4;
    const currentTimestamp = Date.now();
    let shouldFetch = false;

    if (!state.lastUpdated || isDataStale(state.lastUpdated)) {
      console.log(
        "Данные о локациях устарели или отсутствуют, выполняем запрос."
      );
      shouldFetch = true;
    }

    if (!shouldFetch) {
      console.log("Данные о локациях актуальны, запрос не выполняется.");
      return { fromIndexedDB: true }; // Данные не устарели, возвращаем специальное значение
    }

    try {
      console.log("Запрос данных о локациях...");
      const docRef = doc(db, "directories", "locations"); // Обращение к документу locations в коллекции directories
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        console.log("Документ locations не найден.");
        return rejectWithValue("Document does not exist.");
      }

      const data = docSnapshot.data();
      console.log("Данные о локациях успешно получены из Firestore:", data);

      return { data, lastUpdated: currentTimestamp }; // Возвращаем данные и время последнего обновления
    } catch (error) {
      console.error(
        "Ошибка при получении данных о локациях из Firestore:",
        error
      );
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue({ errorMessage });
    }
  }
);
