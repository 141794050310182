import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentRootData } from "../../../../../../reducersToolkit/catalogSliceV4";
import { fetchRootDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { createDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/createDataThunkV4";
import { updateDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import { deleteDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/deleteDataThunkV4";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import InputText from "../../WidgetsProfile/Inputs/InputText";
import Textarea from "../../WidgetsProfile/Textarea/Textarea";
import Button from "../../WidgetsProfile/Buttons/Button";
import WidgetAlert from "../../WidgetsProfile/Alerts/WidgetAlert";
import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowBrandDetailV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const { loading, tempPreviewData, tempRootData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);
  const [newPhotoFiles, setNewPhotoFiles] = useState([]);
  const [remove, setRemove] = useState(null);

  const [originalTitle, setOriginalTitle] = useState("");
  const [originalDescription, setOriginalDescription] = useState("");
  const [loading_brandsIconFile, setLoading_brandsIconFile] = useState(false);

  const [idRootDocument, setIdRootDocument] = useState(null);
  const [idPreviewDocument, setIdPreviewDocument] = useState(null);
  const [loading_brands, setLoading_brands_rootData] = useState(false);

  const [cuurrentBrand, setRootCurrentBrand] = useState({});

  useEffect(() => {
    setLoading_brands_rootData(loading.loading_brands || false);
    setLoading_brandsIconFile(loading.loading_brandsIconFile || false);
  }, [loading.loading_brands, loading.loading_brandsIconFile]);

  useEffect(() => {
    setTitle(tempRootData?.brands_rootData?.title?.titleOriginal || "");
    setDescription(
      tempRootData?.brands_rootData?.description?.descriptionOriginal || ""
    );
    setOriginalTitle(tempRootData?.brands_rootData?.title?.titleOriginal || "");
    setOriginalDescription(
      tempRootData?.brands_rootData?.description?.descriptionOriginal || ""
    );
    setPreviewPhotoFiles(tempRootData?.brands_rootData?.files?.iconFiles || []);
    setRootCurrentBrand(tempRootData?.brands_rootData || {});
    setRemove(tempRootData?.brands_rootData?.remove || null);
  }, [tempRootData?.brands_rootData]);

  useEffect(() => {
    setIdPreviewDocument(
      tempPreviewData?.selectedBrand?.idPreviewDocument || null
    );
    setIdRootDocument(tempPreviewData?.selectedBrand?.idRootDocument || null);
  }, [
    tempPreviewData?.selectedBrand,
    tempPreviewData?.subscribe_brandsPreview,
  ]);

  useEffect(() => {
    if (mode === "edit") {
      // Если есть idRootCountryId страны, выполняем запрос для получения данных для детализации
      if (idRootDocument) {
        console.log(
          `Есть idRootCountryId: ${idRootDocument} страны, выполняем запрос для получения данных для детализации`
        );

        dispatch(
          fetchRootDataThunkV4({
            idRootDocument: idRootDocument,
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: "brands",
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState: "brands_rootData",
            // Название state который будет помогать отслежывать результаты работы thunk
            loadingStateName: "loading_brands",
            errorStateName: "currentError",
          })
        );
      }
    } else if (mode === "new") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "brands_rootData",
          data: {},
        })
      );
    }
  }, [dispatch, idRootDocument, mode]);

  const handleCreateOrUpdateDirectory = async () => {
    try {
      if (mode === "new") {
        dispatch(
          createDataThunkV4({
            rootData: rootCountryData,
            previewData: previewCountryData,
            files: {
              iconFiles: newPhotoFiles,
            },
            // Имя того массива файлов который будет использоваться для назначения иконки
            iconFields: "iconFiles",
            rootCollectionPath: "brands",
            previewCollectionPath: "brandsPreview",
            metadataDocumentPath: "brandsPreview.metadata",
            errorStateName: "currentError",
            loadingStateName: "loading_create_brand",
          })
        );
        onClose();
      }
      if (mode === "edit") {
        if (idRootDocument) {
          dispatch(
            updateDataThunkV4({
              idRootDocument: idRootDocument,
              rootData: rootCountryData,
              previewData: previewCountryData,
              newFiles: {
                iconFiles: newPhotoFiles,
              },
              // Имя того массива файлов который будет использоваться для назначения иконки
              iconFields: "iconFiles",
              rootCollectionPath: "brands",
              previewCollectionPath: "brandsPreview",
              metadataDocumentPath: "brandsPreview.metadata",
              rootCurrentDocumentState: "brands_rootData",
              loadingStateName: "loading_updateBrands",
              errorStateName: "currentError",
            })
          );
          onClose();
        }
      }
    } catch (error) {
      console.error("Ошибка при создании или обновлении документа:", error);
      onClose();
    }
  };

  const handleDeleBrand = () => {
    dispatch(
      deleteDataThunkV4({
        // Id документов у которых поле remove будет обновлено
        idPreviewDocument: idPreviewDocument,
        idRootDocument: idRootDocument,
        // Определение путей коллекций
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: "brands",
        previewCollectionPath: "brandsPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: "brandsPreview.metadata",
        // Название state который будет содержать массив как результат работы thunk
        // previewCurrentDocumenState: "brandsPreview_deleteBrand",
        // Название state который будет помогать отслежывать результаты работы thunk
        loadingStateName: "loading_deleBrand",
        errorStateName: "currentError",
      })
    );
    onClose();
  };

  const handleRestoreBrand = () => {
    dispatch(
      updateDataThunkV4({
        idRootDocument: idRootDocument,
        rootData: rootCountryData,
        previewData: previewCountryData,
        newFiles: {
          iconFiles: newPhotoFiles,
        },
        // Имя того массива файлов который будет использоваться для назначения иконки
        iconFields: "iconFiles",
        rootCollectionPath: "brands",
        previewCollectionPath: "brandsPreview",
        metadataDocumentPath: "brandsPreview.metadata",
        rootCurrentDocumentState: "brands_rootData",
        loadingStateName: "loading_restoreBrand",
        errorStateName: "currentError",
      })
    );

    onClose();
  };

  const rootCountryData = {
    ...cuurrentBrand,
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const previewCountryData = {
    title: title || "",
    description: (description || "").slice(0, 250),
    createdat: Date.now(),
    visibility: false,
    remove: null,
  };

  const handleSetTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleSetDescription = (event) => {
    setDescription(event.target.value);
  };

  const isButtonActive =
    newPhotoFiles.length > 0 ||
    title !== originalTitle ||
    description !== originalDescription;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
          // ...(mode === "new" && {
          //   marginBottom: "72px",
          // }),
        }}
      >
        <ContentBox position="start" isLoading={loading_brands}>
          <WidgetListFileV4
            isLoading={loading_brandsIconFile}
            // Передаём данные для отображения
            viewFiles={previewPhotoFiles}
            // Поле в котором находится массив с файлами
            fieldName="files.iconFiles"
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath="brands"
            previewCollectionPath="brandsPreview"
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath="brandsPreview.metadata"
            // Редактируемые документы
            idRootDocument={idRootDocument}
            // Динамические состояния загрузки
            loadingStateName="loading_brandsIconFile"
            errorStateName="currentError"
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState="brands_rootData"
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            previewCurrentDocumenState="brandsPreview"
            // Режим
            mode="edit"
          />

          <WidgetUploadFilesV4
            isLoading={loading_brandsIconFile}
            id="brandsIconFiles"
            title="Add photo country"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setNewPhotoFiles}
          />

          <InputText
            id="BrandName"
            label="Brand name"
            description="Enter the name of the country."
            value={title}
            onChange={handleSetTitle}
            type="text"
          />

          <Textarea
            label="Description"
            maxLength={5000}
            value={description}
            onChange={handleSetDescription}
          />
        </ContentBox>

        <ButtonsBar>
          {mode === "edit" &&
            (!remove ? (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    alignItems: "flex-start",
                    gap: "8px",
                  }}
                >
                  <Button
                    type="small"
                    label="Cancel"
                    active={true}
                    onClick={() => onClose()}
                    color="--color-black"
                  />

                  <Button
                    type="small"
                    label="Delete"
                    active={true}
                    onClick={() => handleDeleBrand()}
                    color="--color-alarm"
                    allert={
                      <WidgetAlert
                        title={`Are you sure you want to add the page "${title}" to the directory?`}
                      />
                    }
                  />
                  <Button
                    type="small"
                    label="Save"
                    active={isButtonActive}
                    onClick={() => handleCreateOrUpdateDirectory()}
                    allert={
                      <WidgetAlert
                        title={`Are you sure you want to add the page "${title}" to the directory?`}
                      />
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    alignItems: "flex-start",
                    gap: "8px",
                  }}
                >
                  <Button
                    type="small"
                    label="Cancel"
                    active={true}
                    onClick={() => onClose()}
                    color="--color-black"
                  />

                  <Button
                    type="small"
                    label="Restore"
                    active={true}
                    onClick={() => handleRestoreBrand()}
                    allert={
                      <WidgetAlert
                        title={`Are you sure you want to restore the directory "${title}"?`}
                      />
                    }
                  />
                </div>
              </>
            ))}
        </ButtonsBar>

        {mode === "new" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel"
                active={true}
                onClick={() => onClose()}
                color="--color-black"
              />

              <Button
                type="small"
                label="Save"
                active={title !== "" && description !== ""}
                onClick={() => handleCreateOrUpdateDirectory()}
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to add the page "${title}" to the directory?`}
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}
      </div>
    </>
  );
};

export default WindowBrandDetailV4;

// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setCurrentRootData } from "../../../../../../reducersToolkit/catalogSliceV4";
// import { fetchRootDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
// import { createDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/createDataThunkV4";
// import { updateDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
// import { deleteDataThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/deleteDataThunkV4";
// import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
// import WidgetListFileV4 from "../../WidgetsProfile/UploadFiles/WidgetListFileV4";
// import WidgetUploadFilesV4 from "../../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
// import InputText from "../../WidgetsProfile/Inputs/InputText";
// import Textarea from "../../WidgetsProfile/Textarea/Textarea";
// import Button from "../../WidgetsProfile/Buttons/Button";
// import WidgetAlert from "../../WidgetsProfile/Alerts/WidgetAlert";
// import ButtonsBar from "../../WidgetsProfile/ButtonsBar/ButtonsBar";

// const WindowBrandDetailV4 = ({ mode, onClose }) => {
//   const dispatch = useDispatch();

//   const { loading, tempPreviewData, tempRootData } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);
//   const [newPhotoFiles, setNewPhotoFiles] = useState([]);
//   const [remove, setRemove] = useState(null);

//   const [originalTitle, setOriginalTitle] = useState("");
//   const [originalDescription, setOriginalDescription] = useState("");
//   const [loading_brandsIconFile, setLoading_brandsIconFile] = useState(false);

//   const [idRootDocument, setIdRootDocument] = useState(null);
//   const [idPreviewDocument, setIdPreviewDocument] = useState(null);
//   const [loading_brands, setLoading_brands_rootData] = useState(false);

//   const [cuurrentBrand, setRootCurrentBrand] = useState({});

//   useEffect(() => {
//     setLoading_brands_rootData(loading.loading_brands || false);
//     setLoading_brandsIconFile(loading.loading_brandsIconFile || false);
//   }, [loading.loading_brands, loading.loading_brandsIconFile]);

//   useEffect(() => {
//     setTitle(tempRootData?.brands_rootData?.title?.titleOriginal || "");
//     setDescription(
//       tempRootData?.brands_rootData?.description?.descriptionOriginal || ""
//     );
//     setOriginalTitle(tempRootData?.brands_rootData?.title?.titleOriginal || "");
//     setOriginalDescription(
//       tempRootData?.brands_rootData?.description?.descriptionOriginal || ""
//     );
//     setPreviewPhotoFiles(tempRootData?.brands_rootData?.files?.iconFiles || []);
//     setRootCurrentBrand(tempRootData?.brands_rootData || {});
//     setRemove(tempRootData?.brands_rootData?.remove || null);
//   }, [tempRootData?.brands_rootData]);

//   useEffect(() => {
//     setIdPreviewDocument(
//       tempPreviewData?.selectedBrand?.idPreviewDocument || null
//     );
//     setIdRootDocument(tempPreviewData?.selectedBrand?.idRootDocument || null);
//   }, [
//     tempPreviewData?.selectedBrand,
//     tempPreviewData?.subscribe_brandsPreview,
//   ]);

//   useEffect(() => {
//     if (mode === "edit") {
//       // Если есть idRootCountryId страны, выполняем запрос для получения данных для детализации
//       if (idRootDocument) {
//         console.log(
//           `Есть idRootCountryId: ${idRootDocument} страны, выполняем запрос для получения данных для детализации`
//         );

//         dispatch(
//           fetchRootDataThunkV4({
//             idRootDocument: idRootDocument,
//             // Путь до коллекции указывается из НЕ четного колличества вложенностей
//             rootCollectionPath: "brands",
//             // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
//             rootCurrentDocumentState: "brands_rootData",
//             // Название state который будет помогать отслежывать результаты работы thunk
//             loadingStateName: "loading_brands",
//             errorStateName: "currentError",
//           })
//         );
//       }
//     } else if (mode === "new") {
//       dispatch(
//         setCurrentRootData({
//           rootCurrentDocumentState: "brands_rootData",
//           data: {},
//         })
//       );
//     }
//   }, [dispatch, idRootDocument, mode]);

//   const handleCreateOrUpdateDirectory = async () => {
//     try {
//       if (mode === "new") {
//         dispatch(
//           createDataThunkV4({
//             rootData: rootCountryData,
//             previewData: previewCountryData,
//             files: {
//               iconFiles: newPhotoFiles,
//             },
//             rootCollectionPath: "brands",
//             previewCollectionPath: "brandsPreview",
//             metadataDocumentPath: "brandsPreview.metadata",
//             errorStateName: "currentError",
//             loadingStateName: "loading_create_brand",
//           })
//         );
//         onClose();
//       }
//       if (mode === "edit") {
//         if (idRootDocument) {
//           dispatch(
//             updateDataThunkV4({
//               idRootDocument: idRootDocument,
//               rootData: rootCountryData,
//               previewData: previewCountryData,
//               newFiles: {
//                 iconFiles: newPhotoFiles,
//               },
//               rootCollectionPath: "brands",
//               previewCollectionPath: "brandsPreview",
//               metadataDocumentPath: "brandsPreview.metadata",
//               rootCurrentDocumentState: "brands_rootData",
//               loadingStateName: "loading_updateBrands",
//               errorStateName: "currentError",
//             })
//           );
//           onClose();
//         }
//       }
//     } catch (error) {
//       console.error("Ошибка при создании или обновлении документа:", error);
//       onClose();
//     }
//   };

//   const handleDeleBrand = () => {
//     dispatch(
//       deleteDataThunkV4({
//         // Id документов у которых поле remove будет обновлено
//         idPreviewDocument: idPreviewDocument,
//         idRootDocument: idRootDocument,
//         // Определение путей коллекций
//         // Путь до коллекции указывается из НЕ четного колличества вложенностей
//         rootCollectionPath: "brands",
//         previewCollectionPath: "brandsPreview",
//         // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
//         metadataDocumentPath: "brandsPreview.metadata",
//         // Название state который будет содержать массив как результат работы thunk
//         // previewCurrentDocumenState: "brandsPreview_deleteBrand",
//         // Название state который будет помогать отслежывать результаты работы thunk
//         loadingStateName: "loading_deleBrand",
//         errorStateName: "currentError",
//       })
//     );
//     onClose();
//   };

//   const handleRestoreBrand = () => {
//     dispatch(
//       updateDataThunkV4({
//         idRootDocument: idRootDocument,
//         rootData: rootCountryData,
//         previewData: previewCountryData,
//         newFiles: {
//           iconFiles: newPhotoFiles,
//         },
//         rootCollectionPath: "brands",
//         previewCollectionPath: "brandsPreview",
//         metadataDocumentPath: "brandsPreview.metadata",
//         rootCurrentDocumentState: "brands_rootData",
//         loadingStateName: "loading_restoreBrand",
//         errorStateName: "currentError",
//       })
//     );

//     onClose();
//   };

//   const rootCountryData = {
//     ...cuurrentBrand,
//     title: {
//       titleOriginal: title,
//     },
//     description: {
//       descriptionOriginal: description,
//     },
//     createdat: Date.now(),
//     visibility: false,
//     remove: null,
//   };

//   const previewCountryData = {
//     title: title || "",
//     description: (description || "").slice(0, 250),
//     createdat: Date.now(),
//     visibility: false,
//     remove: null,
//   };

//   const handleSetTitle = (event) => {
//     setTitle(event.target.value);
//   };

//   const handleSetDescription = (event) => {
//     setDescription(event.target.value);
//   };

//   const isButtonActive =
//     newPhotoFiles.length > 0 ||
//     title !== originalTitle ||
//     description !== originalDescription;

//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "8px",
//           marginBottom: "72px",
//           // ...(mode === "new" && {
//           //   marginBottom: "72px",
//           // }),
//         }}
//       >
//         <ContentBox position="start" isLoading={loading_brands}>
//           <WidgetListFileV4
//             isLoading={loading_brandsIconFile}
//             // Передаём данные для отображения
//             viewFiles={previewPhotoFiles}
//             // Поле в котором находится массив с файлами
//             fieldName="files.iconFiles"
//             // Путь до коллекции указывается из НЕ четного колличества вложенностей
//             rootCollectionPath="brands"
//             previewCollectionPath="brandsPreview"
//             // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
//             metadataDocumentPath="brandsPreview.metadata"
//             // Редактируемые документы
//             idRootDocument={idRootDocument}
//             // Динамические состояния загрузки
//             loadingStateName="loading_brandsIconFile"
//             errorStateName="currentError"
//             // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
//             rootCurrentDocumentState="brands_rootData"
//             // Сохраняет в state МАССИВ объектов как результат выполнения thunk
//             previewCurrentDocumenState="brandsPreview"
//             // Режим
//             mode="edit"
//           />

//           <WidgetUploadFilesV4
//             isLoading={loading_brandsIconFile}
//             id="brandsIconFiles"
//             title="Add photo country"
//             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//             files={setNewPhotoFiles}
//           />

//           <InputText
//             id="Name"
//             label="Country"
//             description="Enter the name of the country."
//             value={title}
//             onChange={handleSetTitle}
//             type="text"
//           />

//           <Textarea
//             label="Description"
//             maxLength={5000}
//             value={description}
//             onChange={handleSetDescription}
//           />
//         </ContentBox>

//         <ButtonsBar>
//           {mode === "edit" &&
//             (!remove ? (
//               <>
//                 <div
//                   style={{
//                     width: "100%",
//                     display: "grid",
//                     gridTemplateColumns: "repeat(3, 1fr)",
//                     alignItems: "flex-start",
//                     gap: "8px",
//                   }}
//                 >
//                   <Button
//                     type="small"
//                     label="Cancel"
//                     active={true}
//                     onClick={() => onClose()}
//                     color="--color-black"
//                   />

//                   <Button
//                     type="small"
//                     label="Delete"
//                     active={true}
//                     onClick={() => handleDeleBrand()}
//                     color="--color-alarm"
//                     allert={
//                       <WidgetAlert
//                         title={`Are you sure you want to add the page "${title}" to the directory?`}
//                       />
//                     }
//                   />
//                   <Button
//                     type="small"
//                     label="Save"
//                     active={isButtonActive}
//                     onClick={() => handleCreateOrUpdateDirectory()}
//                     allert={
//                       <WidgetAlert
//                         title={`Are you sure you want to add the page "${title}" to the directory?`}
//                       />
//                     }
//                   />
//                 </div>
//               </>
//             ) : (
//               <>
//                 <div
//                   style={{
//                     width: "100%",
//                     display: "grid",
//                     gridTemplateColumns: "repeat(2, 1fr)",
//                     alignItems: "flex-start",
//                     gap: "8px",
//                   }}
//                 >
//                   <Button
//                     type="small"
//                     label="Cancel"
//                     active={true}
//                     onClick={() => onClose()}
//                     color="--color-black"
//                   />

//                   <Button
//                     type="small"
//                     label="Restore"
//                     active={true}
//                     onClick={() => handleRestoreBrand()}
//                     allert={
//                       <WidgetAlert
//                         title={`Are you sure you want to restore the directory "${title}"?`}
//                       />
//                     }
//                   />
//                 </div>
//               </>
//             ))}
//         </ButtonsBar>

//         {mode === "new" && (
//           <ButtonsBar>
//             <div
//               style={{
//                 width: "100%",
//                 display: "grid",
//                 gridTemplateColumns: "repeat(2, 1fr)",
//                 alignItems: "flex-start",
//                 gap: "8px",
//               }}
//             >
//               <Button
//                 type="small"
//                 label="Cancel"
//                 active={true}
//                 onClick={() => onClose()}
//                 color="--color-black"
//               />

//               <Button
//                 type="small"
//                 label="Save"
//                 active={title !== "" && description !== ""}
//                 onClick={() => handleCreateOrUpdateDirectory()}
//                 allert={
//                   <WidgetAlert
//                     title={`Are you sure you want to add the page "${title}" to the directory?`}
//                   />
//                 }
//               />
//             </div>
//           </ButtonsBar>
//         )}
//       </div>
//     </>
//   );
// };

// export default WindowBrandDetailV4;
