import React, { useEffect, useState } from "react";
import "../profile.css";
import "./userProfile.css";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowUserProfile from "./WindowUserProfile";
import Preloader from "../../../../UIElements/Preloader";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import priorityFile from "../../../../../functions/priorityFile";

const WidgetUserPreview = ({ user, isLoading }) => {
  const [isOpenWindowUserProfile, setIsOpenWindowUserProfile] = useState(false);
  const [email, setEmail] = useState("");
  const [ownerNamem, setOwnerName] = useState("");
  const [createdat, setCreatedat] = useState(0);
  const [url, setURL] = useState(null);

  useEffect(() => {
    const date = new Date(parseInt(user?.createdat, 10));
    const formattedDate = date.toLocaleDateString("en-US");

    const priorityFileselected = priorityFile(
      user?.files?.userAvatarFiles || []
    );

    setEmail(user?.userContacts?.email || "No email");
    setOwnerName(user?.user?.ownerName || "No name");
    setCreatedat(formattedDate);
    setURL(priorityFileselected?.url || null);
  }, [user]);

  const handleToggle = () => {
    setIsOpenWindowUserProfile(!isOpenWindowUserProfile);
  };

  const handleClose = () => {
    setIsOpenWindowUserProfile(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={isOpenWindowUserProfile}
        onClose={handleClose}
        title="User profile"
      >
        <WindowUserProfile onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      <ContentBox onClick={handleToggle}>
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="user-preview">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <AvatarUser size={80} url={url} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "4px",
                }}
              >
                <h6>{ownerNamem}</h6>
                <div>
                  <p className="p-light">Email: {email}</p>
                  <p className="p-light">{createdat}</p>
                </div>
              </div>
            </div>

            <div>
              <span className="material-symbols-outlined">edit</span>
            </div>
          </div>
        )}
      </ContentBox>
    </>
  );
};

export default WidgetUserPreview;
