import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
import { isDataStale } from "../fetchCatalogData/utils";

// Утилита для получения вложенного значения по строковому пути
const getNestedValue = (obj, path) => {
  if (!obj || !path) return undefined;
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

// Максимальное количество попыток
const MAX_RETRIES = 3;

// Список кодов ошибок, для которых будут выполняться повторные попытки
const RETRYABLE_ERRORS = [
  "unavailable", // Firestore backend не доступен
  "deadline-exceeded", // Превышено время ожидания запроса
  "internal", // Внутренняя ошибка Firestore
];

// Thunk для получения данных из Firestore или IndexedDB
export const fetchPreviewDataV4 = createAsyncThunk(
  "data/fetchPreviewDataV4",
  async (
    {
      previewCollectionPath,
      loadingStateName,
      errorStateName,
      previewCurrentDocumenState,
    },
    { getState, rejectWithValue }
  ) => {
    const state = getState().catalogSliceV4; // Состояние, содержащее данные и дату последнего обновления
    console.log("Текущее состояние catalogSliceV4:", state);

    const currentTimestamp = Date.now();
    let shouldFetch = false;

    // Используем утилиту getNestedValue для получения значения из состояния
    const nestedState = getNestedValue(state, previewCollectionPath);
    console.log(
      "Извлеченное состояние по пути:",
      previewCollectionPath,
      nestedState
    );

    // Проверяем, нужно ли выполнять запрос (данные устарели или отсутствуют)
    if (!nestedState?.lastUpdated || isDataStale(nestedState?.lastUpdated)) {
      console.log("Данные устарели или отсутствуют, выполняем запрос.");
      shouldFetch = true;
    }

    if (!shouldFetch) {
      console.log(
        "Данные актуальны, запрос не выполняется.",
        nestedState.data,
        nestedState.lastUpdated
      );

      return {
        previewCollectionPath, // Добавляем previewCollectionPath
        previewDocumentsData: nestedState.data, // Извлекаем данные из nestedState
        lastUpdated: nestedState.lastUpdated, // Используем lastUpdated из nestedState
        previewCurrentDocumenState,
        loadingStateName, // Добавляем loadingStateName
        errorStateName, // Добавляем errorStateName
      };
    }

    console.log("Начало механизма повторных попыток для получения данных...");

    // Механизм повторных попыток
    for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
      try {
        console.log(
          `Попытка ${attempt} запроса данных из Firestore по пути:`,
          previewCollectionPath
        );

        // Получаем ссылку на коллекцию в Firestore
        const collectionRef = collection(
          db,
          ...previewCollectionPath.split(".")
        );
        console.log("Ссылка на коллекцию:", collectionRef);

        // Получаем все документы из коллекции
        const snapshot = await getDocs(collectionRef);
        console.log("Снимок коллекции получен.");

        if (snapshot.empty) {
          console.log("Коллекция пуста или не существует.");

          return {
            previewCollectionPath,
            previewDocumentsData: [], // Возвращаем пустой массив
            lastUpdated: currentTimestamp,
            previewCurrentDocumenState,
            loadingStateName,
            errorStateName,
          };
        }

        // Инициализируем пустой массив для объединения всех данных
        let previewDocumentsData = [];

        // Проходимся по каждому документу и извлекаем массив data
        snapshot.forEach((doc) => {
          const docData = doc.data();
          if (Array.isArray(docData.data)) {
            console.log(
              `Добавление данных из документа ${doc.id}:`,
              docData.data
            );
            previewDocumentsData = previewDocumentsData.concat(docData.data); // Объединяем массивы data
          } else {
            console.warn(`Документ ${doc.id} не содержит массива data.`);
          }
        });

        // Возвращаем объединенные данные и время последнего обновления
        return {
          previewCollectionPath,
          previewDocumentsData,
          lastUpdated: currentTimestamp,
          previewCurrentDocumenState,
          loadingStateName,
          errorStateName,
        };
      } catch (error) {
        console.error(
          `Ошибка при попытке ${attempt} получения данных из Firestore:`,
          error
        );

        // Проверка, если ошибка является одной из "повторяемых"
        if (RETRYABLE_ERRORS.includes(error.code)) {
          console.log(
            `Ошибка ${error.code} позволяет выполнить повторную попытку.`
          );

          // Если это была последняя попытка, возвращаем ошибку
          if (attempt === MAX_RETRIES) {
            const errorMessage = interpretErrorV4(error.code);
            console.error("Превышено максимальное количество попыток.");
            return rejectWithValue({ errorMessage });
          }

          // Иначе выполняем повторную попытку
          continue;
        } else {
          // Если ошибка не позволяет повторить попытку, сразу возвращаем ее
          const errorMessage = interpretErrorV4(error.code);
          console.error(
            "Ошибка не позволяет выполнить повторную попытку.",
            errorMessage
          );
          return rejectWithValue({ errorMessage });
        }
      }
    }

    console.error(
      "Механизм повторных попыток завершен без успешного результата."
    );
  }
);
