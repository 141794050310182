import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button";
import InputText from "../WidgetsProfile/Inputs/InputText";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowBikePricePerMonthModeration = ({ mode, index, onClose }) => {
  const dispatch = useDispatch();

  const { tempRootData, rootNewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [inputPrice, setInputPrice] = useState("");
  const [inputDiscount, setInputDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState([]);
  const [newData, setNewData] = useState([]);
  const [applyButtonActive, setApplyButtonActive] = useState(false);

  /**
   *
   * Наполнение useState из slice
   *
   */
  useEffect(() => {
    if (mode === "edit" || mode === "moderation") {
      setRate(tempRootData?.selectedBike_rootData?.rate || []);
      const rate = tempRootData?.selectedBike_rootData?.rate || [];
      const currentRate = rate[index] || {};
      setInputPrice(currentRate.price || "");
      setInputDiscount(currentRate.discount || "");
      setDescription(currentRate.description?.descriptionOriginal || "");
    } else if (mode === "moderatorAdded") {
      setRate(tempRootData?.newRootBike_create?.rate || []);
      const rate = tempRootData?.newRootBike_create?.rate || [];
      const currentRate = rate[index] || {};
      setInputPrice(currentRate.price || "");
      setInputDiscount(currentRate.discount || "");
      setDescription(currentRate.description?.descriptionOriginal || "");
    }
  }, [
    tempRootData?.newRootBike_create?.rate,
    tempRootData?.selectedBike_rootData,
    mode,
    index,
  ]);

  /**
   *
   * Формирование объекта newData
   *
   */
  useEffect(() => {
    const editableRate = {
      month: index,
      price: inputPrice === "" ? 0 : Number(inputPrice),
      discount: inputDiscount === "" ? 0 : Number(inputDiscount),
      description: {
        descriptionOriginal: description,
      },
    };
    // Создаем новый массив, копируем все элементы из rate
    const updatedRate = [...rate];
    // Заменяем элемент по индексу
    updatedRate[index] = editableRate;
    // Обновляем локальный стейт newData
    setNewData(updatedRate);
  }, [index, inputPrice, inputDiscount, description, rate]);

  /**
   *
   * функции
   *
   */

  const rateAssignment = () => {
    if (mode === "moderation") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "selectedBike_rootData.rate",
          data: newData,
        })
      );
    } else if (mode === "moderatorAdded") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "newRootBike_create.rate",
          data: newData,
        })
      );
    }
  };

  const clearRate = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "newRootBike_create.rate",
        data: rootNewData.rootBikeData.rate || [],
      })
    );
  };

  /**
   *
   * Логика компонента
   *
   */

  useEffect(() => {
    const ActiveSaveButton = () => {
      const currentRate = rate[index] || {};
      return (
        currentRate.price !== Number(inputPrice) ||
        currentRate.discount !== Number(inputDiscount) ||
        currentRate.description?.descriptionOriginal !== description
      );
    };

    setApplyButtonActive(ActiveSaveButton());
  }, [index, inputDiscount, inputPrice, description, rate, mode]);

  const applyButton = () => {
    rateAssignment();
    onClose();
  };

  const handleClearRate = () => {
    clearRate();
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        marginBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <InputText
            label="Price"
            type="number"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={inputPrice}
            onChange={(event) => setInputPrice(event.target.value)}
          />

          <InputText
            label="Sale"
            type="number"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={inputDiscount}
            onChange={(event) => setInputDiscount(event.target.value)}
          />
        </div>
        <Textarea
          label="Rate description"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          maxLength={1000}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </ContentBox>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Clear"
            active={inputPrice !== ""}
            onClick={handleClearRate}
            color="--color-black"
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>Are you sure you want to delete the current rate?</h1>
                <p className="p-light">
                  This action will reset all entered data.
                </p>
              </div>
            }
          />
          <Button
            type="small"
            label="Save"
            active={applyButtonActive}
            onClick={applyButton}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowBikePricePerMonthModeration;
