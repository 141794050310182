import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
import { db } from "../../../../services/firebase/authFirebase";
import { updateDocumentFields } from "../../../../services/firebase/crudFirestore"; // Импорт функции обновления документов

export const createRentalPointThunkV4 = createAsyncThunk(
  "rentalPoints/createRentalPointThunkV4",
  async (
    { countryId, cityId, rentalPointId, userId, data, files },
    { rejectWithValue }
  ) => {
    try {
      // Создание ссылки на документ в коллекции "rentalPoints" внутри города
      const rentalPointDocRef = doc(
        collection(db, "catalog", countryId, "cities", cityId, "rentalPoints"),
        rentalPointId ||
          doc(
            collection(
              db,
              "catalog",
              countryId,
              "cities",
              cityId,
              "rentalPoints"
            )
          ).id
      );

      // Сохранение данных rental point в Firestore без файлов
      await setDoc(rentalPointDocRef, data);

      console.log("Точка проката успешно добавлена в Firestore:", {
        countryId,
        cityId,
        rentalPointId: rentalPointDocRef.id,
        data,
      });

      // Загрузка файлов, если они есть
      let uploadedFiles = {};

      // Функция для загрузки файлов по типам
      const uploadFilesByType = async (fileArray, folderName) => {
        if (fileArray && fileArray.length > 0) {
          return await uploadFilesToFirebase(
            `rentalPoints/${rentalPointDocRef.id}/${folderName}`,
            fileArray
          );
        }
        return [];
      };

      // Обработка всех массивов в объекте files
      if (files) {
        for (const [key, fileArray] of Object.entries(files)) {
          const uploaded = await uploadFilesByType(fileArray, key);
          uploadedFiles[key] = uploaded;
        }
      }

      // Обновление документа rental point с ссылками на загруженные файлы
      if (Object.keys(uploadedFiles).length > 0) {
        await updateDoc(rentalPointDocRef, { files: uploadedFiles });
        console.log("Документ точки проката обновлен с загруженными файлами.");
      }

      // Обновление документа пользователя с новым rentalPointId
      await updateDocumentFields("users", userId, {
        rentalPointsIds: [rentalPointDocRef.id], // Используем arrayUnion для добавления в массив
      });

      console.log(
        `rentalPointId ${rentalPointDocRef.id} добавлен в rentalPointsIds пользователя ${userId}.`
      );

      // Определение URL логотипа после загрузки
      const logoFileUrl =
        uploadedFiles.logoFile?.find((file) => file.priority)?.url ||
        uploadedFiles.logoFile?.[0]?.url ||
        "";

      // Формирование объекта для добавления в rentalPoints
      const rentalPointData = {
        countryId: countryId,
        cityId: cityId,
        rentalPointId: rentalPointDocRef.id,
        rentalPointName: data.basicInformation.title.titleOriginal || "", // Название точки проката из данных
        logoFile: logoFileUrl, // URL логотипа
        pointAddress: data.location.pointAddress || "", // Адрес точки проката из данных
      };

      // Получение ссылки на документ rentalPoints в коллекции indexed
      const rentalPointsDocRef = doc(db, "indexed", "rentalPoints");

      // Проверяем, существует ли документ rentalPoints
      const rentalPointsDocSnap = await getDoc(rentalPointsDocRef);

      if (rentalPointsDocSnap.exists()) {
        // Если документ существует, обновляем его
        await updateDoc(rentalPointsDocRef, {
          data: arrayUnion(rentalPointData),
        });
        console.log(
          "Объект добавлен в существующий документ rentalPoints:",
          rentalPointData
        );
      } else {
        // Если документа не существует, создаем его с массивом data
        await setDoc(rentalPointsDocRef, {
          data: [rentalPointData],
        });
        console.log(
          "Документ rentalPoints создан с объектом:",
          rentalPointData
        );
      }

      return {
        countryId,
        cityId,
        rentalPointId: rentalPointDocRef.id,
        data: { ...data, files: uploadedFiles },
      };
    } catch (error) {
      console.error("Ошибка при добавлении точки проката в Firestore:", error);
      return rejectWithValue(error.message);
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
// import { db } from "../../../../services/firebase/authFirebase";
// import { updateDocumentFields } from "../../../../services/firebase/crudFirestore"; // Импорт функции обновления документов

// export const createRentalPointThunkV4 = createAsyncThunk(
//   "rentalPoints/createRentalPointThunkV4",
//   async (
//     { countryId, cityId, rentalPointId, userId, data, files },
//     { rejectWithValue }
//   ) => {
//     try {
//       // Создание ссылки на документ в коллекции "rentalPoints" внутри города
//       const rentalPointDocRef = doc(
//         collection(db, "catalog", countryId, "cities", cityId, "rentalPoints"),
//         rentalPointId ||
//           doc(
//             collection(
//               db,
//               "catalog",
//               countryId,
//               "cities",
//               cityId,
//               "rentalPoints"
//             )
//           ).id
//       );

//       // Сохранение данных rental point в Firestore без файлов
//       await setDoc(rentalPointDocRef, data);

//       console.log("Точка проката успешно добавлена в Firestore:", {
//         countryId,
//         cityId,
//         rentalPointId: rentalPointDocRef.id,
//         data,
//       });

//       // Загрузка файлов, если они есть
//       let uploadedFiles = {};

//       // Функция для загрузки файлов по типам
//       const uploadFilesByType = async (fileArray, folderName) => {
//         if (fileArray && fileArray.length > 0) {
//           return await uploadFilesToFirebase(
//             `rentalPoints/${rentalPointDocRef.id}/${folderName}`,
//             fileArray
//           );
//         }
//         return [];
//       };

//       // Обработка всех массивов в объекте files
//       if (files) {
//         for (const [key, fileArray] of Object.entries(files)) {
//           const uploaded = await uploadFilesByType(fileArray, key);
//           uploadedFiles[key] = uploaded;
//         }
//       }

//       // Обновление документа rental point с ссылками на загруженные файлы
//       if (Object.keys(uploadedFiles).length > 0) {
//         await updateDoc(rentalPointDocRef, { files: uploadedFiles });
//         console.log("Документ точки проката обновлен с загруженными файлами.");
//       }

//       // Обновление документа пользователя с новым rentalPointId
//       await updateDocumentFields("users", userId, {
//         rentalPointsIds: [rentalPointDocRef.id], // Используем arrayUnion для добавления в массив
//       });

//       console.log(
//         `rentalPointId ${rentalPointDocRef.id} добавлен в rentalPointsIds пользователя ${userId}.`
//       );

//       return {
//         countryId,
//         cityId,
//         rentalPointId: rentalPointDocRef.id,
//         data: { ...data, files: uploadedFiles },
//       };
//     } catch (error) {
//       console.error("Ошибка при добавлении точки проката в Firestore:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );
