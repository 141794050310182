import React, { useState, useEffect } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowEditEmailModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const { tempRootData } = useSelector((state) => state.catalogSliceV4);

  const [email, setEmail] = useState("");
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [newEmailAddresses, setNewEmailAddresses] = useState([]);
  const [removedEmailAddresses, setRemovedEmailAddresses] = useState([]);

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setEmailAddresses(
        tempRootData?.rentalPoint_create_createRerntalPoint?.contacts
          ?.emailAddresses || []
      );
    } else if (mode === "moderation") {
      setEmailAddresses(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.contacts
          ?.emailAddresses || []
      );
    }
  }, [
    mode,
    tempRootData?.rentalPoint_create_createRerntalPoint?.contacts
      ?.emailAddresses,
    tempRootData?.rentalPoint_moderation_rootRerntalPoint?.contacts
      ?.emailAddresses,
  ]);

  const handleAddEmail = () => {
    const emailObject = {
      key: Date.now().toString(),
      email,
    };
    setNewEmailAddresses([...newEmailAddresses, emailObject]);
    setEmail("");
  };

  const handleRemoveEmail = (index) => {
    setNewEmailAddresses(newEmailAddresses.filter((_, i) => i !== index));
  };

  const handleRemoveLoadedEmail = (emailObject) => {
    // Удаляем email из локального состояния, а не из Redux
    setEmailAddresses(
      emailAddresses.filter((email) => email.key !== emailObject.key)
    );

    // Добавляем email в список удаленных
    setRemovedEmailAddresses([...removedEmailAddresses, emailObject]);
  };

  const handleApply = () => {
    if (mode === "moderation") {
      const updatedEmailAddresses = [...emailAddresses, ...newEmailAddresses];
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState:
            "rentalPoint_moderation_rootRerntalPoint.contacts.emailAddresses",
          data: updatedEmailAddresses,
        })
      );
      onClose();
    } else if (mode === "moderatorAdded") {
      const updatedEmailAddresses = [...emailAddresses, ...newEmailAddresses];
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState:
            "rentalPoint_create_createRerntalPoint.contacts.emailAddresses",
          data: updatedEmailAddresses,
        })
      );
      onClose();
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email address"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            <div style={{ width: "auto" }}>
              <Button
                label="Add"
                active={email ? true : false}
                onClick={handleAddEmail}
              />
            </div>
          </div>
        </ContentBox>

        {(emailAddresses.length > 0 || newEmailAddresses.length > 0) && (
          <ContentBox>
            <ul
              style={{
                width: "100%",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {emailAddresses.map((emailObj) => (
                <li
                  key={emailObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{emailObj.email}</h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemoveLoadedEmail(emailObj)}
                      allert={
                        <h1 style={{ padding: "48px", textAlign: "center" }}>
                          Are you sure you want to delete this email address?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}

              {newEmailAddresses.map((emailObj, index) => (
                <li
                  key={emailObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{emailObj.email}</h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemoveEmail(index)}
                      allert={
                        <h1 style={{ padding: "48px", textAlign: "center" }}>
                          Are you sure you want to delete this email address?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </ContentBox>
        )}
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            active={newEmailAddresses.length > 0}
            label="Save"
            onClick={handleApply}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Please confirm that you want to update the user information.
              </h1>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditEmailModeration;

// import React, { useState, useEffect } from "react";
// import InputText from "../WidgetsProfile/Inputs/InputText";
// import Button from "../WidgetsProfile/Buttons/Button";
// import { useDispatch, useSelector } from "react-redux";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import {
//   removeContactsEmailAddressModeration,
//   setContactsEmailAddressesModeration,
// } from "../../../../../reducersToolkit/moderationSliceV2";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
// import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

// const WindowEditEmailModeration = ({ mode, onClose }) => {
//   const dispatch = useDispatch();

//   const {
//     /** !!!Заменить!!! */
//     selectedRentalPoint,
//     /** */
//     tempRootData,
//     // tempData
//   } = useSelector((state) => state.catalogSliceV4);

//   const [email, setEmail] = useState("");
//   const [emailAddresses, setEmailAddresses] = useState([]);
//   const [newEmailAddresses, setNewEmailAddresses] = useState([]);

//   console.log(
//     "WindowEditEmailModeration tempRootData",
//     tempRootData.rentalPoint_create_createRerntalPoint.contacts.emailAddresses,
//     newEmailAddresses
//   );

//   useEffect(() => {
//     if (mode === "moderatorAdded") {
//       setEmailAddresses(
//         tempRootData.rentalPoint_create_createRerntalPoint.contacts
//           .emailAddresses || []
//       );
//     } else if (mode === "moderation") {
//       setEmailAddresses(selectedRentalPoint?.contacts?.emailAddresses || []);
//     }
//   }, [
//     mode,
//     tempRootData.rentalPoint_create_createRerntalPoint.contacts.emailAddresses,
//     selectedRentalPoint,
//   ]);

//   const handleAddEmail = () => {
//     const emailObject = {
//       key: Date.now().toString(),
//       email,
//     };
//     setNewEmailAddresses([...newEmailAddresses, emailObject]);
//     setEmail("");
//   };

//   const handleRemoveEmail = (index) => {
//     setNewEmailAddresses(newEmailAddresses.filter((_, i) => i !== index));
//   };

//   const handleRemoveLoadedEmail = (emailObject) => {
//     const updatedEmailAddresses = emailAddresses.filter(
//       (email) => email.key !== emailObject.key
//     );
//     dispatch(
//       setCurrentRootData({
//         rootCurrentDocumentState:
//           "rentalPoint_create_createRerntalPoint.contacts.emailAddresses",
//         data: updatedEmailAddresses,
//       })
//     );
//   };

//   const handleApply = () => {
//     const updatedEmailAddresses = [...emailAddresses, ...newEmailAddresses];

//     dispatch(
//       setCurrentRootData({
//         rootCurrentDocumentState:
//           "rentalPoint_create_createRerntalPoint.contacts.emailAddresses",
//         data: updatedEmailAddresses,
//       })
//     );
//     onClose();
//   };

//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           // height: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           gap: "8px",
//           marginBottom: "72px",
//         }}
//       >
//         <ContentBox position="start">
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "flex-start",
//               gap: "16px",
//             }}
//           >
//             <InputText
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               label="Email address"
//               description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//             />
//             <div
//               style={{
//                 width: "auto",
//               }}
//             >
//               <Button
//                 label="Add"
//                 active={email ? true : false}
//                 onClick={handleAddEmail}
//               />
//             </div>
//           </div>
//         </ContentBox>
//         {(emailAddresses.length > 0 || newEmailAddresses.length > 0) && (
//           <ContentBox>
//             <ul
//               style={{
//                 width: "100%",
//                 listStyle: "none",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "8px",
//               }}
//             >
//               {emailAddresses.map((emailObj) => (
//                 <li
//                   key={emailObj.key}
//                   style={{
//                     height: "56px",
//                     width: "100%",
//                     boxSizing: "border-box",
//                     borderRadius: "8px",
//                     backgroundColor: "var(--color-gray-100)",
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     padding: "0 8px 0 16px",
//                   }}
//                 >
//                   <h6>{emailObj.email}</h6>
//                   <div
//                     style={{
//                       width: "auto",
//                     }}
//                   >
//                     <Button
//                       onClick={() => handleRemoveLoadedEmail(emailObj)}
//                       allert={
//                         <h1
//                           style={{
//                             padding: "48px",
//                             textAlign: "center",
//                           }}
//                         >
//                           Are you sure you want to delete this email address?
//                           Please confirm your action.
//                         </h1>
//                       }
//                       active={true}
//                       color="--color-gray-100"
//                       type="icon"
//                       icon={
//                         <span className="material-symbols-outlined">
//                           delete
//                         </span>
//                       }
//                     />
//                   </div>
//                 </li>
//               ))}
//               {newEmailAddresses.map((emailObj, index) => (
//                 <li
//                   key={emailObj.key}
//                   style={{
//                     height: "56px",
//                     width: "100%",
//                     boxSizing: "border-box",
//                     borderRadius: "8px",
//                     backgroundColor: "var(--color-gray-100)",
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     padding: "0 8px 0 16px",
//                   }}
//                 >
//                   <h6>{emailObj.email}</h6>
//                   <div
//                     style={{
//                       width: "auto",
//                     }}
//                   >
//                     <Button
//                       onClick={() => handleRemoveEmail(index)}
//                       allert={
//                         <h1
//                           style={{
//                             padding: "48px",
//                             textAlign: "center",
//                           }}
//                         >
//                           Are you sure you want to delete this email address?
//                           Please confirm your action.
//                         </h1>
//                       }
//                       active={true}
//                       color="--color-gray-100"
//                       type="icon"
//                       icon={
//                         <span className="material-symbols-outlined">
//                           delete
//                         </span>
//                       }
//                     />
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           </ContentBox>
//         )}
//       </div>
//       <ButtonsBar>
//         <div
//           style={{
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)",
//             gap: "16px",
//             width: "100%",
//           }}
//         >
//           <Button
//             type="small"
//             active={true}
//             label="Cancel"
//             color="--color-black"
//             onClick={onClose}
//             allert={
//               <h1
//                 style={{
//                   padding: "80px",
//                   textAlign: "center",
//                 }}
//               >
//                 Are you sure you want to leave the editing window?
//               </h1>
//             }
//           />

//           <Button
//             type="small"
//             active={newEmailAddresses.length > 0 && true}
//             label="Save"
//             onClick={handleApply}
//             allert={
//               <h1
//                 style={{
//                   padding: "80px",
//                   textAlign: "center",
//                 }}
//               >
//                 Please confirm that you want to update the user information.
//               </h1>
//             }
//           />
//         </div>
//       </ButtonsBar>
//     </>
//   );
// };

// export default WindowEditEmailModeration;
