import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";

import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
import {
  setErrorState,
  setLoadingState,
  updateDocuments,
} from "../../../../catalogSliceV4";

// Слушаем изменения в коллекции Firestore
export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
  "catalog/subscribePreviewDocumentsThunkV4",
  async (
    {
      collectionPath,
      previewCurrentDocumenState,
      excludedIds = [],
      loadingStateName,
      errorStateName,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log("Инициализация подписки на коллекцию:", collectionPath);

      const collectionRef = collection(db, ...collectionPath.split("."));
      console.log("Ссылка на коллекцию Firestore создана:", collectionRef);

      // Устанавливаем подписку и получаем функцию отписки
      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          const filteredDocuments = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((doc) => !excludedIds.includes(doc.id));

          const combinedData = filteredDocuments.reduce((acc, doc) => {
            if (Array.isArray(doc.data)) {
              return acc.concat(doc.data);
            }
            return acc;
          }, []);

          dispatch(
            updateDocuments({
              documents: combinedData,
              previewCurrentDocumenState,
            })
          );

          dispatch(
            setLoadingState({
              loadingStateName,
              isLoading: false,
            })
          );
        },
        (error) => {
          const errorMessage = interpretErrorV4(error.code);
          console.error("Ошибка при подписке на Firestore:", errorMessage);
          dispatch(
            setErrorState({
              errorStateName,
              errorMessage,
            })
          );
          return rejectWithValue({ errorMessage });
        }
      );

      console.log("Подписка успешно установлена");
      return { unsubscribe }; // Возвращаем функцию отписки
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error("Ошибка при подписке на Firestore:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, onSnapshot } from "firebase/firestore";
// import { db } from "../../../../../services/firebase/authFirebase";

// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
// import {
//   setErrorState,
//   setLoadingState,
//   updateDocuments,
// } from "../../../../catalogSliceV4";

// // Слушаем изменения в коллекции Firestore
// export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
//   "catalog/subscribePreviewDocumentsThunkV4",
//   async (
//     {
//       collectionPath, // Путь к коллекции
//       previewCurrentDocumenState, // Путь в стейте для хранения данных
//       excludedIds = [], // Исключаемые ID документов из ответа функции (по умолчанию пустой массив)
//       loadingStateName, // Название состояния загрузки
//       errorStateName, // Название состояния ошибки
//     },
//     { dispatch, rejectWithValue }
//   ) => {
//     try {
//       console.log("Инициализация подписки на коллекцию:", collectionPath);

//       // Получаем ссылку на коллекцию
//       const collectionRef = collection(db, ...collectionPath.split("."));
//       console.log("Ссылка на коллекцию Firestore создана:", collectionRef);

//       // Подписка на изменения в коллекции
//       onSnapshot(
//         collectionRef,
//         (snapshot) => {
//           console.log("Получен снимок данных из Firestore:");
//           snapshot.docs.forEach((doc) => {
//             console.log(`Документ ID: ${doc.id}`, doc.data());
//           });

//           // Фильтруем документы, исключая те, у которых ID содержатся в excludedIds
//           const filteredDocuments = snapshot.docs
//             .map((doc) => ({
//               id: doc.id,
//               ...doc.data(),
//             }))
//             .filter((doc) => !excludedIds.includes(doc.id));

//           // Объединяем все массивы `data` из каждого документа в один массив
//           const combinedData = filteredDocuments.reduce((acc, doc) => {
//             if (Array.isArray(doc.data)) {
//               return acc.concat(doc.data);
//             }
//             return acc;
//           }, []);

//           console.log("Объединенные данные:", combinedData);

//           // Используем сгенерированный экшен updateDocuments для обновления данных в стейте
//           dispatch(
//             updateDocuments({
//               documents: combinedData, // Объединенный массив данных
//               previewCurrentDocumenState, // Путь для хранения данных
//             })
//           );

//           console.log(
//             "Завершена обработка документов. Сброс статуса загрузки."
//           );

//           // Используем сгенерированный экшен setLoadingState для изменения статуса загрузки
//           dispatch(
//             setLoadingState({
//               loadingStateName,
//               isLoading: false,
//             })
//           );
//         },
//         (error) => {
//           console.error("Ошибка подписки на документы Firestore:", error);
//           const errorMessage = interpretErrorV4(error.code);
//           console.log("Ошибка обработки:", errorMessage);

//           // Используем сгенерированный экшен setErrorState для изменения статуса ошибки
//           dispatch(
//             setErrorState({
//               errorStateName,
//               errorMessage,
//             })
//           );

//           rejectWithValue({ errorMessage });
//         }
//       );

//       console.log(
//         "Подписка на изменения в коллекции Firestore успешно инициирована."
//       );
//       return { success: true };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при подписке на документы Firestore:",
//         errorMessage
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
