import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
import { db } from "../../../../services/firebase/authFirebase";

export const createCityThunkV4 = createAsyncThunk(
  "cities/createCityThunkV4",
  async ({ countryId, cityId, data, files }, { rejectWithValue }) => {
    try {
      // Создание ссылки на документ в коллекции "cities" внутри страны
      const cityDocRef = doc(
        collection(db, "catalog", countryId, "cities"),
        cityId || doc(collection(db, "catalog", countryId, "cities")).id
      );

      // Сохранение данных в Firestore без файлов
      await setDoc(cityDocRef, data);

      console.log("Данные успешно добавлены в Firestore:", {
        countryId,
        cityId: cityDocRef.id,
        data,
      });

      // Загрузка файлов, если они есть
      let uploadedFiles = [];
      if (files && files.length > 0) {
        console.log("Загрузка файлов", { files });
        uploadedFiles = await uploadFilesToFirebase(
          `cities/${cityDocRef.id}`,
          files
        );
      }

      // Обновление документа в Firestore с ссылками на загруженные файлы
      const updatedData = {
        files: {
          iconFiles: uploadedFiles,
        },
      };

      if (uploadedFiles.length > 0) {
        await setDoc(cityDocRef, { ...data, ...updatedData }, { merge: true });
        console.log("Документ в Firestore обновлен с загруженными файлами.");
      }

      // Формирование объекта для добавления в citiesPrewiew
      const cityPreview = {
        title: {
          titleOriginal: data.title.titleOriginal || "",
        },
        filesUrl: {
          iconFiles:
            uploadedFiles.find((file) => file.priority)?.url ||
            uploadedFiles[0]?.url ||
            "", // URL файла с приоритетом или первый файл
        },
        id: cityDocRef.id,
      };

      // Получение текущих данных из документа citiesPrewiew
      const citiesPrewiewDocRef = doc(
        db,
        "catalog",
        countryId,
        "cities",
        "citiesPrewiew"
      );

      const citiesPreviewSnapshot = await getDoc(citiesPrewiewDocRef);
      const existingData = citiesPreviewSnapshot.exists()
        ? citiesPreviewSnapshot.data().data || []
        : [];

      // Добавление нового города в массив и установка документа заново
      await setDoc(citiesPrewiewDocRef, {
        data: arrayUnion(...existingData, cityPreview), // Сохраняем предыдущие и новые данные
      });

      console.log("Объект добавлен в документ citiesPrewiew:", cityPreview);

      return {
        countryId,
        cityId: cityDocRef.id,
        data: { ...data, ...updatedData },
      };
    } catch (error) {
      console.error("Ошибка при добавлении данных в Firestore:", error);
      return rejectWithValue(error.message);
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const createCityThunkV4 = createAsyncThunk(
//   "cities/createCityThunkV4",
//   async ({ countryId, cityId, data, files }, { rejectWithValue }) => {
//     try {
//       // Создание ссылки на документ в коллекции "cities" внутри страны
//       const cityDocRef = doc(
//         collection(db, "catalog", countryId, "cities"),
//         cityId || doc(collection(db, "catalog", countryId, "cities")).id
//       );

//       // Сохранение данных в Firestore без файлов
//       await setDoc(cityDocRef, data);

//       console.log("Данные успешно добавлены в Firestore:", {
//         countryId,
//         cityId: cityDocRef.id,
//         data,
//       });

//       // Загрузка файлов, если они есть
//       let uploadedFiles = [];
//       if (files && files.length > 0) {
//         console.log("Загрузка файлов", {
//           files,
//         });
//         uploadedFiles = await uploadFilesToFirebase(
//           `cities/${cityDocRef.id}`,
//           files
//         );
//       }

//       // Обновление документа в Firestore с ссылками на загруженные файлы
//       const updatedData = {
//         files: {
//           iconFiles: uploadedFiles,
//         },
//       };

//       if (uploadedFiles.length > 0) {
//         await updateDoc(cityDocRef, updatedData);
//         console.log("Документ в Firestore обновлен с загруженными файлами.");
//       }

//       return {
//         countryId,
//         cityId: cityDocRef.id,
//         data: { ...data, ...updatedData },
//       };
//     } catch (error) {
//       console.error("Ошибка при добавлении данных в Firestore:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );
