import React, { useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowPartnerRegistration from "./WindowPartnerRegistration";
import InformBox from "../WidgetsProfile/ContentBox/InformBox";
import Button from "../WidgetsProfile/Buttons/Button";
const AllertWindowOfferAccept = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "48px",
        padding: "48px",
        textAlign: "center",
      }}
    >
      <img
        alt=""
        src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripster.svg?alt=media&token=66931d08-c4f8-4754-9c10-c1d8233a2df7"
        style={{
          width: "48%",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <h1>We welcome new members!</h1>
        <p className="p-light">
          Please note, to register with our service, you need to either own a
          bike that you plan to rent out or have a rental service. This helps us
          ensure high quality and safety of the services provided.
        </p>
      </div>
    </div>
  );
};
const WidgetRentalOffer = ({ user }) => {
  const [windowOfferAccept, setWindowOfferAccept] = useState(false);

  const handleOpenWindowOfferAccept = () => {
    setWindowOfferAccept(true);
  };

  const handleClose = () => {
    setWindowOfferAccept(false);
  };

  return (
    <>
      {/* POPUP WINDOW */}
      <PopupDirrectory
        isOpen={windowOfferAccept}
        onClose={handleClose}
        title="Questionnaire"
      >
        <WindowPartnerRegistration />
      </PopupDirrectory>
      {/* POPUP WINDOW */}
      <InformBox>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <h5>We invite new partners to cooperate.</h5>
            <p className="p-light">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt justo odio, gravida tempus erat convallis id.
              Suspendisse maximus purus non tellus feugiat, ac laoreet diam
              feugiat.
            </p>
          </div>
          <Button
            type="small"
            active={true}
            label="Accept"
            onClick={handleOpenWindowOfferAccept}
            allert={<AllertWindowOfferAccept />}
          />
        </div>
      </InformBox>
    </>
  );
};

export default WidgetRentalOffer;
