import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import interpretErrorV4 from "../../../functions/interpretErrorV4";
import { fetchRootDataThunkV4 } from "../catalog/V4/CRUD/fetchRootDataThunkV4";

export const emailSignInThunkV4 = createAsyncThunk(
  "reducersToolkit/emailSignInThunkV4",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    const auth = getAuth();

    try {
      // Аутентифицируем пользователя с помощью Firebase Auth
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Проверяем, аутентифицирован ли пользователь
      if (!userCredential.user) {
        console.error("User is not authenticated.");
        throw new Error("User is not authenticated.");
      }

      // Чтение данных пользователя из Firestore и получение ID добавленного документа
      await dispatch(
        fetchRootDataThunkV4({
          idRootDocument: userCredential.user.uid,
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // rootCurrentDocumentState: "currentUser",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_fetchUsers_widgetSignIn",
          errorStateName: "widgetSignInErrorUsers",
        })
      );

      // Возвращаем данные пользователя вместе с ID добавленного документа
      //  return { currentUser: currentUser };
      return { userId: userCredential.user.uid };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error("Failed to sign in user. Error: ", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);
