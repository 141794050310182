import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetOrdersList from "./WidgetOrdersList";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";
import Preloader from "../../../../UIElements/Preloader";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderCreateNewOrderV4 from "./WindowOrderCreateNewOrderV4";

const WidgetOrders = ({ orders, isLoading }) => {
  const [orderType, setOrderType] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);

  // WINDOW
  const [windowCreateNewOrderV4, setWindowCreateNewOrderV4] = useState(false);

  useEffect(() => {
    if (orderType) {
      const filtered = orders?.filter(
        (order) => order.orderStatuses?.currentStatus === orderType
      );
      setFilteredOrders(filtered);
    }
  }, [orderType, orders]);

  /**
   *
   * Логика компонента
   *
   */

  const handleOpenWindowCreateNewOrderV4 = () => {
    setWindowCreateNewOrderV4(true);
  };

  const handleClose = () => {
    setWindowCreateNewOrderV4(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowCreateNewOrderV4}
        onClose={handleClose}
        title={`Create order`}
      >
        <WindowOrderCreateNewOrderV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}
      <ContentBox>
        {!isLoading ? (
          <>
            <AddData
              onClick={handleOpenWindowCreateNewOrderV4}
              icon={<span className="material-symbols-outlined">add</span>}
              title="Create new order"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            {/* <SegmentedControls
            options={[
              "new",
              "exchange",
              "in progress",
              "completed",
              "canceled",
            ]}
            onSelect="new"
            // label="Orders"
            selected={setOrderType}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tincidunt justo odio, gravida tempus erat convallis id. Suspendisse maximus purus non tellus feugiat, ac laoreet diam feugiat."
          /> */}

            {orderType && orders?.length > 0 && (
              <>
                <SegmentedControls
                  options={[
                    "new",
                    "exchange",
                    "in progress",
                    "completed",
                    "canceled",
                  ]}
                  onSelect="new"
                  // label="Orders"
                  selected={setOrderType}
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tincidunt justo odio, gravida tempus erat convallis id. Suspendisse maximus purus non tellus feugiat, ac laoreet diam feugiat."
                />
                <WidgetOrdersList filteredOrders={filteredOrders} />
              </>
            )}
          </>
        ) : (
          <Preloader />
        )}
      </ContentBox>
    </>
  );
};

export default WidgetOrders;
