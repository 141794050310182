import styled, { keyframes } from "styled-components";

const fadeInOut = keyframes`
  0% {
    background-color: rgba(128, 128, 128, 0.1);
  }
  100% {
    background-color: rgba(128, 128, 128, 0.9);
  }
`;

const AnimatedDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeInOut} 0.5s infinite alternate;
`;
export default AnimatedDiv;
