import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
import {
  setErrorState,
  setLoadingState,
  updateRootDocuments,
} from "../../../../catalogSliceV4";

// Для хранения функций отписки, связанных с конкретными документами
const unsubscribeMap = new Map();

// Подписка на изменения в документе Firestore
export const subscribeRootDocumentsThunkV4 = createAsyncThunk(
  "catalog/subscribeRootDocumentsThunkV4",
  async (
    {
      collectionPath,
      rootCurrentDocumenState,
      idRootDocument,
      loadingStateName,
      errorStateName,
      updateTime,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(
        "Инициализация подписки на документ:",
        `${collectionPath}/${idRootDocument}`
      );

      const documentRef = doc(db, ...collectionPath.split("."), idRootDocument);
      console.log("Ссылка на документ Firestore создана:", documentRef);

      // Если существует подписка на тот же документ, отписываемся от неё
      if (unsubscribeMap.has(idRootDocument)) {
        const previousUnsubscribe = unsubscribeMap.get(idRootDocument);
        if (typeof previousUnsubscribe === "function") {
          previousUnsubscribe();
          console.log(
            `Существующая подписка на документ ${idRootDocument} отменена`
          );
        }
      }

      // Подписка на документ и получение функции отписки
      const unsubscribe = onSnapshot(
        documentRef,
        (snapshot) => {
          let documentData = {};
          if (snapshot.exists()) {
            documentData = { id: snapshot.id, ...snapshot.data() };
          } else {
            console.warn(`Документ с ID ${idRootDocument} не найден.`);
          }

          dispatch(
            updateRootDocuments({
              documents: documentData,
              rootCurrentDocumenState,
            })
          );

          dispatch(
            setLoadingState({
              loadingStateName,
              isLoading: false,
            })
          );
        },
        (error) => {
          const errorMessage = interpretErrorV4(error.code);
          console.error("Ошибка при подписке на Firestore:", errorMessage);
          dispatch(
            setErrorState({
              errorStateName,
              errorMessage,
            })
          );
          return rejectWithValue({ errorMessage });
        }
      );

      // Сохраняем функцию отписки в Map
      unsubscribeMap.set(idRootDocument, unsubscribe);

      console.log("Подписка успешно установлена на документ:", idRootDocument);

      // Возвращаем объект без функции отписки для сериализуемости
      return { subscriptionEstablished: true };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error("Ошибка при подписке на Firestore:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);

// Функция для отписки от документа
export const unsubscribeRootDocument = (idRootDocument) => {
  if (unsubscribeMap.has(idRootDocument)) {
    const unsubscribe = unsubscribeMap.get(idRootDocument);
    if (typeof unsubscribe === "function") {
      unsubscribe();
      console.log(`Отписка от документа с ID ${idRootDocument} выполнена`);
    }
    unsubscribeMap.delete(idRootDocument);
  } else {
    console.log(`Нет подписки для документа с ID ${idRootDocument}`);
  }
};
