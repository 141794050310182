import React, { useState } from "react";
import WindowUserDetailModerationV4 from "./WindowUserDetailModerationV4";
import { useDispatch } from "react-redux";
import { setCurrentPreviewData } from "../../../../../reducersToolkit/catalogSliceV4";
import { v4 as uuidv4 } from "uuid";
import WidgetItemUserPreview from "./WidgetItemUserPreview";
import PopupAllert from "../WidgetsProfile/Popups/PopupAllert";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";

const WidgetUsersListModerationV4 = ({ usersModerationList }) => {
  const dispatch = useDispatch();

  const [windowPartnerDetailModeration, setWindowPartnerDetailModeration] =
    useState(false);

  const handleClose = () => {
    setWindowPartnerDetailModeration(false);
  };

  // Сохраняем выбраннй user preview
  const setCurrenPreviewUser = (userItem) => {
    dispatch(
      setCurrentPreviewData({
        currentPreviewDocumenState:
          "user_moderation_selectedPreviewCurrentData",
        data: userItem,
      })
    );
    setWindowPartnerDetailModeration(true);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowPartnerDetailModeration}
        onClose={handleClose}
        title="Partner detail Moderation"
      >
        <WindowUserDetailModerationV4 onClose={handleClose} mode="moderation" />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {usersModerationList?.map((userItem) => (
          <div
            key={uuidv4()}
            style={{
              width: "100%",
            }}
          >
            <WidgetItemUserPreview
              onClick={() => setCurrenPreviewUser(userItem)}
              user={userItem}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default WidgetUsersListModerationV4;
