/**
 * Функция для получения вложенного состояния по строковому пути.
 * @param {Object} state - Объект состояния.
 * @param {string} path - Путь к вложенному состоянию в виде строки, разделенной точками или просто строки из одного элемента.
 * @returns {*} - Значение во вложенном состоянии или undefined, если путь некорректен.
 */
export const getNestedState = (state, path) => {
  if (!state || typeof state !== "object") {
    console.error("getNestedState Некорректный объект состояния:", state, path);
    return undefined;
  }

  if (!path || typeof path !== "string") {
    console.error("getNestedState Некорректный путь:", state, path);
    return undefined;
  }

  return path.split(".").reduce((acc, key) => {
    if (!acc) return undefined; // Если на каком-то уровне пустое значение
    return acc[key];
  }, state);
};

/**
 * Функция для установки значения во вложенное состояние по строковому пути с созданием вложенностей при их отсутствии.
 * @param {Object} state - Объект состояния.
 * @param {string} path - Путь к вложенному состоянию в виде строки, разделенной точками или просто строки из одного элемента.
 * @param {*} value - Значение для установки во вложенном состоянии.
 */
export const setNestedState = (state, path, value) => {
  if (!state || typeof state !== "object") {
    console.error("setNestedState Некорректный объект состояния:", state);
    return;
  }

  if (!path || typeof path !== "string") {
    console.error("setNestedState Некорректный путь:", path);
    return;
  }

  const keys = path.split(".");
  keys.reduce((acc, key, index) => {
    // Преобразуем числовой ключ для массивов
    const isArrayIndex = !isNaN(key) && Array.isArray(acc);

    if (index === keys.length - 1) {
      // Последний ключ - устанавливаем значение
      if (isArrayIndex) {
        acc.splice(Number(key), 1, value); // Для массивов используем splice
      } else {
        acc[key] = value; // Для объектов просто присваиваем значение
      }
    } else {
      // Инициализируем структуру, если она отсутствует
      if (!acc[key]) {
        acc[key] = isNaN(keys[index + 1]) ? {} : [];
      }

      acc = acc[key]; // Переходим к следующему уровню вложенности
    }
    return acc;
  }, state);
};

/**
 * Функция для установки значения во вложенное состояние по строковому пути с созданием вложенностей при их отсутствии.
 * @param {Object} state - Объект состояния.
 * @param {string} path - Путь к вложенному состоянию в виде строки, разделенной точками или просто строки из одного элемента.
 * @param {*} value - Значение для установки во вложенном состоянии.
 */
// export const setNestedState = (state, path, value) => {
//   if (!state || typeof state !== "object") {
//     console.error(
//       "setNestedState Некорректный объект состояния:",
//       state,
//       path,
//       value
//     );
//     return;
//   }

//   if (!path || typeof path !== "string") {
//     console.error("setNestedState Некорректный путь:", state, path, value);
//     return;
//   }

//   const keys = path.split(".");

//   keys.reduce((acc, key, index) => {
//     if (index === keys.length - 1) {
//       acc[key] = value; // Устанавливаем значение на последнем уровне
//     } else {
//       if (!acc[key] || typeof acc[key] !== "object") {
//         // Инициализируем объект, если не существует или некорректный тип
//         acc[key] = {};
//       }
//     }
//     return acc[key];
//   }, state);
// };
