import React, { useState } from "react";
import InputText from "../Profile/WidgetsProfile/Inputs/InputText";
import Button from "../Profile/WidgetsProfile/Buttons/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailSignUpThunkV4 } from "../../../../reducersToolkit/thunk/user/emailSignUpThunkV4";
import { googleSignUpThunkV4 } from "../../../../reducersToolkit/thunk/user/googleSignUpThunkV4";

const WidgetSignUp = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [userName, setUserName] = useState("");

  const handleSetLogin = (event) => {
    setLogin(event.target.value);
  };

  const handleSetPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSetConfirmationPassword = (event) => {
    setConfirmationPassword(event.target.value);
  };

  const handleSetUserName = (event) => {
    setUserName(event.target.value);
  };

  const handleEmailSignUp = () => {
    dispatch(
      emailSignUpThunkV4({
        email: login,
        password: password,
        userName: userName,
        createdat: Date.now().toString(),
      })
    )
      .unwrap()
      .then(() => {
        navigate("/profile");
        onClose();
      })
      .catch((error) => {
        console.error("Error during registration: ", error.errorMessage);
      });
  };

  const handleGoogleAuth = async () => {
    dispatch(googleSignUpThunkV4())
      .unwrap()
      .then(() => {
        navigate("/profile");
        onClose();
      })
      .catch((error) => {
        console.error("Error during registration: ", error.errorMessage);
      });
  };

  // const handleClearIndexedDB = () => {
  //   const request = indexedDB.deleteDatabase("myApp");

  //   request.onsuccess = function () {
  //     console.log("Database myApp deleted successfully");
  //   };

  //   request.onerror = function () {
  //     console.error("Error deleting database myApp");
  //   };

  //   request.onblocked = function () {
  //     console.warn("Database myApp deletion is blocked");
  //   };
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <InputText
          type="text"
          label="Email"
          description="Please enter your name as you would like to be addressed."
          value={login}
          onChange={handleSetLogin}
        />
        <InputText
          type="password"
          label="Password"
          description="Please enter your name as you would like to be addressed."
          value={password}
          onChange={handleSetPassword}
        />
        <InputText
          type="Password"
          label="Password confirmation"
          description="Please enter your name as you would like to be addressed."
          value={confirmationPassword}
          onChange={handleSetConfirmationPassword}
        />
        <InputText
          type="text"
          label="User name"
          description="Please enter your name as you would like to be addressed."
          value={userName}
          onChange={handleSetUserName}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "24px 0",
          }}
        >
          <Button
            active={
              login !== "" &&
              password !== "" &&
              password === confirmationPassword
            }
            label="Registration"
            onClick={handleEmailSignUp}
          />
          <Button
            active={true}
            color="--color-black"
            label="Registration using Google"
            onClick={handleGoogleAuth}
          />
        </div>
      </div>
    </>
  );
};

export default WidgetSignUp;
