import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetListFileV4 from "../WidgetsProfile/UploadFiles/WidgetListFileV4";
import WidgetUploadFilesV4 from "../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import { useDispatch, useSelector } from "react-redux";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { v4 as uuidv4 } from "uuid";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowUploadFilesModeration = ({ onClose }) => {
  const dispatch = useDispatch();

  const { loading, tempRootData, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [idRootDocument, setIdRootDocument] = useState(null);
  const [idPreviewDocument, setIdPreviewDocument] = useState(null);
  const [userAvatarNewFiles, setUserAvatarNewFiles] = useState([]);
  const [userAvatarFiles, setUserAvatarFiles] = useState([]);

  const [currentRootUser, setCurrenRootUser] = useState(null);

  const [currentPreviewUser, setCurrentPreviewUser] = useState(null);

  const [isSaveActive, setIsSaveActive] = useState(false); // Управление активностью кнопки

  /** LOADING */
  const [
    loading_user_moderation_rootCurrentData,
    setLoading_user_moderation_rootCurrentData,
  ] = useState(false);
  const [loading_userAvatarFiles, setLoading_userAvatarFiles] = useState(false);
  const [loading_updateUserData, setLoading_updateUserData] = useState(false);

  /** Update Loading States */
  useEffect(() => {
    setLoading_userAvatarFiles(loading?.loading_userAvatarFiles || false);
    setLoading_updateUserData(
      loading?.loading_moderation_uploadFilesRootCurrentData || false
    );
    setLoading_user_moderation_rootCurrentData(
      loading?.loading_user_moderation_rootCurrentData || false
    );
  }, [
    loading?.loading_userAvatarFiles,
    loading?.loading_moderation_uploadFilesRootCurrentData,
    loading?.loading_user_moderation_rootCurrentData,
  ]);

  /** Update User Data */
  useEffect(() => {
    setCurrenRootUser(tempRootData?.user_moderation_rootCurrentData || null);

    setUserAvatarFiles(
      tempRootData?.user_moderation_rootCurrentData?.files?.userAvatarFiles ||
        null
    );
  }, [tempRootData.user_moderation_rootCurrentData]);

  useEffect(() => {
    setCurrentPreviewUser(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData || null
    );
    setIdRootDocument(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.idRootDocument || null
    );
    setIdPreviewDocument(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.idPreviewDocument || null
    );
  }, [tempPreviewData?.user_moderation_selectedPreviewCurrentData]);

  useEffect(() => {
    // Если есть rootUserId, выполняем запрос для получения данных для детализации
    if (idRootDocument) {
      console.log(
        `Есть idRootDocument: ${idRootDocument} выполняем запрос для получения данных для детализации`
      );
      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: idRootDocument,
          rootCollectionPath: "users",

          rootCurrentDocumentState: "user_moderation_rootCurrentData",

          loadingStateName: "loading_fetchRootUserData",
          errorStateName: "error_fetchUserRootData",
        })
      );
    }
  }, [dispatch, idRootDocument, loading_user_moderation_rootCurrentData]);

  /** Активность кнопки сохранения на основе изменений в файлах */
  useEffect(() => {
    setIsSaveActive(userAvatarNewFiles.length > 0); // Активируем кнопку только при наличии новых файлов
  }, [userAvatarNewFiles]);

  /** Handle Update Fields */
  const handleUpdateFields = async () => {
    const rootUserData = {
      ...currentRootUser,
    };

    const priorityFile = userAvatarFiles?.find(
      (file) => file?.priority === true
    );

    const previewUserData = {
      ...currentPreviewUser,
      fileUrl: priorityFile ? priorityFile.url : "",
    };

    try {
      const resultAction = await dispatch(
        updateDataThunkV4({
          idPreviewDocument: idPreviewDocument,
          idRootDocument: idRootDocument,

          rootData: rootUserData,
          previewData: previewUserData,

          newFiles: {
            userAvatarFiles: userAvatarNewFiles,
          },

          // Имя того массива файлов который будет использоваться для назначения иконки
          iconFields: "userAvatarFiles",
          rootCollectionPath: "users",
          previewCollectionPath: "usersPreview",
          // previewGeneralCollectionPath:'',
          metadataDocumentPath: "usersPreview.metadata",
          // metadataGeneralDocumentPath:'',
          rootCurrentDocumentState: "user_moderation_rootCurrentData",
          // previewGeneralDocumenState:'',
          loadingStateName: "loading_user_moderation_rootCurrentData",
          errorStateName: "error_moderation_uploadFilesRootCurrentData",
        })
      );

      if (updateDataThunkV4.fulfilled.match(resultAction)) {
        console.log("Данные успешно обновлены:", resultAction.payload);
        dispatch(
          setCurrentRootData({
            rootCurrentDocumentState: "user_auth_rootCurrentData",
            data: rootUserData,
          })
        );
      } else {
        console.error("Ошибка обновления данных:", resultAction.error.message);
      }
    } catch (error) {
      console.error("Ошибка вызова thunk:", error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "72px",
      }}
    >
      <ContentBox position="start">
        {/* Компонент WidgetListFileV4 нужен для редактирования не для добавления (deleteFilesThunkV4, updateFilePriorityThunkV4) */}
        <WidgetListFileV4
          isLoading={
            loading_userAvatarFiles ||
            loading_updateUserData ||
            loading_user_moderation_rootCurrentData
          }
          // Передаём данные для отображения
          viewFiles={userAvatarFiles}
          // Поле в котором находится массив с файлами
          fieldName="files.userAvatarFiles"
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath="users"
          previewCollectionPath="usersPreview"
          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath="usersPreview.metadata"
          // Редактируемые документы
          idRootDocument={idRootDocument}
          // Динамические состояния загрузки
          loadingStateName="loading_userAvatarFiles"
          errorStateName="currentError"
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState={[
            "user_moderation_rootCurrentData",
            "user_auth_rootCurrentData",
          ]}
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState="general_usersPreviewNotifications"
          // Режим
          mode="edit"
        />

        <WidgetUploadFilesV4
          isLoading={
            loading_userAvatarFiles ||
            loading_updateUserData ||
            loading_user_moderation_rootCurrentData
          }
          id={uuidv4()}
          title="Add photo user"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          files={setUserAvatarNewFiles}
        />

        <ButtonsBar>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
              }}
            >
              <Button
                type="small"
                active={true}
                label="Cancel"
                onClick={onClose}
                color="--color-black"
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Are you sure you want to leave the editing window?
                  </h1>
                }
              />
              <Button
                type="small"
                active={isSaveActive} // Кнопка активна только при изменениях в файлах
                label="Save"
                onClick={handleUpdateFields}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Please confirm that you want to update the user information.
                  </h1>
                }
              />
            </div>
          </div>
        </ButtonsBar>
      </ContentBox>
    </div>
  );
};

export default WindowUploadFilesModeration;

// import React, { useEffect, useState } from "react";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import WidgetListFileV4 from "../WidgetsProfile/UploadFiles/WidgetListFileV4";
// import WidgetUploadFilesV4 from "../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
// import { useDispatch, useSelector } from "react-redux";
// import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
// import Button from "../WidgetsProfile/Buttons/Button";
// import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";

// const WindowUploadFilesModeration = ({ onClose }) => {
//   const dispatch = useDispatch();

//   const { loading, tempRootData, tempPreviewData } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   const [idRootDocument, setIdRootDocument] = useState(null);

//   const [userAvatarNewFiles, setUserAvatarNewFiles] = useState([]);
//   const [userAvatarFiles, setUserAvatarFiles] = useState([]);

//   const [currentRootUser, setCurrenRootUser] = useState(null);
//   const [currentPreviewUser, setCurrentPreviewUser] = useState(null);

//   /** LOADING */
//   const [
//     loading_userAvatarFiles,
//     setLoading_moderation_uploadFilesRootCurrentData,
//   ] = useState(false);
//   const [
//     loading_moderation_uploadFilesRootCurrentData,
//     setLoading_updateUserData,
//   ] = useState(false);

//   const [resetSelectedFiles, setResetSelectedFiles] = useState(false);

//   /** Update Loading States */
//   useEffect(() => {
//     setLoading_moderation_uploadFilesRootCurrentData(
//       loading?.loading_userAvatarFiles || false
//     );
//     setLoading_updateUserData(
//       loading?.loading_moderation_uploadFilesRootCurrentData || false
//     );
//   }, [
//     loading.loading_userAvatarFiles,
//     loading.loading_moderation_uploadFilesRootCurrentData,
//   ]);

//   /** Update User Data */
//   useEffect(() => {
//     setCurrenRootUser(tempRootData?.user_moderation_rootCurrentData || null);

//     setUserAvatarFiles(
//       tempRootData?.user_moderation_rootCurrentData?.files?.userAvatarFiles ||
//         null
//     );
//   }, [tempRootData.user_moderation_rootCurrentData]);

//   useEffect(() => {
//     setCurrentPreviewUser(
//       tempPreviewData?.user_moderation_selectedPreviewCurrentData || null
//     );
//     setIdRootDocument(
//       tempPreviewData?.user_moderation_selectedPreviewCurrentData
//         ?.idRootDocument
//     );
//   }, [tempPreviewData?.user_moderation_selectedPreviewCurrentData]);

//   useEffect(() => {
//     // Если есть rootUserId, выполняем запрос для получения данных для детализации
//     if (idRootDocument) {
//       console.log(
//         `Есть idRootDocument: ${idRootDocument} выполняем запрос для получения данных для детализации`
//       );
//       dispatch(
//         fetchRootDataThunkV4({
//           idRootDocument: idRootDocument,
//           // Путь до коллекции указывается из НЕ четного колличества вложенностей
//           rootCollectionPath: "users",
//           // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
//           rootCurrentDocumentState: "user_moderation_rootCurrentData",
//           // Название state который будет помогать отслежывать результаты работы thunk
//           loadingStateName: "loading_fetchRootUserData",
//           errorStateName: "error_fetchUserRootData",
//         })
//       );
//     }
//   }, [dispatch, idRootDocument]);

//   /** Handle Update Fields */
//   const handleUpdateFields = async () => {
//     const rootUserData = {
//       ...currentRootUser,
//     };

//     const priorityFile = userAvatarFiles?.find(
//       (file) => file?.priority === true
//     );

//     const previewUserData = {
//       ...currentPreviewUser,
//       fileUrl: priorityFile ? priorityFile.url : "",
//     };

//     try {
//       const resultAction = await dispatch(
//         updateDataThunkV4({
//           // Указываем id preview документа который будем менять
//           idPreviewDocument: null,
//           // Указываем id root Элемента
//           idRootDocument: idRootDocument,
//           // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
//           rootData: rootUserData,
//           previewData: previewUserData,
//           // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
//           newFiles: {
//             userAvatarFiles: userAvatarNewFiles,
//             /* Другие массивы файлов. Массивов файлов может быть несколько*/
//           },
//           // Путь до коллекции указывается из НЕ четного количества вложенностей
//           rootCollectionPath: "users",
//           previewCollectionPath: "usersPreview",
//           // Путь до документа указывается из ЧЕТНОГО количества вложенностей
//           metadataDocumentPath: "usersPreview.metadata",
//           /*************/
//           // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
//           rootCurrentDocumentState: "user_moderation_rootCurrentData",
//           // Сохраняет в state МАССИВ объектов как результат выполнения thunk
//           // previewCurrentDocumenState: "user_moderation_previewCurrentData",
//           // Эти состояния будут отображать состояния загрузки и ошибки
//           /*************/
//           loadingStateName: "loading_moderation_uploadFilesRootCurrentData",
//           errorStateName: "error_moderation_uploadFilesRootCurrentData",
//         })
//       );

//       if (updateDataThunkV4.fulfilled.match(resultAction)) {
//         console.log("Данные успешно обновлены:", resultAction.payload);
//         setResetSelectedFiles(true); // Сбрасываем выбранные файлы
//         // onClose(); // Закомментируйте, если не хотите закрывать окно сразу
//       } else {
//         console.error("Ошибка обновления данных:", resultAction.error.message);
//         // onClose();
//       }
//     } catch (error) {
//       console.error("Ошибка вызова thunk:", error);
//       // onClose();
//     }
//   };

//   return (
//     <div
//       style={{
//         paddingBottom: "72px",
//       }}
//     >
//       <ContentBox position="start">
//         <WidgetUploadFilesV4
//           isLoading={
//             loading_moderation_uploadFilesRootCurrentData ||
//             loading_userAvatarFiles
//           }
//           id="userAvatarFiles2"
//           title="Add user avater"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//           files={setUserAvatarNewFiles}
//           resetFiles={resetSelectedFiles} // Передаем флаг сброса
//           setResetFiles={setResetSelectedFiles} // Передаем функцию для сброса флага
//         />
//         <WidgetListFileV4
//           isLoading={
//             loading_moderation_uploadFilesRootCurrentData ||
//             loading_userAvatarFiles
//           }
//           // Передаём данные для отображения
//           viewFiles={userAvatarFiles}
//           // Поле в котором находится массив с файлами
//           fieldName="files.userAvatarFiles"
//           // Путь до коллекции указывается из НЕ четного количества вложенностей
//           rootCollectionPath="users"
//           previewCollectionPath="usersPreview"
//           // Путь до документа указывается из ЧЕТНОГО количества вложенностей
//           metadataDocumentPath="usersPreview.metadata"
//           // Редактируемые документы
//           idPreviewDocument={null}
//           idRootDocument={idRootDocument}
//           // Динамические состояния загрузки
//           loadingStateName="loading_userAvatarFiles"
//           errorStateName="error_userAvatarFiles"
//           // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
//           rootCurrentDocumentState="user_moderation_rootCurrentData"
//           // Сохраняет в state МАССИВ объектов как результат выполнения thunk
//           previewCurrentDocumenState="user_profile_previewCurrentData"
//           // Режим
//           mode="edit"
//         />

//         <ButtonsBar>
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               gap: "16px",
//             }}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 display: "grid",
//                 gridTemplateColumns: "repeat(2, 1fr)",
//                 gap: "16px",
//               }}
//             >
//               <Button
//                 type="small"
//                 active={true}
//                 label="Cancel"
//                 onClick={onClose}
//                 color="--color-black"
//               />
//               <Button
//                 type="small"
//                 active={true}
//                 label="Save"
//                 onClick={handleUpdateFields}
//                 allert={
//                   <h1
//                     style={{
//                       padding: "80px",
//                       textAlign: "center",
//                     }}
//                   >
//                     Please confirm that you want to update the user information.
//                   </h1>
//                 }
//               />
//             </div>
//           </div>
//         </ButtonsBar>
//       </ContentBox>
//     </div>
//   );
// };

// export default WindowUploadFilesModeration;
