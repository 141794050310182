import React, { useEffect, useState } from "react";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { confirmOrderStatusThunk } from "../../../../../reducersToolkit/thunk/orders/confirmOrderStatusThunk";
import { useDispatch } from "react-redux";

const WidgetOrderCancel = ({ currentOrder }) => {
  const dispatch = useDispatch();

  const [userId, setUserId] = useState("");
  const [motivation, setMotivation] = useState(null);

  useEffect(() => {
    setUserId(currentOrder.id);
  }, [currentOrder]);

  const updateData = {
    orderStatuses: {
      currentStatus: "canceled",

      historyChanges: [
        {
          orderStatus: "canceled",
          motivation: motivation,
          userId: userId,
          timestamp: Date.now().toString(),
        },
      ],
    },
  };

  const handkeConfirmOrderStatus = () => {
    if (motivation.trim()) {
      dispatch(
        confirmOrderStatusThunk({
          orderId: currentOrder.id,
          updateData: updateData,
        })
      )
        .unwrap()
        .then(() => {
          console.log("Order status updated successfully!");
        })
        .catch((error) => {
          console.error("Failed to update order status: ", error);
        });
    } else {
      console.error("Motivation is empty!");
    }
  };

  const handleMotivationChange = (e, index, newValue) => {
    setMotivation(newValue);
  };

  return (
    <>
      <Textarea
        label="Reason for replacement"
        maxLength={500}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        value={motivation}
        onChange={handleMotivationChange}
        allert={
          <h1
            style={{
              padding: "24px",
              textAlign: "center",
            }}
          >
            Please confirm that you are canceling the order that the customer
            placed
          </h1>
        }
      />
      <Button
        label="Cancel"
        color="--color-alarm"
        active={motivation}
        onClick={handkeConfirmOrderStatus}
      />
    </>
  );
};

export default WidgetOrderCancel;
