import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import { useDispatch, useSelector } from "react-redux";
import { getWeekDayName } from "../RentalPointsV3/functions/getWeekDayName";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import DoubleDropdownList from "../WidgetsProfile/DropdownList/DoubleDropdownList";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { setTimetableInModeration } from "../../../../../reducersToolkit/moderationSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowEditTimetableModeration = ({ mode, indexDay, onClose }) => {
  const dispatch = useDispatch();

  const { tempRootData } = useSelector((state) => state.catalogSliceV4);

  /** */

  const { newRentalPoint, selectedRentalPoint } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [timetable, setTimetable] = useState([]);
  const [openingHoursTime, setOpeningHoursTime] = useState("");
  const [openingMinutesTime, setOpeningMinutesTime] = useState("");

  const [closingHoursTime, setClosingHoursTime] = useState("");
  const [closingMinutesTime, setClosingMinutesTime] = useState("");

  const [selectedDay, setSelectedDay] = useState();
  const [timeTableDescription, setTimeTableDescription] = useState();
  const [historyChanges, setHistoryChanges] = useState({});

  const [newData, setNewData] = useState(null);

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setTimetable(
        tempRootData.rentalPoint_create_createRerntalPoint.timetable || []
      );
    } else if (mode === "moderation") {
      setTimetable(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.timetable || []
      );
    }
  }, [
    tempRootData?.rentalPoint_moderation_rootRerntalPoint,
    tempRootData.rentalPoint_create_createRerntalPoint,
    mode,
  ]);

  useEffect(() => {
    const currentTimetable = timetable[indexDay] || {};
    setOpeningHoursTime(currentTimetable.openHours || "");
    setOpeningMinutesTime(currentTimetable.openMinutes || "");

    setClosingHoursTime(currentTimetable.closeHours || "");
    setClosingMinutesTime(currentTimetable.closeMinutes || "");

    setSelectedDay(currentTimetable.isHoliday ? "Day off" : "Working day");
    setTimeTableDescription(
      currentTimetable.description?.descriptionOriginal || ""
    );
    setHistoryChanges(currentTimetable.historyChanges || {});
  }, [timetable, indexDay]);

  useEffect(() => {
    setNewData({
      weekday: { weekday_En: getWeekDayName(indexDay) },
      openHours: openingHoursTime.toString(),
      openMinutes: openingMinutesTime.toString(),
      closeHours: closingHoursTime.toString(),
      closeMinutes: closingMinutesTime.toString(),
      description: {
        descriptionOriginal: timeTableDescription,
      },
      isHoliday: selectedDay === "Day off" ? true : false,
    });
  }, [
    indexDay,
    closingHoursTime,
    closingMinutesTime,
    openingHoursTime,
    openingMinutesTime,
    selectedDay,
    timeTableDescription,
  ]);

  const handleApply = () => {
    if (mode === "moderatorAdded") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: `rentalPoint_create_createRerntalPoint.timetable.${indexDay}`,
          data: newData,
        })
      );
      onClose();
    } else if (mode === "moderation") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: `rentalPoint_moderation_rootRerntalPoint.timetable.${indexDay}`,
          data: newData,
        })
      );
      onClose();
    }
  };

  const hours = Array.from({ length: 25 }, (_, index) => index);
  const minutes = Array.from({ length: 61 }, (_, index) =>
    index.toString().padStart(2, "0")
  );

  const hoursOptions = {
    label: "Hours",
    value: hours,
  };

  const minutesOptions = {
    label: "Minutes",
    value: minutes,
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div className="profile-widget__content-box">
            <SegmentedControls
              options={["Working day", "Day off"]}
              onSelect={selectedDay}
              selected={setSelectedDay}
              label="SegmentedControls"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            />

            {selectedDay === "Working day" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "16px",
                  }}
                >
                  <DoubleDropdownList
                    optionsFirst={hoursOptions}
                    optionsSecond={minutesOptions}
                    onSelectFirst={setOpeningHoursTime}
                    onSelectSecond={setOpeningMinutesTime}
                    label={
                      openingHoursTime && openingMinutesTime
                        ? `${openingHoursTime}:${openingMinutesTime}`
                        : "Open time"
                    }
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    selectedOptionFirst={openingHoursTime}
                    selectedOptionSecond={openingMinutesTime}
                  />

                  <DoubleDropdownList
                    optionsFirst={hoursOptions}
                    optionsSecond={minutesOptions}
                    onSelectFirst={setClosingHoursTime}
                    onSelectSecond={setClosingMinutesTime}
                    label={
                      closingHoursTime && closingMinutesTime
                        ? `${closingHoursTime}:${closingMinutesTime}`
                        : "Close time"
                    }
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    selectedOptionFirst={closingHoursTime}
                    selectedOptionSecond={closingMinutesTime}
                  />
                </div>
                <Textarea
                  label="Description"
                  maxLength={500}
                  onChange={(e) => setTimeTableDescription(e.target.value)}
                  value={timeTableDescription}
                  description={`Please describe the details of your rental point's operation on this day, if any.`}
                />
              </>
            )}
          </div>
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
            type="small"
          />
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleApply}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditTimetableModeration;
