import React, { useEffect, useState, useRef } from "react";
import Counter from "../WidgetsProfile/Counter/Counter";
import { useDispatch } from "react-redux";

const WidgetQuntityBike = ({ mode, metricsBike, setQuantityBikes }) => {
  const dispatch = useDispatch();
  const [counterValues, setCounterValues] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const prevCounterValues = useRef(counterValues);

  useEffect(() => {
    if (metricsBike?.quantityBikes !== undefined) {
      setCounterValues(metricsBike.quantityBikes);
      setIsInitialized(true);
    }
  }, [metricsBike]);

  useEffect(() => {
    if (isInitialized && prevCounterValues.current !== counterValues) {
      setQuantityBikes(counterValues);
      prevCounterValues.current = counterValues;
    }
  }, [dispatch, mode, counterValues, isInitialized, setQuantityBikes]);

  return (
    <>
      <Counter
        minValue={0}
        maxValue={100}
        value={counterValues}
        onChange={(newCount) => setCounterValues(newCount)}
        label="Quantity bikes"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
    </>
  );
};

export default WidgetQuntityBike;

// import React, { useEffect, useState } from "react";
// import Counter from "../WidgetsProfile/Counter/Counter";
// import { useDispatch } from "react-redux";
// import { setMetricsBikeModeration } from "../../../../../reducersToolkit/moderationSliceV2";
// import { setMetricsBike } from "../../../../../reducersToolkit/bikesSliceV2";

// const WidgetQuntityBike = ({ mode, metricsBike }) => {
//   const dispatch = useDispatch();
//   const [counterValues, setCounterValues] = useState(0);
//   const [isInitialized, setIsInitialized] = useState(false);

//   // Устанавливаем начальное значение counterValues при изменении metricsBike
//   useEffect(() => {
//     if (metricsBike?.quantityBikes !== undefined && !isInitialized) {
//       setCounterValues(metricsBike.quantityBikes);
//       setIsInitialized(true);
//     }
//   }, [metricsBike, isInitialized]);

//   useEffect(() => {
//     if (isInitialized) {
//       if (mode === "new" || mode === "edit") {
//         dispatch(setMetricsBike({ mode, counterValues }));
//       } else if (mode === "moderation") {
//         dispatch(setMetricsBikeModeration({ mode, counterValues }));
//       }
//     }
//   }, [dispatch, mode, counterValues, isInitialized]);

//   return (
//     <>
//       <Counter
//         minValue={0}
//         maxValue={100}
//         value={counterValues}
//         onChange={(newCount) => setCounterValues(newCount)}
//         label="Quantity bikes"
//         description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
//       />
//     </>
//   );
// };

// export default WidgetQuntityBike;
