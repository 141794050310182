import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./services/route/protectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import WidgetPublicMenu from "./components/Public/Menu/WidgetPublicMenu";
import { LoadScript } from "@react-google-maps/api";
import { setLoadingStateGoogleMapApi } from "./slice/configSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Добавьте onAuthStateChanged
import { fetchRootDataThunkV4 } from "./reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { subscribePreviewDocumentsThunkV4 } from "./reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4";

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapsLibraries = ["places"];

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();

  const { loading, error, tempRootData, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const { mainMenu } = useSelector((state) => state.navigateSliceV2);

  /** Stats */
  const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
  // const [signInLoading, setSignInLoading] = useState(false);
  // const [signUpLoading, setSignUpLoading] = useState(false);
  // const [logOutLoading, setLogOutLoading] = useState(false);
  const [userRole, setUserRole] = useState("user");
  const [totalNotifications, setTotalNotifications] = useState(0);

  useEffect(() => {
    setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
  }, [tempRootData.user_auth_rootCurrentData]);

  // useEffect(() => {
  //   setSignInLoading(loading?.loading_signIn || false);
  //   setSignUpLoading(loading?.loading_signUp || false);
  //   setLogOutLoading(loading?.loading_logOut || false);
  // }, [loading.loading_signIn, loading.loading_signUp, loading.loading_logOut]);

  /** Fetch current user RootData */
  useEffect(() => {
    // Слушатель изменения состояния аутентификации
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        console.log("Слушатель изменения состояния аутентификации: ", user);
        // Пользователь аутентифицирован, обновляем данные пользователя
        dispatch(
          fetchRootDataThunkV4({
            idRootDocument: user.uid,
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: "users",
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState: "user_auth_rootCurrentData",
            // Название state который будет помогать отслежывать результаты работы thunk
            loadingStateName: "loading_fetchRootUser_App",
            errorStateName: "addErrorRootuser",
          })
        )
          .unwrap()
          .then(({ rootDocumentData }) => {
            console.log("Текущий пользователь:", rootDocumentData);
          })
          .catch((error) => {
            console.error("Ошибка:", error);
            setUser_rootCurrentData(null);
          });
      }
    });

    // Очистка слушателя при размонтировании компонента
    return () => unsubscribe();
  }, [auth, dispatch]);

  /** Counter data on moderation */
  useEffect(() => {
    const filteredRentalPoints =
      tempPreviewData?.general_rentalPointsPreviewNotifications?.filter(
        (itemRentalPoint) => itemRentalPoint.rentalPointStatus === "moderation"
      );

    const filteredBikes =
      tempPreviewData?.general_bikesPreviewNotifications?.filter(
        (bike) => bike.bikeStatus === "moderation"
      );

    const filteredUsers =
      tempPreviewData?.general_usersPreviewNotifications?.filter(
        (user) => user.status === "moderation"
      );

    const totalRentalPointsNotifications = filteredRentalPoints?.length || 0;

    const totalBikesNotifications = filteredBikes?.length || 0;

    const totalUsersNotifications = filteredUsers?.length || 0;

    const totals =
      totalRentalPointsNotifications +
      totalBikesNotifications +
      totalUsersNotifications;

    setTotalNotifications(totals);
  }, [
    tempPreviewData?.general_rentalPointsPreviewNotifications,
    tempPreviewData?.general_bikesPreviewNotifications,
    tempPreviewData?.general_usersPreviewNotifications,
  ]);

  /** USERS */
  useEffect(() => {
    if (userRole === "admin") {
      let unsubscribe; // Объявляем переменную для хранения функции отписки

      // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
      const subscribe = async () => {
        try {
          const result = await dispatch(
            subscribePreviewDocumentsThunkV4({
              collectionPath: `usersPreview`,
              previewCurrentDocumenState: "general_usersPreviewNotifications",
              excludedIds: ["metadata"],
              loadingStateName: "loading_general_usersPreview",
              errorStateName: "currentError",
            })
          ).unwrap();

          // Проверяем, что функция отписки определена
          unsubscribe = result?.unsubscribe;
          if (typeof unsubscribe === "function") {
            console.log("Функция отписки установлена успешно");
          } else {
            console.error("Не удалось получить функцию отписки");
          }
        } catch (error) {
          console.error("Ошибка при подписке:", error);
        }
      };

      subscribe(); // Вызываем функцию подписки

      // Отписываемся при размонтировании компонента
      return () => {
        if (typeof unsubscribe === "function") {
          unsubscribe();
          console.log("Отписка выполнена успешно");
        }
      };
    }
  }, [dispatch, userRole]);

  /** RENTAL POINTS */
  useEffect(() => {
    if (userRole === "admin") {
      let unsubscribe; // Объявляем переменную для хранения функции отписки

      // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
      const subscribe = async () => {
        try {
          const result = await dispatch(
            subscribePreviewDocumentsThunkV4({
              collectionPath: `general_rentalPointsPreview`,
              previewCurrentDocumenState:
                "general_rentalPointsPreviewNotifications",
              excludedIds: ["metadata"],
              loadingStateName: "loading_general_rentalPointsPreview",
              errorStateName: "currentError",
            })
          ).unwrap();

          // Проверяем, что функция отписки определена
          unsubscribe = result?.unsubscribe;
          if (typeof unsubscribe === "function") {
            console.log("Функция отписки установлена успешно");
          } else {
            console.error("Не удалось получить функцию отписки");
          }
        } catch (error) {
          console.error("Ошибка при подписке:", error);
        }
      };

      subscribe(); // Вызываем функцию подписки

      // Отписываемся при размонтировании компонента
      return () => {
        if (typeof unsubscribe === "function") {
          unsubscribe();
          console.log("Отписка выполнена успешно");
        }
      };
    }
  }, [dispatch, userRole]);

  /** BIKES */
  useEffect(() => {
    if (userRole === "admin") {
      let unsubscribe; // Объявляем переменную для хранения функции отписки

      // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
      const subscribe = async () => {
        try {
          const result = await dispatch(
            subscribePreviewDocumentsThunkV4({
              collectionPath: `general_bikesPreview`,
              previewCurrentDocumenState: "general_bikesPreviewNotifications",
              excludedIds: ["metadata"],
              loadingStateName: "loading_general_bikesPreview",
              errorStateName: "currentError",
            })
          ).unwrap();

          // Проверяем, что функция отписки определена
          unsubscribe = result?.unsubscribe;
          if (typeof unsubscribe === "function") {
            console.log("Функция отписки установлена успешно");
          } else {
            console.error("Не удалось получить функцию отписки");
          }
        } catch (error) {
          console.error("Ошибка при подписке:", error);
        }
      };

      subscribe(); // Вызываем функцию подписки

      // Отписываемся при размонтировании компонента
      return () => {
        if (typeof unsubscribe === "function") {
          unsubscribe();
          console.log("Отписка выполнена успешно");
        }
      };
    }
  }, [dispatch, userRole]);

  return (
    <LoadScript
      googleMapsApiKey={googleAPIKey}
      libraries={googleMapsLibraries}
      onLoad={() => dispatch(setLoadingStateGoogleMapApi(true))}
    >
      <Router>
        <WidgetPublicMenu
          mainMenu={mainMenu}
          userRole={userRole}
          currentUser={user_auth_rootCurrentData}
          totalNotifications={totalNotifications}
          // isLoading={signInLoading || signUpLoading || logOutLoading}
          // error={error.error_logOut || error.error_signIn || error.error_signUp}
        />

        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoutes currentUser={user_auth_rootCurrentData} />
            }
          />
        </Routes>
      </Router>
    </LoadScript>
  );
}

export default App;
