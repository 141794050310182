import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WidgetNavigateMenu from "./WidgetNavigateMenu";
import WidgetNavigateUser from "./WidgetNavigateUser";
import ContentBoxPublic from "../PublickWidgets/ContentBox/ContentBoxPublic";

const WindowNavigete = ({ onClose }) => {
  const { tempRootData } = useSelector((state) => state.catalogSliceV4);

  const { mainMenu } = useSelector((state) => state.navigateSliceV2);

  const [role, setRole] = useState(null);

  useEffect(() => {
    setRole(tempRootData?.user_auth_rootCurrentData?.role || null);
  }, [tempRootData.user_auth_rootCurrentData]);

  return (
    <>
      <ContentBoxPublic>
        <WidgetNavigateUser role={role} onClose={onClose} />
      </ContentBoxPublic>

      <ContentBoxPublic>
        <WidgetNavigateMenu mainMenu={mainMenu} onClose={onClose} />
      </ContentBoxPublic>
    </>
  );
};

export default WindowNavigete;
