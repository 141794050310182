import React, { useEffect, useState } from "react";
import WidgetOrderBikeDetail from "./WidgetOrderBikeDetail";
import Table from "../WidgetsProfile/Table/Table";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";

const WidgetRateDetail = ({ bookingDates, orderBike, totals }) => {
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(0);
  const [bookingDaysCount, setBookingDaysCount] = useState(0);
  const [basicInformation, setBasicInformation] = useState({});
  const [quantityBikesOrder, setQuantityBikesOrder] = useState(0);
  const [total, seTtotal] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostAdditionalServices, setTotalCostAdditionalServices] =
    useState(0);

  const [directory, setDirectory] = useState({});
  const [files, setFiles] = useState({});
  const [metricsBike, setMetricsBike] = useState({});
  const [rate, setRate] = useState({});

  useEffect(() => {
    setQuantityBikesOrder(totals?.quantityBikesOrder || 0);
    seTtotal(totals?.total || 0);
    setTotalCost(totals?.totalCost || 0);
    setTotalCostAdditionalServices(totals?.totalCostAdditionalServices || 0);
  }, [totals]);

  useEffect(() => {
    setStartDay(bookingDates?.startDay || 0);
    setEndDay(bookingDates?.endDay || 0);

    const startDate = new Date(bookingDates?.startDay);
    const endDate = new Date(bookingDates?.endDay);

    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    setBookingDaysCount(diffDays);
  }, [bookingDates]);

  return (
    <>
      <Table
        columns={1}
        title="Order details"
        data={[
          {
            // typeCell: "column",
            title: "quantityBikesOrder",
            parameter: <p style={{ fontWeight: 600 }}>{quantityBikesOrder}</p>,
          },
          {
            // typeCell: "column",
            title: "total",
            parameter: (
              <p style={{ fontWeight: 600 }}>{formatCurrencyUSD(total)}</p>
            ),
          },
          {
            // typeCell: "column",
            title: "totalCost",
            parameter: (
              <p style={{ fontWeight: 600 }}>{formatCurrencyUSD(totalCost)}</p>
            ),
          },
          {
            // typeCell: "column",
            title: "totalCostAdditionalServices",
            parameter: (
              <p style={{ fontWeight: 600 }}>
                {formatCurrencyUSD(totalCostAdditionalServices)}
              </p>
            ),
          },
          {
            // typeCell: "column",
            title: "Booking Start Date",
            parameter: (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "4px" }}
              >
                <p style={{ fontWeight: "600" }}>
                  {new Date(startDay).toLocaleString("en-US", {
                    month: "long",
                  })}
                </p>
                <p style={{ fontWeight: "600" }}>
                  {new Date(startDay).getDate()}
                </p>
              </div>
            ),
          },
          {
            // typeCell: "column",
            title: "Booking End Date",
            parameter: (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "4px" }}
              >
                <p style={{ fontWeight: "600" }}>
                  {new Date(endDay).toLocaleString("en-US", {
                    month: "long",
                  })}
                </p>
                <p style={{ fontWeight: "600" }}>
                  {new Date(endDay).getDate()}
                </p>
              </div>
            ),
          },
          {
            // typeCell: "column",
            title: "Number of Booking Days",
            parameter: <p style={{ fontWeight: "600" }}>{bookingDaysCount}</p>,
          },
        ]}
      />
    </>
  );
};

export default WidgetRateDetail;
