import React, { useEffect, useState } from "react";
import "../UserProfile/userProfile.css";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowUserProfileModeration from "./WindowUserProfileModeration";
import PopupAllert from "../WidgetsProfile/Popups/PopupAllert";

const WidgetModerationUserPreview = ({ userPreview }) => {
  const [urlAvatarFile, setUrlAvatarFile] = useState("");
  const [windowUserProfile, setWindowUserProfile] = useState(false);
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setUrlAvatarFile(userPreview?.fileUrl || "");
    setOwnerName(userPreview?.ownerName || "No name");
    setEmail(userPreview?.email || "No email");
    setPhoneNumber(userPreview?.phoneNumber || "No phone number");
  }, [userPreview]);

  const handleToggle = () => {
    setWindowUserProfile(true);
  };

  const handleClose = () => {
    setWindowUserProfile(false);
  };

  return (
    <>
      <PopupDirrectory
        isOpen={windowUserProfile}
        onClose={handleClose}
        title="User profile moderation"
      >
        <WindowUserProfileModeration onClose={handleClose} />
      </PopupDirrectory>
      <div
        className="user-preview"
        onClick={handleToggle}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          <AvatarUser size={80} url={urlAvatarFile} />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <h6>{ownerName}</h6>
            <div>
              <p className="p-light">{email}</p>
              <p className="p-light">{phoneNumber}</p>
            </div>
          </div>
        </div>
        <div>
          <span className="material-symbols-outlined">edit</span>
        </div>
      </div>
    </>
  );
};

export default WidgetModerationUserPreview;
