import React, { useEffect, useState, useCallback } from "react";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import WindowCountryDetailV4 from "./WindowCountryDetailV4";
import { useDispatch } from "react-redux";
import PriorityImage from "../../WidgetsProfile/PhotosGallery/PriorityImage";
import { setCurrentPreviewData } from "../../../../../../reducersToolkit/catalogSliceV4";
import { v4 as uuidv4 } from "uuid";

const ContryItem = React.memo(
  ({ countryItem, handleToggleOpenWindowCountryDetail }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [fileUrl, setFileUrl] = useState([]);
    const [idRootDocument, setIdRootDocument] = useState(null);
    const [idPreviewDocument, setIdPreviewDocument] = useState(null);

    useEffect(() => {
      setTitle(countryItem?.title || "No title");
      setDescription(countryItem?.description || "No description");
      setFileUrl(countryItem?.fileUrl || "");
      setIdRootDocument(countryItem.idRootDocument || null);
      setIdPreviewDocument(countryItem.idPreviewDocument || null);
    }, [countryItem]);

    return (
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          padding: "16px 8px 16px 16px ",
          boxSizing: "border-box",
          borderRadius: "16px",
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "16px",
        }}
        onClick={() =>
          handleToggleOpenWindowCountryDetail({
            currentCountry: countryItem,
          })
        }
      >
        <div>
          <PriorityImage
            photos={fileUrl}
            style={{
              aspectRatio: "1/1",
              borderRadius: "100%",
              width: "48px",
            }}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </p>
          <p
            className="p-light"
            style={{
              color: "var(--color-black)",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </p>
        </div>

        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    );
  }
);

const WidgetCountriesListV4 = ({ countriesList }) => {
  const dispatch = useDispatch();

  const [openWindowCountryDetail, setOpenWindowCountryDetail] = useState(false);

  const handleToggleOpenWindowCountryDetail = useCallback(
    ({ currentCountry }) => {
      dispatch(
        setCurrentPreviewData({
          currentPreviewDocumenState: "selectedPreviewCountry",
          data: currentCountry,
        })
      );

      setOpenWindowCountryDetail(true);
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    setOpenWindowCountryDetail(false);
  }, []);

  return (
    countriesList.length > 0 && (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {countriesList.map((countryItem) => (
            <ContryItem
              key={countryItem.id || uuidv4()}
              countryItem={countryItem}
              handleToggleOpenWindowCountryDetail={
                handleToggleOpenWindowCountryDetail
              }
            />
          ))}
        </div>
        <PopupDirrectory
          isOpen={openWindowCountryDetail}
          onClose={handleClose}
          title={"Edit country V4"}
        >
          <WindowCountryDetailV4 mode="edit" onClose={handleClose} />
        </PopupDirrectory>
      </>
    )
  );
};

export default WidgetCountriesListV4;
