import React, { useEffect, useState } from "react";
import PhotosGallery from "../WidgetsProfile/PhotosGallery/PhotosGallery";
import PriorityImage from "../WidgetsProfile/PhotosGallery/PriorityImage";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";
import Table from "../WidgetsProfile/Table/Table";
import Counter from "../WidgetsProfile/Counter/Counter";
import Rate from "../WidgetsProfile/Rate/RateV4";

const WidgetOrderBikeInfo = ({
  countBikes = 0,
  orderedBike = {},
  selectedBrand = {},
  selectedCategory = {},
  orderedAditionalServices = {},
  setTotals,
  metricsBike = {},
  quantityBike = 1,
  onChange,

  city = {},
  country = {},
  order = {},
}) => {
  // Bikes Data
  const [rate, setRate] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [brandTitle, setBrandTitle] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [brandIconFiles, setBrandIconFiles] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryIconFiles, setCategoryIconFiles] = useState([]);
  const [bikePhotoFiles, setBikePhotoFiles] = useState([]);
  const [quantityBikesInRentalPoint, setQuantityBikesInRentalPoint] =
    useState(0);

  const [startBookingDates, setStartBookingDates] = useState("");
  const [endBookingDates, setEndBookingDates] = useState("");
  const [quantityBikesOrder, setQuantityBikesOrder] = useState(0);
  const [bookingDaysCount, setBookingDaysCount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostAdditionalServices, setTotalCostAdditionalServices] =
    useState([]);
  const [cityTitle, setCityTitle] = useState("");
  const [countryTitle, setCountryTitle] = useState("");

  /**
   *
   * Наполнение useState
   *
   */

  useEffect(() => {
    setRate(orderedBike?.rate || []);
  }, [orderedBike?.rate]);

  // useEffect(() => {
  //   setCityTitle(
  //     order?.orderRentalPoint?.location?.city?.title?.titleOriginal || "No city"
  //   );
  //   setCountryTitle(
  //     order?.orderRentalPoint?.location?.country?.title?.titleOriginal ||
  //       "No country"
  //   );
  //   setRate(order?.orderBike?.rate || []);
  //   setStartBookingDates(order?.bookingDates?.startDay || "");
  //   setEndBookingDates(order?.bookingDates?.endDay || "");
  //   setQuantityBikesOrder(order?.quantityBikes || 0);
  // }, [order]);

  // const calculateBikeRentalCost = (
  //   rate,
  //   startBookingDate,
  //   endBookingDate,
  //   quantityBikesOrder
  // ) => {
  //   const start = new Date(startBookingDate);
  //   const end = new Date(endBookingDate);
  //   let totalCost = 0;

  //   if (start.getTime() === end.getTime()) {
  //     const monthRate = rate.find(
  //       (rateItem) => rateItem.month === start.getMonth()
  //     );
  //     if (monthRate) {
  //       totalCost = monthRate.price * quantityBikesOrder;
  //     }
  //   } else {
  //     for (
  //       let date = new Date(start.getFullYear(), start.getMonth(), 1);
  //       date <= end;
  //       date.setMonth(date.getMonth() + 1)
  //     ) {
  //       const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
  //       const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  //       const startDay =
  //         date.getMonth() === start.getMonth() &&
  //         date.getFullYear() === start.getFullYear()
  //           ? start.getDate()
  //           : monthStart.getDate();
  //       const endDay =
  //         date.getMonth() === end.getMonth() &&
  //         date.getFullYear() === end.getFullYear()
  //           ? end.getDate()
  //           : monthEnd.getDate();

  //       const daysInMonth = endDay - startDay + 1;

  //       const monthRate = rate.find(
  //         (rateItem) => rateItem.month === date.getMonth()
  //       );
  //       if (monthRate) {
  //         totalCost += daysInMonth * monthRate.price * quantityBikesOrder;
  //       }
  //     }
  //   }

  //   return totalCost;
  // };

  // const calculateAdditionalServicesCost = (orderedAditionalServices) => {
  //   const newTotalAdditionalServices = orderedAditionalServices.reduce(
  //     (acc, service) => {
  //       if (service.ordered && service.price) {
  //         return acc + service.price * service.ordered;
  //       }
  //       return acc;
  //     },
  //     0
  //   );
  //   return newTotalAdditionalServices;
  // };
  // useEffect(() => {
  //   setCityTitle(city?.title?.titleOriginal || "No city");
  // }, [city]);

  // useEffect(() => {
  //   setCountryTitle(country?.title?.titleOriginal || "No country");
  // }, [country]);

  // useEffect(() => {
  //   setStartBookingDates(order?.bookingDates?.startDay || "");
  //   setEndBookingDates(order?.bookingDates?.endDay || "");
  //   setQuantityBikesOrder(order?.quantityBikes || 0);
  // }, [order]);

  // useEffect(() => {
  //   setBrandTitle(selectedBrand?.title?.titleOriginal || "");
  //   setCategoryDescription(
  //     selectedBrand?.description?.descriptionOriginal || ""
  //   );
  //   setCategoryIconFiles(selectedBrand?.files?.iconFiles || []);
  // }, [selectedBrand]);

  // useEffect(() => {
  //   setCategoryTitle(selectedCategory?.title?.titleOriginal || "");
  //   setBrandDescription(
  //     selectedCategory?.description?.descriptionOriginal || ""
  //   );
  //   setBrandIconFiles(selectedCategory?.files?.iconFiles || []);
  // }, [selectedCategory]);

  // useEffect(() => {
  //   setTitle(selectedBike?.basicInformation?.title?.titleOriginal || "");
  //   setDescription(
  //     selectedBike?.basicInformation?.description?.descriptionOriginal || ""
  //   );
  //   setBikePhotoFiles(selectedBike?.files?.bikePhotoFiles || []);
  //   setQuantityBikesInRentalPoint(
  //     selectedBike?.metricsBike?.quantityBikes || 0
  //   );
  //   // setRate(selectedBike?.rate || []);
  // }, [selectedBike]);
  /**----------- */
  // useEffect(() => {
  //   if (startBookingDates && endBookingDates) {
  //     const startDate = new Date(startBookingDates);
  //     const endDate = new Date(endBookingDates);

  //     const diffTime = Math.abs(endDate - startDate);
  //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  //     setBookingDaysCount(diffDays);
  //     setTotalCostAdditionalServices(
  //       calculateAdditionalServicesCost(orderedAditionalServices)
  //     );
  //     setTotalCost(
  //       calculateBikeRentalCost(
  //         rate,
  //         startBookingDates,
  //         endBookingDates,
  //         quantityBikesOrder
  //       )
  //     );
  //   }
  // }, [
  //   startBookingDates,
  //   endBookingDates,
  //   rate,
  //   quantityBikesOrder,
  //   orderedAditionalServices,
  // ]);

  // useEffect(() => {
  //   setTotals({
  //     totalCost: totalCost || 0,
  //     totalCostAdditionalServices: totalCostAdditionalServices || 0,
  //     total: totalCost + totalCostAdditionalServices || 0,
  //     quantityBikesOrder: quantityBikesOrder || 0,
  //   });
  // }, [setTotals, totalCost, totalCostAdditionalServices, quantityBikesOrder]);

  const characteristicsBikeData =
    orderedBike?.characteristics?.map((char) => ({
      title: char?.title?.titleOriginal,
      description: char?.description?.descriptionOriginal,
      parameter: char?.characteristic,
    })) || [];

  const characteristicsData = [
    ...characteristicsBikeData,
    {
      typeCell: "column",
      title: `Description bike ${title}`,
      parameter: <CollapsableText text={description} />,
    },
  ];

  return (
    <>
      <h5 style={{ width: "100%" }}>{title}</h5>

      <Table
        columns={1}
        title="Order details"
        data={[
          {
            title: "Country",
            parameter: <p style={{ fontWeight: "600" }}>{countryTitle}</p>,
          },
          {
            title: "City",
            parameter: <p style={{ fontWeight: "600" }}>{cityTitle}</p>,
          },
          {
            typeCell: "column",
            title: "Bike Prices in Selected Months",
            parameter: (
              <Rate
                rate={rate}
                startBookingDates={startBookingDates}
                endBookingDates={endBookingDates}
              />
            ),
          },
          {
            title: "Booking Start Date",
            parameter: (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "4px" }}
              >
                <p style={{ fontWeight: "600" }}>
                  {new Date(startBookingDates).toLocaleString("en-US", {
                    month: "long",
                  })}
                </p>
                <p style={{ fontWeight: "600" }}>
                  {new Date(startBookingDates).getDate()}
                </p>
              </div>
            ),
          },
          {
            title: "Booking End Date",
            parameter: (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "4px" }}
              >
                <p style={{ fontWeight: "600" }}>
                  {new Date(endBookingDates).toLocaleString("en-US", {
                    month: "long",
                  })}
                </p>
                <p style={{ fontWeight: "600" }}>
                  {new Date(endBookingDates).getDate()}
                </p>
              </div>
            ),
          },
          {
            title: "Number of Booking Days",
            parameter: <p style={{ fontWeight: "600" }}>{bookingDaysCount}</p>,
          },
          {
            title: "Quantity of Bikes in Order",
            parameter: (
              <p style={{ fontWeight: "600" }}>{quantityBikesOrder}</p>
            ),
          },
          {
            title: "Total cost additional services",
            parameter: (
              <p style={{ fontWeight: "600" }}>
                {formatCurrencyUSD(totalCostAdditionalServices)}
              </p>
            ),
          },
          {
            title: "Total cost bike",
            parameter: (
              <p style={{ fontWeight: "600" }}>
                {formatCurrencyUSD(totalCost)}
              </p>
            ),
          },
          {
            title: "Total",
            parameter: (
              <p style={{ fontWeight: "600" }}>
                {formatCurrencyUSD(totalCost + totalCostAdditionalServices)}
              </p>
            ),
          },
        ]}
      />
      {characteristicsData.length > 0 && (
        <Table columns={1} title="Characteristics" data={characteristicsData} />
      )}
    </>
  );
};

export default WidgetOrderBikeInfo;
