import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetMainFilter from "../UniversalWidgets/V2/Filters.js/WidgetMainFilter";
import WidgetBikesList from "./WidgetBikesList";
import { filterBikesThunkV2 } from "../../../reducersToolkit/thunk/catalog/filterBikesThunkV2";
import Preloader from "../../UIElements/Preloader";
import WidgetStories from "../UniversalWidgets/V2/Stories/WidgetStories";

const PageCatalogV2 = () => {
  const dispatch = useDispatch();

  const [countyFilter, setCountyFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [qualityBike, setQualityBike] = useState(1);
  const [bookingDates, setBookingDates] = useState([]);
  const [bikesData, setBikesData] = useState([]);
  const [brandFilter, setBrandFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [countyIdFilter, setCountyIdFilter] = useState(null);
  const [cityIdFilter, setCityIdFilter] = useState(null);
  const [brandIdFilter, setBrandIdFilter] = useState(null);
  const [categoryIdFilter, setCategoryIdFilter] = useState(null);
  const [language, setLanguage] = useState("en");

  const { currentUser } = useSelector((state) => state.catalogSliceV4);
  const { bikesList } = useSelector((state) => state.catalogSliceV4);
  const {
    filteredBikes,
    bikes,
    rentalPoints,
    cities,
    countries,
    brands,
    categories,
    isLoading,
    filters,
  } = useSelector((state) => state.catalogSliceV4);

  useEffect(() => {
    setBikesData(filteredBikes);
  }, [filteredBikes]);

  useEffect(() => {
    setLanguage(currentUser?.settings?.language || "en");
  }, [currentUser]);

  useEffect(() => {
    setBookingDates([
      filters?.bookingDates?.startDay,
      filters?.bookingDates?.endDay,
    ]);
    setQualityBike(filters?.qualityBike || 1);
    setCityFilter(filters?.city || null);
    setCountyFilter(filters?.country || null);
    setBrandFilter(filters?.brand || null);
    setCategoryFilter(filters?.category || null);

    setCityIdFilter(filters?.city?.id || null);
    setCountyIdFilter(filters?.country?.id || null);

    setBrandIdFilter(filters?.brand?.id || null);
    setCategoryIdFilter(filters?.category?.id || null);
  }, [filters]);

  useEffect(() => {
    if (bikes?.length > 0) {
      dispatch(
        filterBikesThunkV2({
          bikes: bikes,
          countryIdFilter: null,
          cityIdFilter: null,
          qualityBikeFilter: 1,
          dateIntervalFilter: bookingDates,
          brandIdFilter: null,
          categoryIdFilter: null,
        })
      );
    }
  }, [dispatch, bikes, bookingDates]);

  return isLoading ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Preloader />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-gray-100)",
        padding: "16px",
      }}
    >
      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        {/* <WidgetStories title="Stories" /> */}
        <WidgetMainFilter
          countyFilter={countyFilter}
          cityFilter={cityFilter}
          qualityBike={qualityBike}
          bookingDates={bookingDates}
        />

        {/* {language} */}

        {/* <WidgetFilterListing
          brandFilter={brandFilter}
          categoryFilter={categoryFilter}
        /> */}

        <WidgetBikesList
          language={language}
          bookingDates={bookingDates}
          bikesData={bikesData}
          brandFilter={brandFilter}
          categoryFilter={categoryFilter}
          categories={categories}
        />

        {/* <WigetProductList /> */}
      </div>
    </div>
  );
};

export default PageCatalogV2;
