import { useEffect, useState } from "react";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";

const WidgetItemUserPreview = ({ onClick, user }) => {
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [avatarFile, setAvatarFile] = useState("");
  // const [quantityBikesModeration, setQuantityBikesModeration] = useState(0);
  // const [quantityRentalPointsModeration, setQuantityRentalPointsModeration] =
  //   useState(0);

  useEffect(() => {
    setAvatarFile(user?.fileUrl);
    setOwnerName(user?.ownerName);
    setEmail(user?.email);
    setPhoneNumber(user?.phoneNumber);
    // setQuantityBikesModeration(user?.totals?.bikesModeration || 0);
    // setQuantityRentalPointsModeration(
    //   user?.totals?.rentalPointsModeration || 0
    // );
  }, [user]);

  return (
    <div
      onClick={onClick}
      style={{
        width: "100%",
        boxSizing: "border-box",
        minHeightheight: "56px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "var(--color-gray-100)",
        padding: "16px 8px 16px 16px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <AvatarUser size={80} url={avatarFile} />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <h6>{ownerName || "No name"}</h6>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              // gap: "2px",
            }}
          >
            <p className="p-light">{email || "No email"}</p>
            <p className="p-light">{phoneNumber || "No phone number"}</p>
            {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                  position: "relative",
                }}
              >
                <p className="p-light">New bikes:</p>
                <p className="p-light">{quantityBikesModeration}</p>
                {quantityBikesModeration > 0 && (
                  <QuantityParams
                    param={quantityBikesModeration}
                    style={{
                      position: "absolute",
                      right: "-8px",
                    }}
                  />
                )}
              </div> */}
            {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                  position: "relative",
                }}
              >
                <p className="p-light">New rental points:</p>
                <p className="p-light">{quantityRentalPointsModeration}</p>
                {quantityRentalPointsModeration > 0 && (
                  <QuantityParams
                    param={quantityRentalPointsModeration}
                    style={{
                      position: "absolute",
                      right: "-8px",
                    }}
                  />
                )}
              </div> */}
          </div>

          {/* <p className="p-light">24.01.2024 11:00</p> */}
        </div>
      </div>

      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    </div>
  );
};

export default WidgetItemUserPreview;
