import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useParams, useLocation, Link } from "react-router-dom";
import DateFormat from "../UniversalWidgets/supportFunctions/functionDateFormat";
import NewsItem from "./NewsList/NewsItem";
import WigetTitle from "../UniversalWidgets/WigetTitle";

const UiNews = () => {
  const countryData = useSelector((state) => state.country);
  const cityData = useSelector((state) => state.city);
  const bikeData = useSelector((state) => state.bike);
  const brandData = useSelector((state) => state.brand);
  const categoryData = useSelector((state) => state.category);
  const newsData = useSelector((state) => state.news.data);
  const storiesData = useSelector((state) => state.stories.data);
  const rentalPointsData = useSelector((state) => state.rentalPoints);

  const { id } = useParams();

  return (
    <>
      {/* <PublicMenu/> */}

      <div className="content-container">
        {/* <div className="uIHome-box"> */}
        <div className="content-body-news">
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <WigetTitle
              title="News"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum vitae nunc gravida facilisis. Pellentesque finibus, justo id tincidunt accumsan, mauris elit consequat nulla, vel tempus mi lorem vel ipsum. Integer id tincidunt sapien. Cras faucibus euismod massa eget interdum. "
            ></WigetTitle>

            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "32px",
              }}
            >
              {newsData.isLoading ? (
                // Отображаем индикатор загрузки, если isLoading равно true
                <p>Loading...</p>
              ) : (
                // Если isLoading равно false, отображаем данные
                newsData.map((newsItem, index) => (
                  <NewsItem
                    key={index}
                    id={newsItem?.id}
                    date={newsItem?.date && newsItem?.date[0]}
                    title={newsItem?.title}
                    description={newsItem?.description}
                    file_photo={newsItem?.file_photo}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UiNews;
