import React, { useEffect, useState } from "react";
import PhotosGallery from "../WidgetsProfile/PhotosGallery/PhotosGallery";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import Table from "../WidgetsProfile/Table/Table";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  return date.toLocaleString("en-US", { month: "short" });
};

const Rate = ({ rate, startBookingDates, endBookingDates }) => {
  const startMonth = new Date(startBookingDates).getMonth();
  const endMonth = new Date(endBookingDates).getMonth();
  const maxPrice = Math.max(...rate.map((rateItem) => rateItem.price));
  const calculateHeight = (price) => (price / maxPrice) * 56;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
        }}
      >
        {rate?.map((rateItem, index) => (
          <div key={index} style={{ width: "100%" }}>
            <div
              title={`${getMonthName(index)} — ${formatCurrencyUSD(
                rateItem?.price
              )}`}
              style={{
                height: `${calculateHeight(rateItem.price)}px`,
                width: "100%",
                borderRadius: "4px",
                backgroundColor:
                  index >= startMonth && index <= endMonth
                    ? "var(--color-success)"
                    : "var(--color-gray-100)",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "flex-end",
                ...(index >= startMonth && index <= endMonth
                  ? {
                      minHeight: "23px",
                      padding: "2px 4px",
                    }
                  : {
                      minHeight: "8px",
                      padding: "0",
                    }),
              }}
            >
              {index >= startMonth && index <= endMonth && (
                <p className="p-light" style={{ color: "var(--color-white)" }}>
                  {formatCurrencyUSD(rateItem?.price)}
                </p>
              )}
            </div>
            <div
              style={{
                height: "24px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <p className="p-light" style={{ color: "var(--color-black)" }}>
                {index >= startMonth &&
                  index <= endMonth &&
                  getMonthName(index)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const WidgetOrderBikeDetail = ({
  orderBike,
  bookingDates,
  brands,
  categories,
}) => {
  const [bikePhotoFiles, setBikePhotoFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState([]);
  const [brandTitle, setBrandTitle] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(0);
  const [bookingDaysCount, setBookingDaysCount] = useState(0);
  const [brand, setBrand] = useState({});
  const [category, setCategory] = useState({});

  useEffect(() => {
    const foundBrand = brands.find(
      (brand) => brand.id === orderBike?.directory?.brandId
    );
    setBrand(foundBrand);
  }, [brands, orderBike]);

  useEffect(() => {
    const foundCategory = categories.find(
      (category) => category.id === orderBike?.directory?.categoryId
    );
    setCategory(foundCategory);
  }, [categories, orderBike]);

  useEffect(() => {
    setStartDay(bookingDates?.startDay || 0);
    setEndDay(bookingDates?.endDay || 0);

    const startDate = new Date(bookingDates?.startDay);
    const endDate = new Date(bookingDates?.endDay);

    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    setBookingDaysCount(diffDays);
  }, [bookingDates]);

  useEffect(() => {
    setBikePhotoFiles(orderBike?.files?.bikePhotoFiles || []);
    setTitle(orderBike?.basicInformation?.title?.titleOriginal || "No name");
    setDescription(
      orderBike?.basicInformation?.description?.descriptionOriginal ||
        "No Description"
    );
    setRate(orderBike?.rate || []);
  }, [orderBike]);

  useEffect(() => {
    setBrandTitle(brand?.title?.titleOriginal || "No brand");
  }, [brand]);

  useEffect(() => {
    setCategoryTitle(category?.title?.titleOriginal || "No category");
  }, [category]);

  return (
    <>
      <Table
        columns={1}
        title={title}
        data={[
          {
            typeCell: "column",
            // title: "quantityBikesOrder",
            parameter: <PhotosGallery photos={bikePhotoFiles} />,
          },
          {
            // typeCell: "column",
            title: "Category",
            parameter: (
              <div
                style={{
                  padding: "4px 8px",
                  backgroundColor: "var(--color-gray-100)",
                  borderRadius: "4px",
                }}
              >
                <p style={{ fontWeight: "600" }}>{categoryTitle}</p>
              </div>
            ),
          },
          {
            // typeCell: "column",
            title: "Brand",
            parameter: (
              <div
                style={{
                  padding: "4px 8px",
                  backgroundColor: "var(--color-gray-100)",
                  borderRadius: "4px",
                }}
              >
                <p style={{ fontWeight: "600" }}>{brandTitle}</p>
              </div>
            ),
          },

          {
            typeCell: "column",
            title: "Rate",
            parameter: (
              <Rate
                rate={rate}
                startBookingDates={startDay}
                endBookingDates={endDay}
              />
            ),
          },
          description && {
            typeCell: "column",
            title: "Description bike",
            parameter: <CollapsableText text={description} />,
          },
        ]}
      />
      {/* <Table
        columns={1}
        title="Bike detail"
        data={[
          {
            typeCell: "column",
            title: "Rate",
            parameter: (
              <Rate
                rate={rate}
                startBookingDates={startDay}
                endBookingDates={endDay}
              />
            ),
          },
          description && {
            typeCell: "column",
            title: "Description bike",
            parameter: <CollapsableText text={description} />,
          },
        ]}
      /> */}
    </>
  );
};

export default WidgetOrderBikeDetail;
