import React from "react";

const QuantityParams = ({ param, style }) => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          backgroundColor: "var(--color-alarm)",
          borderRadius: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "var(--color-white)",
          fontSize: "0.5rem",
          padding: "2px 5px",
        }}
      >
        {param}
      </p>
    </div>
  );
};

export default QuantityParams;
