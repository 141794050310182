import React, { useEffect, useState } from "react";
import WidgetRentalPointBasicInformation from "./WidgetRentalPointBasicInformation";
import WidgetRentalPointLocation from "./WidgetRentalPointLocation";
import WidgetRentalPointContact from "./WidgetRentalPointContact";
import WidgetRentalPointTimetable from "./WidgetRentalPointTimetable";
import WidgetAditionalServices from "./WidgetAditionalServices";
import "../profile.css";
import WidgetRentalPointFilesBar from "./WidgetRentalPointFilesBar";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import Button from "../WidgetsProfile/Buttons/Button";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import { addRentalPointsThunkV2 } from "../../../../../reducersToolkit/thunk/rentalPoints/addRentalPointsThunkV2";
import { updateRentalPointsThunkV2 } from "../../../../../reducersToolkit/thunk/rentalPoints/updateRentalPointsThunkV2";
import { fetchBikesThunkV2 } from "../../../../../reducersToolkit/thunk/bikes/fetchBikesThunkV2";
import { clearForm } from "../../../../../reducersToolkit/rentalPointsSliiceV2";
import { deactivateRentalPointThunkV2 } from "../../../../../reducersToolkit/thunk/rentalPoints/deactivateRentalPointThunkV2";
import { activatedRentalPointThunkV2 } from "../../../../../reducersToolkit/thunk/rentalPoints/activatedRentalPointThunkV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowRentalPointDetail = ({ mode, onClose, state }) => {
  const dispatch = useDispatch();

  const [basicInformation, setBasicInformation] = useState({});
  const [location, setLocation] = useState({});
  const [files, setFiles] = useState({});
  const [contacts, setContacts] = useState({});
  const [additionalServices, setAdditionalServices] = useState({});
  const [timetable, setTimetable] = useState([]);
  const [bikesIds, setBikesIds] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  const {
    isLoading: isLoadingRentalPoint,
    selectedRentalPoint,
    newRentalPoint,
  } = useSelector((state) => state.rentalPointsSliiceV2);

  const { currentUser } = useSelector((state) => state.catalogSliceV4);

  const { isLoading: isLoadingBikes, bikesList } = useSelector(
    (state) => state.bikesSliceV2
  );

  const { brands, categories } = useSelector((state) => state.directorySliceV2);

  useEffect(() => {
    dispatch(clearForm());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBikesThunkV2(bikesIds));
  }, [dispatch, bikesIds]);

  useEffect(() => {
    if (mode === "new") {
      setBasicInformation(newRentalPoint.basicInformation);
      setLocation(newRentalPoint.location);
      setContacts(newRentalPoint.contacts);
      setAdditionalServices(newRentalPoint.additionalServices);
      setTimetable(newRentalPoint.timetable);
      setBikesIds(newRentalPoint.bikesIds);
      if (!initialState) setInitialState(newRentalPoint);
    } else if (mode === "edit") {
      setBasicInformation(selectedRentalPoint.basicInformation);
      setLocation(selectedRentalPoint.location);
      setContacts(selectedRentalPoint.contacts);
      setAdditionalServices(selectedRentalPoint.additionalServices);
      setTimetable(selectedRentalPoint.timetable);
      setBikesIds(selectedRentalPoint.bikesIds);
      if (!initialState) setInitialState(selectedRentalPoint);
    } else if (mode === "moderation") {
      setBasicInformation(newRentalPoint.basicInformation);
      setLocation(newRentalPoint.location);
      setContacts(newRentalPoint.contacts);
      setAdditionalServices(newRentalPoint.additionalServices);
      setTimetable(newRentalPoint.timetable);
      setBikesIds(newRentalPoint.bikesIds);
      if (!initialState) setInitialState(newRentalPoint);
    }
  }, [newRentalPoint, selectedRentalPoint, mode, initialState]);

  useEffect(() => {
    const hasChanges = () => {
      if (!initialState) return false;
      return (
        JSON.stringify(initialState.basicInformation) !==
          JSON.stringify(basicInformation) ||
        JSON.stringify(initialState.location) !== JSON.stringify(location) ||
        JSON.stringify(initialState.contacts) !== JSON.stringify(contacts) ||
        JSON.stringify(initialState.additionalServices) !==
          JSON.stringify(additionalServices) ||
        JSON.stringify(initialState.timetable) !== JSON.stringify(timetable)
      );
    };

    setIsChanged(hasChanges());
  }, [
    basicInformation,
    location,
    contacts,
    additionalServices,
    timetable,
    initialState,
  ]);

  const handleCreateRentalPoint = () => {
    const collectionName = "rentalPoints";
    if (mode === "new" || mode === "moderation") {
      const newData = newRentalPoint;
      dispatch(
        addRentalPointsThunkV2({
          collectionName,
          newData,
          userId: currentUser.id,
        })
      );
      onClose();
    } else if (mode === "edit") {
      const newData = selectedRentalPoint;
      const id = selectedRentalPoint.id;
      dispatch(
        updateRentalPointsThunkV2({
          collectionName,
          id,
          newData,
        })
      );
      onClose();
    }
  };

  const handleDeactivate = (rentalPointId) => {
    dispatch(deactivateRentalPointThunkV2(rentalPointId));
    onClose();
  };

  const handleActivate = (rentalPointId) => {
    dispatch(activatedRentalPointThunkV2(rentalPointId));
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      <div className="block-section block-section__start-box">
        <div className="profile-block-section__content-box">
          <WidgetRentalPointBasicInformation
            mode={mode}
            basicInformation={basicInformation}
          />
        </div>

        {mode === "edit" && (
          <WidgetRentalPointFilesBar
            selectedRentalPoint={selectedRentalPoint}
          />
        )}
      </div>

      <ContentBox isLoading={isLoadingRentalPoint}>
        <WidgetRentalPointLocation mode={mode} location={location} />
      </ContentBox>

      {mode === "edit" && (
        <ContentBox>
          <WidgetBikesList
            brands={brands}
            categories={categories}
            bikesList={bikesList}
            isLoadingBikes={isLoadingBikes}
          />
        </ContentBox>
      )}
      {mode === "edit" && (
        <ContentBox isLoading={isLoadingRentalPoint}>
          <WidgetAditionalServices
            mode={mode}
            additionalServices={additionalServices}
          />
        </ContentBox>
      )}

      <ContentBox isLoading={isLoadingRentalPoint}>
        <WidgetRentalPointContact mode={mode} contacts={contacts} />
      </ContentBox>

      <ContentBox isLoading={isLoadingRentalPoint}>
        <WidgetRentalPointTimetable mode={mode} timetable={timetable} />
      </ContentBox>
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          {state === "deactivated" && (
            <Button
              type="small"
              label="Activate"
              active={true}
              onClick={() => handleActivate(selectedRentalPoint.id)}
              color="--color-success"
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>
                    Are you sure you want to deactivate
                    {basicInformation?.title?.titleOriginal
                      ? ` "${basicInformation?.title?.titleOriginal}"`
                      : ""}
                    ?
                  </h1>
                  <p className="p-light">
                    You will be able to reactivate the rental point at any time
                    convenient for you.
                  </p>
                </div>
              }
            />
          )}
          {state === "activated" && (
            <Button
              type="small"
              label="Deactivate"
              active={true}
              onClick={() => handleDeactivate(selectedRentalPoint.id)}
              color="--color-black"
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>
                    Are you sure you want to deactivate
                    {basicInformation?.title?.titleOriginal
                      ? ` "${basicInformation?.title?.titleOriginal}"`
                      : ""}
                    ?
                  </h1>
                  <p className="p-light">
                    You will be able to reactivate the rental point at any time
                    convenient for you.
                  </p>
                </div>
              }
            />
          )}
          <Button
            type="small"
            label="Cancel"
            onClick={onClose}
            color="--color-black"
            active={true}
          />
          <Button
            type="small"
            label={`${
              mode === "new"
                ? "Create"
                : mode === "edit"
                ? "Save changes"
                : "Moderation a rental point"
            }`}
            onClick={handleCreateRentalPoint}
            active={isChanged}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowRentalPointDetail;
