import React, { useEffect, useState, useCallback } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";

const WidgetContacts = ({ userContacts, setUserContacts }) => {
  console.log("WidgetContacts userContacts", userContacts);

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setEmail(userContacts?.email || "");
    setPhoneNumber(userContacts?.phoneNumber || "");
  }, [userContacts]);

  const handleEmailChange = useCallback(
    (event) => {
      const newEmail = event.target.value;
      setEmail(newEmail);
      setUserContacts((prev) => ({
        ...prev,
        email: newEmail,
      }));
    },
    [setUserContacts]
  );

  const handlePhoneNumberChange = useCallback(
    (event) => {
      const newPhoneNumber = event.target.value;
      setPhoneNumber(newPhoneNumber);
      setUserContacts((prev) => ({
        ...prev,
        phoneNumber: newPhoneNumber,
      }));
    },
    [setUserContacts]
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <TitleBlockSection
        title="Contacts"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien, ac rhoncus justo magna non turpis. Sed tristique odio eu magna convallis interdum. Pellentesque vitae tortor est."
      />
      <InputText
        type="text"
        label="Phone"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
      <InputText
        type="text"
        label="Email"
        value={email}
        onChange={handleEmailChange}
      />
    </div>
  );
};

export default WidgetContacts;
