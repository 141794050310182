import React, { useEffect, useState } from "react";
import DropdownList from "../../Private/Login/Profile/WidgetsProfile/DropdownList/DropdownList";
import Button from "../../Private/Login/Profile/WidgetsProfile/Buttons/Button";

const sortOptions = [
  { id: "cheapest", title: "Cheapest" },
  { id: "most_expensive", title: "Most Expensive" },
  { id: "discounted", title: "Discounted" },
  { id: "non_discounted", title: "Non-discounted" },
];

const WidgetSortList = ({
  bikesData,
  isLoading,
  bookingDates,
  setSortedBikesData,
}) => {
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    if (bikesData?.length > 0) {
      const startMonth = new Date(bookingDates[0]).getMonth() + 1;
      const endMonth = new Date(bookingDates[1]).getMonth() + 1;

      const bikesWithRatesInRange = bikesData.filter((bike) =>
        bike.rate.some(
          (rate) =>
            rate.month >= startMonth && rate.month <= endMonth && rate.price > 0
        )
      );

      let sortedData;
      switch (selectedSortOption.id) {
        case "cheapest":
          sortedData = bikesWithRatesInRange.sort((a, b) => {
            const aMinPrice = Math.min(
              ...a.rate
                .filter(
                  (rate) =>
                    rate.month >= startMonth &&
                    rate.month <= endMonth &&
                    rate.price > 0
                )
                .map((rate) => rate.price)
            );
            const bMinPrice = Math.min(
              ...b.rate
                .filter(
                  (rate) =>
                    rate.month >= startMonth &&
                    rate.month <= endMonth &&
                    rate.price > 0
                )
                .map((rate) => rate.price)
            );
            return aMinPrice - bMinPrice;
          });
          break;
        case "most_expensive":
          sortedData = bikesWithRatesInRange.sort((a, b) => {
            const aMaxPrice = Math.max(
              ...a.rate
                .filter(
                  (rate) =>
                    rate.month >= startMonth &&
                    rate.month <= endMonth &&
                    rate.price > 0
                )
                .map((rate) => rate.price)
            );
            const bMaxPrice = Math.max(
              ...b.rate
                .filter(
                  (rate) =>
                    rate.month >= startMonth &&
                    rate.month <= endMonth &&
                    rate.price > 0
                )
                .map((rate) => rate.price)
            );
            return bMaxPrice - aMaxPrice;
          });
          break;
        case "discounted":
          sortedData = bikesWithRatesInRange.sort((a, b) => {
            const aHasDiscount = a.rate.some(
              (rate) =>
                rate.month >= startMonth &&
                rate.month <= endMonth &&
                rate.discount > 0
            );
            const bHasDiscount = b.rate.some(
              (rate) =>
                rate.month >= startMonth &&
                rate.month <= endMonth &&
                rate.discount > 0
            );
            return bHasDiscount - aHasDiscount;
          });
          break;
        case "non_discounted":
          sortedData = bikesWithRatesInRange.sort((a, b) => {
            const aHasNoDiscount = a.rate.some(
              (rate) =>
                rate.month >= startMonth &&
                rate.month <= endMonth &&
                rate.discount === 0
            );
            const bHasNoDiscount = b.rate.some(
              (rate) =>
                rate.month >= startMonth &&
                rate.month <= endMonth &&
                rate.discount === 0
            );
            return aHasNoDiscount - bHasNoDiscount;
          });
          break;
        default:
          sortedData = bikesWithRatesInRange;
      }

      setSortedBikesData(sortedData);
    }
  }, [bikesData, bookingDates, selectedSortOption]);

  const buttonClear = () => {
    setSelectedSortOption(sortOptions[0]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {/* <h1>Hello, WidgetSortList!</h1> */}

      <DropdownList
        options={sortOptions}
        onSelect={setSelectedSortOption}
        label="Sort by"
        // description="Sort bikes by selected criteria."
        fieldView="title"
        fieldId="id"
        initialSelectedOption={selectedSortOption}
        type="small"
      />
      {/* <div>
        <Button
          label="Clear"
          active={true}
          color="--color-black"
          onClick={buttonClear}
        />
      </div> */}
    </div>
  );
};

export default WidgetSortList;
