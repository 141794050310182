import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import Button from "../WidgetsProfile/Buttons/Button";
import { useSelector, useDispatch } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";

const WindowBikeBasicInformationModeration = ({ onClose, mode }) => {
  const dispatch = useDispatch();

  const { selectedBike, newBike, tempRootData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [applyButtonActive, setApplyButtonActive] = useState(false);
  const [newData, setNewData] = useState(false);

  /**
   *
   * Формирование newData объекта
   *
   */

  useEffect(() => {
    setNewData({
      title: {
        titleOriginal: title,
      },

      description: {
        descriptionOriginal: description,
      },
    });
  }, [title, description]);

  /**
   *
   * Наполнение useState
   *
   * */

  useEffect(() => {
    if (mode === "moderation") {
      setTitle(
        tempRootData?.selectedBike_rootData?.basicInformation?.title
          ?.titleOriginal || ""
      );
      setDescription(
        tempRootData?.selectedBike_rootData?.basicInformation?.description
          ?.descriptionOriginal || ""
      );
    } else if (mode === "moderatorAdded") {
      setTitle(newBike?.basicInformation?.title?.titleOriginal || "");
      setDescription(
        newBike?.basicInformation?.description?.descriptionOriginal || ""
      );
    }
  }, [mode, tempRootData?.selectedBike_rootData?.basicInformation, newBike]);

  /**
   *
   * CRUD функции
   *
   */

  const applyChanges = () => {
    if (mode === "moderation") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "selectedBike_rootData.basicInformation",
          data: newData,
        })
      );
      onClose();
    } else if (mode === "moderatorAdded") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "newRootBike_create.basicInformation",
          data: newData,
        })
      );
      onClose();
    }
  };

  /**
   *
   * Логика компонента
   *
   */

  // Активаци якнопки apply
  useEffect(() => {
    const hasChanges = () => {
      return (
        selectedBike?.basicInformation?.title?.titleOriginal !== title ||
        selectedBike?.basicInformation?.description?.descriptionOriginal !==
          description
      );
    };

    setApplyButtonActive(hasChanges());
  }, [title, description, selectedBike]);

  // Кнопки
  const applyButton = () => {
    applyChanges();
  };

  const cancelButton = () => {
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        marginBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <InputText
          label="Bike name"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />

        <Textarea
          label="Bike description"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          maxLength={1000}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </ContentBox>

      <ButtonsBar position="end">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={cancelButton}
          />
          <Button
            type="small"
            label="Apply"
            active={applyButtonActive}
            onClick={applyButton}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowBikeBasicInformationModeration;
