import React, { useEffect, useState } from "react";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import AddData from "../../WidgetsProfile/AddData/AddData";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../../../../../UIElements/Preloader";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import WidgetCategoriesListV4 from "./WidgetCategoriesListV4";
import WindowCategoryDetailV4 from "./WindowCategoryDetailV4";
import { subscribePreviewDocumentsThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4";

const WindowCategoriesListV4 = () => {
  const dispatch = useDispatch();

  const { loading, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [isOpenWindowDirectorirsDetail, setIsOpenWindowDirectorirsDetail] =
    useState(false);

  const [loadingCategoriesPreview, setLoadingCategoriesPreview] =
    useState(false);
  const [loading_create_category, setLoading_create_category] = useState(false);
  const [loading_restoreCategory, setLoading_restoreCategory] = useState(false);
  const [loading_deleCategory, setLoading_deleCategory] = useState(false);
  const [loading_updateCategories, setLoading_updateCategories] =
    useState(false);

  const [categoriesPreview, setCategoriesPreview] = useState(null);

  /** Получаем данные по брендам из стейта */
  useEffect(() => {
    setCategoriesPreview(tempPreviewData?.subscribe_categoriesPreview || null);
  }, [tempPreviewData?.subscribe_categoriesPreview]);

  /** Получаем состояние загрузки из стейта */
  useEffect(() => {
    setLoadingCategoriesPreview(
      loading?.loading_subscribe_categoriesPreview || false
    );
    setLoading_create_category(loading?.loading_create_category || false);
    setLoading_restoreCategory(loading?.loading_restoreCategory || false);
    setLoading_deleCategory(loading?.loading_deleCategory || false);
    setLoading_updateCategories(loading?.loading_updateCategories || false);
  }, [
    loading?.loading_subscribe_categoriesPreview,
    loading?.loading_create_category,
    loading?.loading_restoreCategory,
    loading?.loading_deleCategory,
    loading?.loading_updateCategories,
  ]);

  const handleClose = () => {
    setIsOpenWindowDirectorirsDetail(false);
  };

  const handleOpenWindowCategoryDetailV4 = () => {
    setIsOpenWindowDirectorirsDetail(true);
  };

  /** !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch */
  useEffect(() => {
    let unsubscribe; // Объявляем переменную для хранения функции отписки

    const subscribe = async () => {
      try {
        const result = await dispatch(
          subscribePreviewDocumentsThunkV4({
            collectionPath: `categoriesPreview`,
            previewCurrentDocumenState: "subscribe_categoriesPreview",
            excludedIds: ["metadata"],
            loadingStateName: "loading_subscribe_categoriesPreview",
            errorStateName: "currentError",
          })
        ).unwrap();

        // Проверяем, что функция отписки определена
        unsubscribe = result?.unsubscribe;
        if (typeof unsubscribe === "function") {
          console.log("Функция отписки установлена успешно");
        } else {
          console.error("Не удалось получить функцию отписки");
        }
      } catch (error) {
        console.error("Ошибка при подписке:", error);
      }
    };

    subscribe(); // Вызываем функцию подписки

    // Отписываемся при размонтировании компонента
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
        console.log("Отписка выполнена успешно");
      }
    };
  }, [dispatch]);

  return (
    <>
      <ContentBox position="one">
        {loadingCategoriesPreview ||
        loading_create_category ||
        loading_restoreCategory ||
        loading_deleCategory ||
        loading_updateCategories ? (
          <Preloader />
        ) : (
          <>
            {/* POPUP WINDOW */}
            <PopupDirrectory
              isOpen={isOpenWindowDirectorirsDetail}
              onClose={handleClose}
              title="Add category"
            >
              <WindowCategoryDetailV4 mode="new" onClose={handleClose} />
            </PopupDirrectory>
            {/* POPUP WINDOW */}

            <AddData
              onClick={handleOpenWindowCategoryDetailV4}
              icon={<span className="material-symbols-outlined">add</span>}
              title="Add category"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />

            <WidgetCategoriesListV4 directories={categoriesPreview} />
          </>
        )}
      </ContentBox>
    </>
  );
};

export default WindowCategoriesListV4;
