import { useSelector } from "react-redux";

import { useParams, useLocation, Link } from "react-router-dom";
import DateFormat from "../../UniversalWidgets/supportFunctions/functionDateFormat";

const UiNewsDetail = () => {
  const { id } = useParams();
  const newsData = useSelector((state) => state.news.data);
  const foundNewsItem = newsData.find((item) => item.id === id);
  return (
    <>
      <div className="content-container">
        {/* <div className="uIHome-box"> */}
        <div className="content-body-news">
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <h2>news</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                aspecRatio: "16/9",
                with: "100%",
              }}
            >
              <DateFormat
                timestamp={foundNewsItem?.date && foundNewsItem.date[0]}
              />
              <h2>{foundNewsItem?.title}</h2>

              <p>{foundNewsItem?.description}</p>

              <p>
                {foundNewsItem?.file_photo.map((item) => (
                  <p>{item.url}</p>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UiNewsDetail;
