import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button";
import { useDispatch } from "react-redux";
import { confirmOrderStatusThunk } from "../../../../../reducersToolkit/thunk/orders/confirmOrderStatusThunk";

const WidgetOrderConfirm = ({ currentOrder }) => {
  const dispatch = useDispatch();

  const [userId, setUserId] = useState("");

  useEffect(() => {
    setUserId(currentOrder.id);
  }, [currentOrder]);

  const motivation =
    "We are pleased to inform you that your order for renting a bike has been confirmed by the rental point! Your bike will be ready for use at the agreed time and place. We understand how important convenience and reliability are to you, so we do everything we can to provide you with quality service.";

  const updateData = {
    orderStatuses: {
      currentStatus: "in progress",

      historyChanges: [
        {
          orderStatus: "in progress",
          motivation: motivation,
          userId: userId,
          timestamp: Date.now().toString(),
        },
      ],
    },
  };

  const handkeConfirmOrderStatus = () => {
    if (motivation.trim()) {
      dispatch(
        confirmOrderStatusThunk({
          orderId: currentOrder.id,
          updateData: updateData,
        })
      )
        .unwrap()
        .then(() => {
          console.log("Order status updated successfully!");
        })
        .catch((error) => {
          console.error("Failed to update order status: ", error);
        });
    } else {
      console.error("Motivation is empty!");
    }
  };
  return (
    <>
      <Button
        label="Confirm"
        allert={
          <h1
            style={{
              width: "100%",
              padding: "24px",
              textAlign: "center",
            }}
          >
            Please confirm that you are ready to provide the customer with the
            order he has placed.
          </h1>
        }
        active={true}
        onClick={handkeConfirmOrderStatus}
      />
    </>
  );
};

export default WidgetOrderConfirm;
