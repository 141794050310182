import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentBox from "./WidgetsProfile/ContentBox/ContentBox";
import SegmentedControls from "./WidgetsProfile/SegmentedControls/SegmentedControls";

import WidgetRentalPointsListV4 from "./Moderation/WidgetRentalPointsListV4";
import WidgetBikesList from "./BikesV3/WidgetBikesList";

import WidgetUsersListModerationV4 from "./Moderation/WidgetUsersListModerationV4";

const UINotificationsV3 = () => {
  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);
  const userRole = "admin";
  /**
   *
   * LocalStats - инициализируем переменные в localState с целью их оперативного обновления и человека читаемости кода
   *
   */
  // ------- Windows stats
  // ------- Loading stats
  // ------- Buttons stats
  // ------- Widgets stats
  // ------- Files

  // ------- previewNewData
  const [
    general_usersPreviewNotifications,
    setGeneral_usersPreviewNotifications,
  ] = useState(null);
  const [
    general_rentalPointsPreviewNotifications,
    setGeneral_rentalPointsPreviewNotifications,
  ] = useState(null);
  const [
    general_bikesPreviewNotifications,
    setGeneral_bikesPreviewNotifications,
  ] = useState(null);
  // ------- rootNewData

  // ------- tempPreviewData
  // ------- tempRootData
  // ------- tempData

  // ------- loading
  // ------- error
  // ------- localStates
  const [moderationType, setModerationType] = useState("users");

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */
  useEffect(() => {
    setGeneral_usersPreviewNotifications(
      tempPreviewData?.general_usersPreviewNotifications || null
    );
    setGeneral_rentalPointsPreviewNotifications(
      tempPreviewData?.general_rentalPointsPreviewNotifications || null
    );
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
  }, [
    tempPreviewData?.general_usersPreviewNotifications,
    tempPreviewData?.general_rentalPointsPreviewNotifications,
    tempPreviewData?.general_bikesPreviewNotifications,
  ]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        {userRole === "admin" && (
          <ContentBox>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <SegmentedControls
                options={["users", "rental points", "bikes"]}
                onSelect={moderationType}
                selected={setModerationType}
              />

              {moderationType === "users" ? (
                <>
                  <WidgetUsersListModerationV4
                    usersModerationList={general_usersPreviewNotifications}
                  />
                </>
              ) : moderationType === "rental points" ? (
                <>
                  <WidgetRentalPointsListV4
                    rentalPointsList={general_rentalPointsPreviewNotifications}
                    mode="moderation"
                  />
                </>
              ) : (
                moderationType === "bikes" && (
                  <>
                    <WidgetBikesList
                      bikesList={general_bikesPreviewNotifications}
                      mode="moderation"
                    />
                  </>
                )
              )}
            </div>
          </ContentBox>
        )}
      </div>
    </div>
  );
};

export default UINotificationsV3;
