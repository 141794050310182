import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, signOut } from "firebase/auth";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { setCurrentRootData } from "../../catalogSliceV4";

// Создаем асинхронный экшн для выхода из учетной записи пользователя
export const logoutThunkV4 = createAsyncThunk(
  "reducersToolkit/logoutThunkV4",
  async (_, { rejectWithValue, dispatch }) => {
    const auth = getAuth();

    try {
      // Проверяем, есть ли текущий авторизованный пользователь
      if (!auth.currentUser) {
        // Если текущий пользователь уже вышел, возвращаем сообщение об этом
        console.warn("No user is currently signed in.");
        return rejectWithValue({
          errorMessage: "No user is currently signed in.",
        });
      }

      // Выполняем операцию выхода из учетной записи
      await signOut(auth);

      // Сбрасываем состояние текущего авторизованного пользователя
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          data: null,
        })
      );

      // Возвращаем успешное выполнение
      return true;
    } catch (error) {
      // Логируем ошибку
      const errorMessage = interpretAuthErrorV2(error.code);
      console.error(
        "Logout failed, please retry. Error: ",
        errorMessage,
        error
      );
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { getAuth, signOut } from "firebase/auth";
// import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";

// // Создаем асинхронный экшн для выхода из учетной записи пользователя
// export const logoutThunkV4 = createAsyncThunk(
//   "reducersToolkit/logoutThunkV4",
//   async (_, { rejectWithValue }) => {
//     const auth = getAuth();
//     try {
//       // Выполняем операцию выхода из учетной записи
//       await signOut(auth);
//       // Возвращаем успешное выполнение
//       return true;
//     } catch (error) {
//       const errorMessage = interpretAuthErrorV2(error.code);
//       console.error("Logout failed, please retry. Error: ", errorMessage);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
