import React, { useEffect } from "react";
import "./popup.css";
import Button from "../Buttons/Button";
import ButtonsBar from "../ButtonsBar/ButtonsBar";
const PopupAllert = ({ isOpen, onClose, children, title, onConfirm }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleToggle = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <div className="popup__allert-content-box">
        {/* <div className="popup__button-bar">
        <div className="popup__button--close" onClick={onClose}>
          <span className="material-symbols-outlined">close</span>
        </div>
      </div> */}

        <div className="popup__allert-content">
          <div className="popup__header">
            <h5>{title}</h5>
            <div className="popup__button--close" onClick={onClose}>
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>

          <div className="popup__allert-options">
            <div className="popup__children-content">{children}</div>
          </div>
        </div>

        <ButtonsBar>
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            <Button
              type="small"
              active={true}
              label="Cancel"
              onClick={onClose}
              color="--color-black"
            />
            <Button
              type="small"
              active={true}
              label="Сonfirm"
              onClick={handleToggle}
            />
          </div>

          {/* <div className="popup__allert-button-bottom-bar">
            <button className="popup__allert-button--cancel" onClick={onClose}>
              Cancel
            </button>
            <button
              className="popup__allert-button--confirm"
              onClick={handleToggle}
            >
              Сonfirm
            </button>
          </div> */}
        </ButtonsBar>
      </div>
    </>
  );
};

export default PopupAllert;
