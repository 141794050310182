import React, { useEffect, useState } from "react";
import PopupDirrectory from "../../WidgetsProfile/Popups/PopupDirrectory";
import AddData from "../../WidgetsProfile/AddData/AddData";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../../../../../UIElements/Preloader";
import ContentBox from "../../WidgetsProfile/ContentBox/ContentBox";
import WidgetBrandslistV4 from "./WidgetBrandslistV4";

import WindowBrandDetailV4 from "./WindowBrandDetailV4";
import { subscribePreviewDocumentsThunkV4 } from "../../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4";

const WindowBrandsListV4 = () => {
  const dispatch = useDispatch();

  const { loading, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [isOpenWindowDirectorirsDetail, setIsOpenWindowDirectorirsDetail] =
    useState(false);

  const [loadingBrandsPreview, setLoadingBrandsPreview] = useState(false);
  const [loading_create_brand, setLoading_create_brand] = useState(false);
  const [loading_restoreBrand, setLoading_restoreBrand] = useState(false);
  const [loading_deleBrand, setLoading_deleBrand] = useState(false);
  const [loading_updateBrands, setLoading_updateBrands] = useState(false);

  const [brandsPreview, setBrandsPreview] = useState(null);

  /** Получаем данные по брендам из стейта */
  useEffect(() => {
    setBrandsPreview(tempPreviewData?.subscribe_brandsPreview || null);
  }, [tempPreviewData?.subscribe_brandsPreview]);

  /** Получаем состояние загрузки из стейта */
  useEffect(() => {
    setLoadingBrandsPreview(loading?.loading_subscribe_brandsPreview || false);
    setLoading_create_brand(loading?.loading_create_brand || false);
    setLoading_restoreBrand(loading?.loading_restoreBrand || false);
    setLoading_deleBrand(loading?.loading_deleBrand || false);
    setLoading_updateBrands(loading?.loading_updateBrands || false);
  }, [
    loading?.loading_subscribe_brandsPreview,
    loading?.loading_create_brand,
    loading?.loading_restoreBrand,
    loading?.loading_deleBrand,
    loading?.loading_updateBrands,
  ]);

  const handleClose = () => {
    setIsOpenWindowDirectorirsDetail(false);
  };

  const handleOpenWindowBrandDetailV4 = () => {
    setIsOpenWindowDirectorirsDetail(true);
  };

  useEffect(() => {
    // setLoadingBrandsPreview(true);

    let unsubscribe;
    const result = dispatch(
      subscribePreviewDocumentsThunkV4({
        collectionPath: `brandsPreview`,
        previewCurrentDocumenState: "subscribe_brandsPreview",
        excludedIds: ["metadata"],
        loadingStateName: "loading_subscribe_brandsPreview",
        errorStateName: "currentError",
      })
    ).unwrap();

    // Проверяем, что функция отписки определена
    unsubscribe = result.payload?.unsubscribe;
    if (typeof unsubscribe === "function") {
      console.log("Функция отписки установлена успешно");
    } else {
      console.error("Не удалось получить функцию отписки");
    }
  }, [dispatch]);

  return (
    <>
      <ContentBox position="one">
        {loadingBrandsPreview ||
        loading_create_brand ||
        loading_restoreBrand ||
        loading_deleBrand ||
        loading_updateBrands ? (
          <Preloader />
        ) : (
          <>
            {/* POPUP WINDOW */}
            <PopupDirrectory
              isOpen={isOpenWindowDirectorirsDetail}
              onClose={handleClose}
              title="Add brand"
            >
              <WindowBrandDetailV4 mode="new" onClose={handleClose} />
            </PopupDirrectory>
            {/* POPUP WINDOW */}

            <AddData
              onClick={handleOpenWindowBrandDetailV4}
              icon={<span className="material-symbols-outlined">add</span>}
              title="Add brand"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />

            <WidgetBrandslistV4 directories={brandsPreview} />
          </>
        )}
      </ContentBox>
    </>
  );
};

export default WindowBrandsListV4;
