import React, { useState } from "react";
import InputText from "../Profile/WidgetsProfile/Inputs/InputText";
import Button from "../Profile/WidgetsProfile/Buttons/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleSignUpThunkV2 } from "../../../../reducersToolkit/thunk/user/googleSignUpThunkV2";
import { emailSignInThunkV4 } from "../../../../reducersToolkit/thunk/user/emailSignInThunkV4";
import { googleSignUpThunkV4 } from "../../../../reducersToolkit/thunk/user/googleSignUpThunkV4";

const WidgetSignIn = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const handleSetLogin = (event) => {
    setLogin(event.target.value);
  };
  const handleSetPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailSignIn = async () => {
    dispatch(emailSignInThunkV4({ email: login, password: password }))
      .unwrap()
      .then(() => {
        navigate("/profile");
        onClose();
      })
      .catch((errorMessage) => {
        console.error("Error during registration: ", errorMessage);
      });
  };

  const handleGoogleAuth = async () => {
    dispatch(googleSignUpThunkV4())
      .unwrap()
      .then(() => {
        navigate("/profile");
        onClose();
      })
      .catch((error) => {
        console.error("Error during registration: ", error.errorMessage);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <InputText
        type="text"
        label="Email"
        description="Please enter your name as you would like to be addressed."
        value={login}
        onChange={handleSetLogin}
      />
      <InputText
        type="password"
        label="Password"
        description="Please enter your name as you would like to be addressed."
        value={password}
        onChange={handleSetPassword}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "24px 0",
        }}
      >
        <Button
          active={login !== "" && password !== ""}
          label="Login"
          onClick={handleEmailSignIn}
        />
        <Button
          active={true}
          color="--color-black"
          label="Login with Google"
          onClick={handleGoogleAuth}
        />
      </div>
    </div>
  );
};

export default WidgetSignIn;
