import { createAsyncThunk } from "@reduxjs/toolkit";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { fetchDocumentById } from "../../../services/firebase/crudFirebaseStorage";
import interpretAuthErrorV2 from "../../../functions/interpretAuthErrorV2";
import { createDataThunkV4 } from "../catalog/V4/CRUD/createDataThunkV4";

export const googleSignUpThunkV4 = createAsyncThunk(
  "reducersToolkit/googleSignUpThunkV4",
  async (_, { dispatch, rejectWithValue }) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Принудительно показываем окно выбора аккаунта
    provider.setCustomParameters({
      prompt: "select_account",
    });

    try {
      // Используем signInWithPopup для авторизации через всплывающее окно
      const userCredential = await signInWithPopup(auth, provider);

      // Проверяем, есть ли пользователь уже в базе данных
      const existingUser = await fetchDocumentById(
        "users",
        userCredential.user.uid
      );

      // Если пользователь уже существует, возвращаем его данные
      if (existingUser) {
        return { currentUser: existingUser };
      }

      // Создаем объект с данными пользователя
      const rootUserData = {
        createdat: Date.now(),
        status: "moderation",
        role: {
          currentRole: "rental",
        },
        user: {
          ownerName: userCredential.user.displayName || "",
        },

        userContacts: {
          email: userCredential.user.email,
          phoneNumber: userCredential.user.phoneNumber || "",
        },

        providerData: userCredential.user.providerData || [],
        id: userCredential.user.uid,
      };

      const previewUserData = {
        createdat: Date.now(),
        fileUrl: userCredential.user.photoURL || "",
        ownerName: userCredential.user.displayName || "",
        email: userCredential.user.email,
        phoneNumber: userCredential.user.phoneNumber || "",
        id: userCredential.user.uid,
        status: "moderation",
        role: "rental",
      };

      await dispatch(
        createDataThunkV4({
          // Данные которые будут использоваться для создания коллекции в firestore
          rootData: rootUserData,
          idRootDocument: userCredential.user.uid,
          previewData: previewUserData,
          // files: {
          //   // или другие массивы файлов, если есть
          //   iconFiles: ,
          // },
          // Определяем пути для коллекций и метаданных с точками в качестве разделителей
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          // Имя того массива файлов который будет использоваться для назначения иконки
          // iconFields: "iconFiles",
          rootCollectionPath: "users",
          previewCollectionPath: "usersPreview",
          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: "usersPreview.metadata",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "cuurrentCountry",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "usersPreview",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "emailSignUpThunkV2LoadingCreateUser",
          errorStateName: "emailSignUpThunkV2ErrorCreateUser",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("Пользователь успешно создан:", response);
        })
        .catch((error) => {
          console.error("Ошибка при создании пользователя:", error);
        });

      return {
        currentUser: rootUserData,
        rootData: rootUserData,
        previewData: previewUserData,
      };
    } catch (error) {
      // Обрабатываем ошибки авторизации
      const errorMessage = interpretAuthErrorV2(error.code);
      console.error("Failed to sign up user. Error:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);
