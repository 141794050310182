import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import {
  addDocumentById,
  fetchDocumentById,
} from "../../../../services/firebase/crudFirebaseStorage";
import { Timestamp } from "firebase/firestore"; // Правильный импорт Timestamp
import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";

export const googleSignUpThunk = createAsyncThunk(
  "user/googleSignUp",
  async (_, { rejectWithValue }) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const { email, uid, displayName, phoneNumber, photoURL, providerData } =
        userCredential.user;

      const existingUser = await fetchDocumentById("users", uid);

      if (existingUser) {
        return { currentUser: existingUser };
      }

      // Использование Timestamp для получения текущей даты и времени
      const timestamp = Timestamp.fromDate(new Date());
      const timestampSeconds = Math.floor(Date.now() / 1000);

      const currentUser = {
        user: {
          contactingTheUser: displayName || "",
          ownerName: "",
          ownerSurname: "",
          gender: "",
          yourGender: "",
          avatar: [
            {
              url: photoURL || "",
              prioriry: false,
            },
          ],
        },
        userContacts: {
          email: email,
          phoneNumber: phoneNumber || "",
        },
        company: {
          companyName: "",
          mailingAddress: "",
          timeZone: "",
        },

        id: uid,
        stateProfile: "new",
        // name: "",
        // displayName: displayName || "",
        // photoURL: photoURL || "",
        role: "rental",
        providerData: providerData.map((data) => ({
          providerId: data.providerId,
          uid: data.uid,
        })),
        offerConfirm: timestampSeconds,
      };

      // Добавление данных пользователя в Firestore и получение ID добавленного документа
      await addDocumentById(currentUser, "users", uid);
      return { currentUser };
    } catch (error) {
      const errorMessage = interpretAuthErrorV2(error.code);
      console.error("Failed to sign up user. Error:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);
