// OrderItemDetail
import React, { useEffect, useState } from "react";
import WidgetOrderStatus from "./WidgetOrderStatus";
import WidgetRateDetail from "./WidgetRateDetail";
import { useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetOrderBikeDetail from "./WidgetOrderBikeDetail";

const WindowOrderItemDetail = () => {
  const [bookingDates, setBookingDates] = useState({});
  const [orderBike, setOrderBike] = useState({});
  const [orderRentalPoint, setOrderRentalPoint] = useState({});
  const [orderStatuses, setOrderStatuses] = useState({});
  const [replacementBikes, setReplacementBikes] = useState({});
  const [totals, setTotals] = useState({});
  const [userCustomer, setUserCustomer] = useState({});
  const [userRentalPoints, setUserRentalPoints] = useState([]);
  const [userId, setUserId] = useState("");
  const [currentStatus, setCurrentStatus] = useState(null);
  const { currentOrder } = useSelector((state) => state.orderSliceV2);

  const { brands, categories } = useSelector((state) => state.directorySliceV2);

  const {
    isLoading: isLoadingUser,
    currentUser,
    error: errorUser,
  } = useSelector((state) => state.catalogSliceV4);
  const { isLoading, rentalPointsList } = useSelector(
    (state) => state.catalogSliceV4
  );

  const { bikes } = useSelector((state) => state.moderationSliceV2);

  useEffect(() => {
    setBookingDates(currentOrder?.bookingDates);
    setOrderBike(currentOrder?.orderBike);
    setOrderRentalPoint(currentOrder?.orderRentalPoint);
    setOrderStatuses(currentOrder?.orderStatuses);
    setReplacementBikes(currentOrder?.replacementBikes);
    setTotals(currentOrder?.totals);
    setUserCustomer(currentOrder?.userCustomer);
    setCurrentStatus(currentOrder?.orderStatuses?.currentStatus);
  }, [currentOrder]);

  useEffect(() => {
    const filteredRentalPoints = rentalPointsList.filter((rentalPoint) =>
      currentUser.rentalPointsIds.includes(rentalPoint.id)
    );
    setUserRentalPoints(filteredRentalPoints);
    setUserId(currentUser?.id);
  }, [rentalPointsList, currentUser]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <WidgetRateDetail
          bookingDates={bookingDates}
          orderBike={orderBike}
          totals={totals}
        />
      </ContentBox>
      <ContentBox>
        <WidgetOrderBikeDetail
          orderBike={orderBike}
          bookingDates={bookingDates}
          brands={brands}
          categories={categories}
        />
      </ContentBox>
      <ContentBox position="end">
        <WidgetOrderStatus
          userRentalPoints={userRentalPoints}
          bikes={bikes}
          brands={brands}
          categories={categories}
          bookingDates={bookingDates}
          currentOrder={currentOrder}
          userId={userId}
          currentStatus={currentStatus}
        />
      </ContentBox>
    </div>
  );
};

export default WindowOrderItemDetail;
