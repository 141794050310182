import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
import { db } from "../../../../services/firebase/authFirebase";

export const updateCityThunkV4 = createAsyncThunk(
  "cities/updateCityThunkV4",
  async ({ countryId, cityId, updatedData, files }, { rejectWithValue }) => {
    try {
      // 1. Получение ссылки на документ города
      const docRef = doc(db, "catalog", countryId, "cities", cityId);

      // 2. Если есть новые файлы, загружаем их
      let uploadedFiles = [];
      if (files && files.length > 0) {
        uploadedFiles = await uploadFilesToFirebase(
          `cities/${cityId}`, // Путь для загрузки файлов, основанный на идентификаторе города
          files
        );
      }

      // 3. Объединяем старые файлы с новыми
      const filesUpdate =
        uploadedFiles.length > 0
          ? {
              files: {
                iconFiles: [
                  ...(updatedData?.files?.iconFiles || []),
                  ...uploadedFiles,
                ],
              },
            }
          : {};

      // 4. Обновляем документ города в Firestore
      const finalData = { ...updatedData, ...filesUpdate };
      await updateDoc(docRef, finalData);

      console.log("Документ города в Firestore успешно обновлен.");

      // 5. Обновление документа citiesPrewiew
      const citiesPrewiewDocRef = doc(
        db,
        "catalog",
        countryId,
        "cities",
        "citiesPrewiew"
      );

      const citiesPreviewSnapshot = await getDoc(citiesPrewiewDocRef);

      if (citiesPreviewSnapshot.exists()) {
        const existingData = citiesPreviewSnapshot.data().data || [];

        // Найти и обновить объект города в массиве data
        const updatedPreviewData = existingData.map((city) =>
          city.id === cityId
            ? {
                ...city,
                title: {
                  titleOriginal: finalData.title.titleOriginal || "",
                },
                filesUrl: {
                  iconFiles:
                    uploadedFiles.find((file) => file.priority)?.url ||
                    uploadedFiles[0]?.url ||
                    city.filesUrl.iconFiles, // URL файла с приоритетом или первый файл
                },
              }
            : city
        );

        // 6. Сохранение обновленных данных в citiesPrewiew
        await setDoc(citiesPrewiewDocRef, { data: updatedPreviewData });

        console.log(
          "Данные в документе citiesPrewiew обновлены для города:",
          cityId
        );
      } else {
        console.error(
          "Документ citiesPrewiew не найден при попытке обновить данные города."
        );
      }

      return { countryId, cityId, updatedData: finalData };
    } catch (error) {
      console.error("Ошибка при обновлении города:", error);
      return rejectWithValue(error.message);
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { doc, updateDoc } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../services/firebase/crudFirestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const updateCityThunkV4 = createAsyncThunk(
//   "cities/updateCityThunkV4",
//   async ({ countryId, cityId, updatedData, files }, { rejectWithValue }) => {
//     try {
//       // 1. Получение ссылки на документ города
//       const docRef = doc(db, "catalog", countryId, "cities", cityId);

//       // 2. Если есть новые файлы, загружаем их
//       let uploadedFiles = [];
//       if (files && files.length > 0) {
//         uploadedFiles = await uploadFilesToFirebase(
//           `cities/${cityId}`, // Путь для загрузки файлов, основанный на идентификаторе города
//           files
//         );
//       }

//       // 3. Объединяем старые файлы с новыми
//       const filesUpdate =
//         uploadedFiles.length > 0
//           ? {
//               files: {
//                 iconFiles: [
//                   ...(updatedData?.files?.iconFiles || []),
//                   ...uploadedFiles,
//                 ],
//               },
//             }
//           : {};

//       // 4. Обновляем документ в Firestore
//       const finalData = { ...updatedData, ...filesUpdate };
//       await updateDoc(docRef, finalData);

//       console.log("Документ города в Firestore успешно обновлен.");

//       return { countryId, cityId, updatedData: finalData };
//     } catch (error) {
//       console.error("Ошибка при обновлении города:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );
