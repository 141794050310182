import { formatCurrencyUSD } from "../../../../../../functions/currencyFormatter";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  return date.toLocaleString("en-US", { month: "short" });
};

const Rate = ({
  setSelectedMonth,
  rate,
  startBookingDates,
  endBookingDates,
  title,
  description,
}) => {
  const startMonth = new Date(startBookingDates).getMonth();
  const endMonth = new Date(endBookingDates).getMonth();
  const maxPrice = Math.max(...rate.map((rateItem) => rateItem.price));
  const calculateHeight = (price) => (price / maxPrice) * 56;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      {title && <h5>{title && title}</h5>}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
        }}
      >
        {rate?.map((rateItem, index) => (
          <div key={index} style={{ width: "100%" }}>
            <div
              onClick={
                rateItem?.price > 0 ? () => setSelectedMonth(index) : undefined
              }
              title={`${getMonthName(index)} — ${formatCurrencyUSD(
                rateItem?.price
              )}`}
              style={{
                height: `${calculateHeight(rateItem.price)}px`,
                width: "100%",
                borderRadius: "4px",
                backgroundColor:
                  index >= startMonth && index <= endMonth
                    ? "var(--color-success)"
                    : rateItem.price === 0
                    ? "var(--color-gray-100)"
                    : "var(--color-gray-100)",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "flex-end",
                ...(index >= startMonth ||
                index <= endMonth ||
                rateItem?.price > 0
                  ? {
                      minHeight: "23px",
                      padding: "4px 4px",
                    }
                  : {
                      minHeight: "8px",
                      padding: "0",
                    }),
                ...(rateItem?.price > 0 && { cursor: "pointer" }),
              }}
            >
              {index >= startMonth && index <= endMonth ? (
                <p className="p-light" style={{ color: "var(--color-white)" }}>
                  {formatCurrencyUSD(rateItem?.price)}
                </p>
              ) : (
                rateItem?.price > 0 && (
                  <p
                    className="p-light"
                    style={{
                      fontSize: "0.5em",
                      //   color: "var(--color-white)",
                    }}
                  >
                    {formatCurrencyUSD(rateItem?.price)}
                  </p>
                )
              )}
            </div>
            <div
              style={{
                height: "24px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <p className="p-light" style={{ color: "var(--color-black)" }}>
                {getMonthName(index)}
              </p>
            </div>
          </div>
        ))}
      </div>
      {description && (
        <div
          style={{
            // padding: "0 16px",
            width: "100%",
          }}
        >
          <p
            style={{
              width: "70%",
              // color: "var(--color-gray-700)",
            }}
            className="p-light"
          >
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default Rate;
