import React, { useEffect, useCallback, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Preloader from "../../UIElements/Preloader";
import "./PublicMenu.css";
import PopupWindow from "../PublickWidgets/Windows/PopupWindow";
import WindowNavigete from "./WindowNavigete";
import WindowLogIn from "./WindowLogIn";
import AvatarUser from "../../Private/Login/Profile/WidgetsProfile/Avatars/AvatarUser";
import PopupAllert from "../../Private/Login/Profile/WidgetsProfile/Popups/PopupAllert";
import WindowLanguage from "./WindowLanguage";
import WidgetAlert from "../../Private/Login/Profile/WidgetsProfile/Alerts/WidgetAlert";

import { setResetErrorLogin } from "../../../reducersToolkit/catalogSliceV4";
import { logoutThunkV4 } from "../../../reducersToolkit/thunk/user/logoutThunkV4";
import QuantityParams from "../../Private/Login/Profile/WidgetsProfile/QuantityParams/QuantityParams";

const WidgetPublicMenu = ({
  mainMenu,
  currentUser,
  totalNotifications,
  userRole,
  isLoading,
  error,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [mainNavegate, setMainNavegate] = useState(false);
  const [windowAuth, setWindowAuth] = useState(false);

  const [url, setURL] = useState(null);
  const [isOpenAllertWindow, setIsOpenAllertWindow] = useState(false);
  const [isOpenWindowLanguage, setIsOpenWindowLanguage] = useState(false);
  const [isOpenErrorWindow, setIsOpenErrorWindow] = useState(false);
  const [windowModerationListV4, setWindowModerationListV4] = useState(false);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */

  /**
   *
   * Fetch функции
   *
   */

  /**
   *
   * Внутренняя логика компонента
   *
   */

  const handleConfirmError = () => {
    dispatch(setResetErrorLogin());
  };

  const handleCloseErrorAllertWindow = () => {
    dispatch(setResetErrorLogin());
  };

  const handleOpenAllertWindow = () => {
    setIsOpenAllertWindow(true);
  };

  const handleOpenMainNavigate = () => {
    setMainNavegate(true);
  };

  const handleOpenWindowAuth = () => {
    setWindowAuth(true);
  };

  const handleOpenWindowLanguage = () => {
    setIsOpenWindowLanguage(true);
  };

  const handleClose = () => {
    setMainNavegate(false);
    setWindowAuth(false);
    setIsOpenWindowLanguage(false);
    setWindowModerationListV4(false);
  };

  const handleCloseAllertWindow = () => {
    setIsOpenAllertWindow(false);
  };

  const isValidSlug = useCallback(
    (slug) => {
      return (
        mainMenu.some((menuItem) => menuItem.slug === slug) ||
        /^(\/newsDatail\/[A-Za-z0-9-]+\/[A-Za-z0-9]+)$/ ||
        /^(\/rentalPoint\/[A-Za-z0-9-]+\/[A-Za-z0-9]+)$/ ||
        /^(\/catalog\/productCard\/[A-Za-z0-9]+|\/productCard\/[A-Za-z0-9]+)$/.test(
          slug
        )
      );
    },
    [mainMenu]
  );

  // Функция для выхода из системы.
  const handleLogout = async () => {
    dispatch(logoutThunkV4())
      .unwrap()
      .then(() => {
        // dispatch(
        //   setCurrentRootData({
        //     rootCurrentDocumentState: "user_current_auth",
        //     data: null,
        //   })
        // );
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during logout: ", error.errorMessage);
      });
  };

  useEffect(() => {
    if (!isValidSlug(location.pathname)) {
      navigate("/");
    }
  }, [location.pathname, isValidSlug, navigate]);

  const Menu = () => {
    return (
      <>
        {/* ДО авторизации */}

        <PopupWindow
          isLoading={isLoading}
          isOpen={windowAuth}
          onClose={handleClose}
          title=""
        >
          <WindowLogIn onClose={handleClose} />
        </PopupWindow>
        {/* Меню с навигацией по проекту */}

        <PopupWindow
          isLoading={isLoading}
          isOpen={mainNavegate}
          onClose={handleClose}
          title=""
        >
          <WindowNavigete onClose={handleClose} isValidSlug={isValidSlug} />
        </PopupWindow>

        {/* ПОСЛЕ авторизации */}

        <PopupWindow
          isLoading={isLoading}
          isOpen={isOpenWindowLanguage}
          onClose={handleClose}
          title=""
        >
          <WindowLanguage onClose={handleClose} />
        </PopupWindow>

        {/* УВЕДОМЛЕНИЯ */}

        <PopupAllert
          onConfirm={handleLogout}
          isOpen={isOpenAllertWindow}
          onClose={handleCloseAllertWindow}
          title="Attention!"
        >
          <WidgetAlert
            title="Are you sure you want to log out?"
            description="Oh no, are you sure you want to leave? 🚴‍♂️ We’ll miss you and your bike adventures! 😢 But don’t worry, whenever you’re ready to hit the road
          again, we’ll be here with a new set of wheels and plenty of excitement
          waiting for you. 😎 Safe travels! 🌟"
          />
        </PopupAllert>

        {/* Обработака ошибок */}

        <PopupAllert
          onConfirm={handleConfirmError}
          isOpen={isOpenErrorWindow}
          onClose={handleCloseErrorAllertWindow}
          title="Attention!"
        >
          <WidgetAlert
            title={error}
            //  description={error}
          />
        </PopupAllert>

        {/* POPUP WINDOW */}

        {/* POPUP WINDOW */}

        <div
          style={{
            width: "100%",
            zIndex: "3999",
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
              cursor: "pointer",
            }}
            onClick={handleOpenMainNavigate}
          >
            <span className="material-symbols-outlined">menu</span>
            <div
              style={{
                backgroundColor: "var(--color-black)",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "48px",

                borderRadius: "16px",
              }}
            >
              <img
                style={{
                  height: "24px",
                  boxSizing: "border-box",
                }}
                src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripsterWhite.svg?alt=media&token=3409bcaf-ad17-47ff-b3c5-eb83971e650a"
                alt="Logo"
              />
            </div>
          </div>

          {userRole ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <QuantityParams
                  param={totalNotifications || 0}
                  style={{
                    position: "absolute",
                    // right: "2px",
                    top: "-16px",
                  }}
                />

                <Link to="/notifications" className="material-symbols-outlined">
                  notifications
                </Link>
              </div>

              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={handleOpenWindowLanguage}
                className="material-symbols-outlined"
              >
                translate
              </span>

              <Link
                to="/profile"
                style={{
                  backgroundColor: "var(--color-primary)",
                  height: "48px",
                  aspectRatio: "1/1",
                  borderRadius: "80px",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "var(--color-white)",
                    height: "44px",
                    aspectRatio: "1/1",
                    borderRadius: "80px",
                    padding: "2px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleClose}
                >
                  <div
                    style={{
                      backgroundColor: "var(--color-gray-100)",
                      height: "40px",
                      aspectRatio: "1/1",
                      borderRadius: "80px",
                      boxSizing: "border-box",
                    }}
                  >
                    {isLoading && url ? (
                      <Preloader
                        style={{
                          height: "34px",
                          width: "34px",
                        }}
                      />
                    ) : (
                      <AvatarUser size={40} url={url} />
                    )}
                  </div>
                </div>
              </Link>

              <span
                style={{
                  cursor: "pointer",
                }}
                className="material-symbols-outlined"
                onClick={handleOpenAllertWindow}
              >
                logout
              </span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                }}
                className="material-symbols-outlined"
                onClick={handleOpenWindowAuth}
              >
                login
              </span>
            </div>
          )}
        </div>
      </>
    );
  };

  return <Menu />;
};

export default WidgetPublicMenu;
