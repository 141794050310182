import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import Switcher from "../WidgetsProfile/Switcher/Switcher";
import WidgetOffer from "./WidgetOffer";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetSoleProprietorship from "../UserProfile/WidgetSoleProprietorship";
import WidgetPartnership from "../UserProfile/WidgetPartnership";
import WidgetLimitedLiabilityCompany from "../UserProfile/WidgetLimitedLiabilityCompany";
import WidgetContacts from "./WidgetContacts";
import { useDispatch, useSelector } from "react-redux";
import { upgradeStatusThunkv2 } from "../../../../../reducersToolkit/thunk/user/upgradeStatusThunkv2";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
const AllertWindowOfferAccept = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "48px",
        padding: "48px",
        textAlign: "center",
      }}
    >
      <img
        alt=""
        src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripster.svg?alt=media&token=66931d08-c4f8-4754-9c10-c1d8233a2df7"
        style={{
          width: "48%",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <h1>Please confirm that you want to update the user information.</h1>
        <p className="p-light">
          By confirming this action, you agree to our terms and conditions. We
          encourage you to review them carefully to ensure you fully understand
          your rights and obligations. If you have any questions, feel free to
          reach out to us for more details.
        </p>
      </div>
    </div>
  );
};

const WindowPartnerRegistration = () => {
  const dispatch = useDispatch();
  const [acceptOffer, setAcceptOffer] = useState(false);
  const [businessStructure, setBusinessStructure] = useState(
    "sole proprietorship"
  );

  const [userContacts, setUserContacts] = useState({});
  const [company, setCompany] = useState({});
  const [uId, setUId] = useState(null);

  const {
    isLoading: isLoadingUser,
    currentUser,
    error: errorUser,
  } = useSelector((state) => state.catalogSliceV4);

  useEffect(() => {
    setUserContacts(currentUser?.userContacts || {});
    setCompany(currentUser?.company || {});
    setUId(currentUser?.id || null);
  }, [currentUser]);

  const upgradeStatus = ({ status }) => {
    if (uId) {
      dispatch(
        upgradeStatusThunkv2({
          collectionName: "users",
          docId: uId,
          fieldsToUpdate: {
            userContacts: userContacts,
            company: {
              ...company,
              businessStructure: businessStructure,
            },
            moderation: { status: status },
            role: { currentRole: "rental" },
          },
        })
      );
    }
  };
  /**
   *
   * CRUD функции
   *
   */

  // const handleUpdateData = async () => {
  //   try {

  //         dispatch(
  //           updateDataThunkV4({
  //             // Указываем id preview документа который будем менять
  //             idPreviewDocument: previewCountryId,
  //             // Указываем id root Элемента
  //             idRootDocument: rootCountryId,
  //             // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
  //             // updatedData: rootCountryData,

  //             rootData: rootCountryData,
  //             previewData: previewCountryData,

  //             // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
  //             newFiles: {
  //               iconFiles: newPhotoFiles,
  //               /* Другие массивы файлов. Массивов файлов может быть несколько*/
  //             },
  //             // Имя того массива файлов который будет использоваться для назначения иконки
  //             iconFields: "iconFiles",
  //             // Путь до коллекции указывается из НЕ четного колличества вложенностей
  //             rootCollectionPath: "countries",
  //             previewCollectionPath: "countriesPreview",
  //             // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
  //             metadataDocumentPath: "countriesPreview.metadata",
  //             /*************/
  //             // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
  //             rootCurrentDocumentState: "cuurrentCountry",
  //             // Сохраняет в state МАССИВ объектов как результат выполнения thunk
  //             previewCurrentDocumenState: "countriesPreview",
  //             // Эти состояния будут отображать состояния загрузки и ошибки
  //             /*************/
  //             loadingStateName: "windowCountryDetailV4LoadingUpdateCountry",
  //             errorStateName: "currentError",
  //           })
  //         );
  //         onClose();

  //   } catch (error) {
  //     console.error("Ошибка при создании или обновлении страны:", error);
  //     onClose();
  //   }
  // };

  /**
   *
   * Логика компонента
   *
   */
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      {/* <WidgetQuestionnaire /> */}
      <ContentBox position="start">
        <SegmentedControls
          options={["sole proprietorship", "partnership", "LLC"]}
          label="Регистрация в сервисе проката"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien, ac rhoncus justo magna non turpis."
          selected={setBusinessStructure}
          onSelect={businessStructure}
        />
      </ContentBox>

      {businessStructure === "sole proprietorship" && (
        <>
          <ContentBox>
            <WidgetContacts
              userContacts={userContacts}
              setUserContacts={setUserContacts}
            />
          </ContentBox>
          <ContentBox>
            <WidgetSoleProprietorship
              currentUser={currentUser}
              company={company}
              setCompany={setCompany}
            />
          </ContentBox>
          <ContentBox>
            <WidgetOffer />
          </ContentBox>
        </>
      )}
      {businessStructure === "partnership" && (
        <>
          {/* <ContentBox>
            <WidgetContacts
              userContacts={userContacts}
              setUserContacts={setUserContacts}
            />
          </ContentBox>
          <ContentBox>
            <WidgetPartnership
              currentUser={currentUser}
              company={company}
              setCompany={setCompany}
            />
          </ContentBox>
          <ContentBox>
            <WidgetOffer />
          </ContentBox> */}
        </>
      )}
      {businessStructure === "LLC" && (
        <>
          {/* <ContentBox>
            <WidgetContacts
              userContacts={userContacts}
              setUserContacts={setUserContacts}
            />
          </ContentBox>
          <ContentBox>
            <WidgetLimitedLiabilityCompany
              currentUser={currentUser}
              company={company}
              setCompany={setCompany}
            />
          </ContentBox>
          <ContentBox>
            <WidgetOffer />
          </ContentBox> */}
        </>
      )}
      <ButtonsBar>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Switcher
            isChecked={acceptOffer}
            onChange={setAcceptOffer}
            label="I confirm my agreement with the offer"
            // description="I confirm that I agree to the terms of the offer."
          />
          <div>
            <Button
              type="small"
              active={acceptOffer}
              label="Accept"
              onClick={() => upgradeStatus({ status: "moderation" })}
              allert={<AllertWindowOfferAccept />}
            />
          </div>
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowPartnerRegistration;
