import React, { useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetOrderExhenge from "./WidgetOrderExhenge";
import WidgetOrderCancel from "./WidgetOrderCancel";
import WidgetOrderConfirm from "./WidgetOrderConfirm";

const WidgetOrderStatus = ({
  userRentalPoints,
  bikes,
  brands,
  categories,
  bookingDates,
  currentOrder,
  userId,
  currentStatus,
}) => {
  const [orderType, setOrderType] = useState(null);
  console.log("WindowOrderItemDetail currentOrder", orderType);
  // ["accept", "exhenge", "cancel"]
  return (
    <div className="profile-widget__content-box">
      <SegmentedControls
        // options={["accept", "exchange", "cancel"]}
        options={
          currentStatus === "exchange"
            ? ["cancel"]
            : currentStatus === "in progress"
            ? ["cancel"]
            : currentStatus === "canceled"
            ? []
            : currentStatus === "completed"
            ? []
            : currentStatus === "new"
            ? ["accept", "exchange", "cancel"]
            : [] // В случае, если `currentStatus` не соответствует ни одному из перечисленных значений
        }
        onSelect={
          currentStatus === "exchange"
            ? "cancel"
            : currentStatus === "in progress"
            ? "cancel"
            : currentStatus === "canceled"
            ? null
            : currentStatus === "completed"
            ? null
            : currentStatus === "new"
            ? "accept"
            : null // В случае, если `currentStatus` не соответствует ни одному из перечисленных значений
        }
        label="Confirm order status"
        selected={setOrderType}
      />
      {orderType && orderType === "accept" && (
        <WidgetOrderConfirm currentOrder={currentOrder} />
      )}

      {orderType && orderType === "exchange" && (
        <WidgetOrderExhenge
          userRentalPoints={userRentalPoints}
          bikes={bikes}
          brands={brands}
          categories={categories}
          bookingDates={bookingDates}
          currentOrder={currentOrder}
          userId={userId}
        />
      )}
      {orderType && orderType === "cancel" && (
        <WidgetOrderCancel currentOrder={currentOrder} />
      )}
    </div>
  );
};

export default WidgetOrderStatus;
