import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import Button from "../WidgetsProfile/Buttons/Button";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";
import { v4 as uuidv4 } from "uuid";

const WindowUserModerationV4 = ({ onClose, mode }) => {
  const dispatch = useDispatch();
  const { tempRootData } = useSelector((state) => state.catalogSliceV4);

  const [rentalUserModeration, setUserStateModeration] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [stateUser, setStateUser] = useState(null);

  // Comments
  const [allComments, setAllComments] = useState([]);
  const [moderatorComment, setModeratorComment] = useState("");

  // User
  const [userId, setUserId] = useState(null);

  /**
   *
   * Наполнение useState
   *
   */
  useEffect(() => {
    setCurrentUser(tempRootData?.manager_selectedRootUserModeration);
    setStateUser(
      tempRootData?.manager_selectedRootUserModeration?.stateUser?.status ||
        false
    );
    setAllComments(
      tempRootData?.manager_selectedRootUserModeration?.stateUser?.comments ||
        []
    );
    setUserId(tempRootData?.user_auth_rootCurrentData?.idRootDocument || null);
  }, [
    tempRootData?.manager_selectedRootUserModeration,
    tempRootData?.user_auth_rootCurrentData,
  ]);

  /**
   *
   * CRUD функции
   *
   */
  const editSelectedUserModeration = (newData) => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState:
          "manager_selectedRootUserModeration.stateUser",
        data: { ...newData },
      })
    );
  };

  /**
   *
   * Логика компонента
   *
   */

  const handleApply = () => {
    if (moderatorComment && rentalUserModeration && userId) {
      const status =
        rentalUserModeration === "accept"
          ? "approved"
          : rentalUserModeration === "reject"
          ? "moderation"
          : "banned";

      const newComment = {
        content: moderatorComment,
        status,
        moderatorId: userId,
        timestamp: Date.now(),
        id: uuidv4(),
      };

      // Добавляем новый комментарий в массив комментариев
      const updatedComments = [...allComments, newComment];

      // Обновляем данные rentalPointStatus
      const newData = {
        status: status,
        comments: updatedComments,
      };

      editSelectedUserModeration(newData);
      // Сбрасываем состояние комментария и закрываем окно
      setModeratorComment("");
      onClose();
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          {mode === "moderation" && (
            <>
              <SegmentedControls
                options={
                  stateUser === "moderation"
                    ? [
                        {
                          title: "accept",
                          description:
                            currentUser?.location?.cityid &&
                            currentUser?.location?.countryId ? (
                              <span>
                                By confirming the moderation of the rental
                                point, you make it available to all users.
                              </span>
                            ) : (
                              <span>
                                In order to undergo moderation of the rental
                                point, please indicate{" "}
                                <a href="#ancor1">country and city</a> for the
                                rental point.
                              </span>
                            ),
                        },
                        {
                          title: "reject",
                          description:
                            "By rejecting the moderation, the rental point will not be available to users.",
                        },
                        {
                          title: "bann",
                          description:
                            "By banning the rental point, it will not be available to users.",
                        },
                      ]
                    : stateUser === "approved"
                    ? [
                        {
                          title: "reject",
                          description:
                            "By rejecting the moderation, the rental point will not be available to users.",
                        },
                        {
                          title: "bann",
                          description:
                            "By banning the rental point, it will not be available to users.",
                        },
                      ]
                    : stateUser === "banned" && [
                        {
                          title: "moderation",
                          description:
                            "By sending the rental point back to moderation, it will not be available to users.",
                        },
                        {
                          title: "accept",
                          description:
                            currentUser?.location?.cityid &&
                            currentUser?.location?.countryId ? (
                              <span>
                                By confirming the moderation of the rental
                                point, you make it available to all users.
                              </span>
                            ) : (
                              <span>
                                In order to undergo moderation of the rental
                                point, please indicate
                                <a href="#ancor1">country and city</a> for the
                                rental point.
                              </span>
                            ),
                        },
                      ]
                }
                onSelect={
                  stateUser === "moderation"
                    ? "accept"
                    : stateUser === "approved"
                    ? "reject"
                    : stateUser === "banned" && "moderation"
                }
                label="User moderation state"
                selected={setUserStateModeration}
              />

              {/* Поля для комментариев в зависимости от состояния */}
              {rentalUserModeration && (
                <>
                  {rentalUserModeration === "accept" && (
                    <Textarea
                      label="Acceptance reason"
                      maxLength={5000}
                      description="Please provide your reason for accepting."
                      onChange={(event) =>
                        setModeratorComment(event.target.value)
                      }
                      value={moderatorComment}
                    />
                  )}

                  {rentalUserModeration === "reject" && (
                    <Textarea
                      label="Rejection reason"
                      maxLength={5000}
                      description="Please provide your reason for rejecting."
                      onChange={(event) =>
                        setModeratorComment(event.target.value)
                      }
                      value={moderatorComment}
                    />
                  )}

                  {rentalUserModeration === "bann" && (
                    <Textarea
                      label="Ban reason"
                      maxLength={5000}
                      description="Please provide your reason for banning."
                      onChange={(event) =>
                        setModeratorComment(event.target.value)
                      }
                      value={moderatorComment}
                    />
                  )}
                </>
              )}
            </>
          )}
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)", // Сетка из двух колонок
            gap: "16px", // Расстояние между кнопками
            width: "100%",
            alignItems: "center", // Выравнивание по вертикали
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
          />

          {rentalUserModeration && (
            <Button
              type="small"
              active={moderatorComment.length > 0}
              label={
                rentalUserModeration === "accept"
                  ? "Accept"
                  : rentalUserModeration === "reject"
                  ? "Reject"
                  : "Ban"
              }
              color={
                rentalUserModeration === "accept"
                  ? "--color-success"
                  : rentalUserModeration === "reject"
                  ? "--color-warning"
                  : "--color-alarm"
              }
              onClick={handleApply}
            />
          )}
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowUserModerationV4;

// import React, { useEffect, useState } from "react";
// import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
// import Button from "../WidgetsProfile/Buttons/Button";
// import Textarea from "../WidgetsProfile/Textarea/Textarea";
// import { useDispatch, useSelector } from "react-redux";
// import { updateRentalPointsModerationThunkV2 } from "../../../../../reducersToolkit/thunk/moderation/updateRentalPointsModerationThunkV2";
// import { addRentalPointsModerationThunkV2 } from "../../../../../reducersToolkit/thunk/moderation/addRentalPointsModerationThunkV2";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
// import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";
// import { v4 as uuidv4 } from "uuid";

// const WindowRentalPointModeration = ({ onClose, mode }) => {
//   const dispatch = useDispatch();
//   const {
//     selectedRentalPoint,
//     brands,
//     categories,
//     loading,
//     //
//     previewNewData,
//     rootNewData,
//     tempPreviewData,
//     tempRootData,
//     tempData,
//   } = useSelector((state) => state.catalogSliceV4);

//   //   const [commentAccept, setCommentAccept] = useState("");
//   //   const [moderatorComment, setModeratorComment] = useState("");
//   //   const [commentBanned, setCommentBanned] = useState("");

//   const [rentalUserModeration, setUserStateModeration] =
//     useState(null);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [stateUser, setStateUser] = useState(null);
//   const [newData, setNewData] = useState(null);
//   const [comments, setAllComments] = useState([]);
//   const [newComment, setNewComment] = useState("");
//   const [moderatorComment, setModeratorComment] = useState("");
//   const [userId, setUserId] = useState(null);
//   console.log("WindowRentalPointModeration tempRootData", userId);

//   useEffect(() => {
//     setCurrentUser(
//       tempRootData?.rentalPoint_moderation_rootRerntalPoint
//     );
//     setStateUser(
//       tempRootData?.rentalPoint_moderation_rootRerntalPoint?.rentalPointStatus
//         ?.currentStatus || false
//     );
//     setAllComments(
//       tempRootData?.rentalPoint_moderation_rootRerntalPoint?.rentalPointStatus
//         ?.comments || false
//     );
//     setUserId(tempRootData?.user_auth_rootCurrentData?.idRootDocument || null);
//   }, [
//     tempRootData?.rentalPoint_moderation_rootRerntalPoint,
//     tempRootData?.user_auth_rootCurrentData,
//   ]);

//   //   useEffect(() => {
//   //     setCommentAccept("");
//   //     setModeratorComment("");
//   //     setCommentBanned("");
//   //   }, [rentalUserModeration]);

//   // Создаем новый комментарий и добавляем его в массив комментариев
//   useEffect(() => {
//     if (moderatorComment && rentalUserModeration && userId) {
//       const status =
//         rentalUserModeration === "accept"
//           ? "approved"
//           : rentalUserModeration === "reject"
//           ? "moderation"
//           : "banned";

//       const newComment = {
//         connent: moderatorComment,
//         status,
//         moderatorId: userId,
//         timestamp: Date.now(),
//         id: uuidv4(),
//       };

//       setAllComments((prevComments) => [...prevComments, newComment]);
//     }
//   }, [moderatorComment, rentalUserModeration, userId]);

//   // Обновляем данные rentalPointStatus при изменении комментариев или статуса
//   useEffect(() => {
//     if (rentalUserModeration) {
//       setNewData({
//         currentStatus:
//           rentalUserModeration === "accept"
//             ? "approved"
//             : rentalUserModeration === "reject"
//             ? "moderation"
//             : "banned",
//         comments: [...comments],
//       });
//     }
//   }, [rentalUserModeration, comments]);

//   /** Сохранение данных в стейт для rentalPoint */
//   const handleApply = () => {
//     dispatch(
//       setCurrentRootData({
//         rootCurrentDocumentState:
//           "rentalPoint_moderation_rootRerntalPoint.rentalPointStatus",
//         data: newData,
//       })
//     );
//     onClose();
//   };

//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           marginBottom: "72px",
//         }}
//       >
//         <ContentBox position="start">
//           {mode === "moderation" && (
//             <>
//               <SegmentedControls
//                 options={
//                   stateUser === "moderation"
//                     ? [
//                         {
//                           title: "accept",
//                           description:
//                             currentUser?.location?.cityid &&
//                             currentUser?.location?.countryId ? (
//                               <span>
//                                 By confirming the moderation of the rental
//                                 point, you make it available to all users.
//                               </span>
//                             ) : (
//                               <span>
//                                 In order to undergo moderation of the rental
//                                 point, please indicate{" "}
//                                 <a href="#ancor1">country and city</a>
//                                 finding a rental point.
//                               </span>
//                             ),
//                         },
//                         {
//                           title: "reject",
//                           description:
//                             "By rejecting the moderation, the rental point will not be available to users.",
//                         },
//                         {
//                           title: "bann",
//                           description:
//                             "By rejecting the moderation, the rental point will not be available to users.",
//                         },
//                       ].filter(Boolean)
//                     : stateUser === "approved"
//                     ? [
//                         {
//                           title: "reject",
//                           description:
//                             "By rejecting the moderation, the rental point will not be available to users.",
//                         },
//                         {
//                           title: "bann",
//                           description:
//                             "By rejecting the moderation, the rental point will not be available to users.",
//                         },
//                       ].filter(Boolean)
//                     : stateUser === "banned" &&
//                       [
//                         {
//                           title: "moderation",
//                           description:
//                             "By rejecting the moderation, the rental point will not be available to users.",
//                         },
//                         {
//                           title: "accept",
//                           description:
//                             currentUser?.location?.cityid &&
//                             currentUser?.location?.countryId ? (
//                               <span>
//                                 By confirming the moderation of the rental
//                                 point, you make it available to all users.
//                               </span>
//                             ) : (
//                               <span>
//                                 In order to undergo moderation of the rental
//                                 point, please indicate{" "}
//                                 <a href="#ancor1">country and city</a>
//                                 finding a rental point.
//                               </span>
//                             ),
//                         },
//                       ].filter(Boolean)
//                 }
//                 onSelect={
//                   stateUser === "moderation"
//                     ? "accept"
//                     : stateUser === "approved"
//                     ? "reject"
//                     : stateUser === "banned" && "moderation"
//                 }
//                 label="Rental points"
//                 selected={setUserStateModeration}
//               />

//               {rentalUserModeration && (
//                 <>
//                   {rentalUserModeration === "accept" && (
//                     <>
//                       <Textarea
//                         label="Rejection reason"
//                         maxLength={5000}
//                         description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//                         onChange={(event) =>
//                           setModeratorComment(event.target.value)
//                         }
//                         value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
//                       />
//                     </>
//                   )}

//                   {rentalUserModeration === "reject" && (
//                     <>
//                       <Textarea
//                         label="Rejection reason"
//                         maxLength={5000}
//                         description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//                         onChange={(event) =>
//                           setModeratorComment(event.target.value)
//                         }
//                         value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
//                       />
//                     </>
//                   )}

//                   {rentalUserModeration === "bann" && (
//                     <>
//                       <Textarea
//                         label="Rejection reason"
//                         maxLength={5000}
//                         description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//                         onChange={(event) =>
//                           setModeratorComment(event.target.value)
//                         }
//                         value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
//                       />
//                     </>
//                   )}
//                 </>
//               )}
//             </>
//           )}
//         </ContentBox>
//       </div>

//       <ButtonsBar>
//         <div
//           style={{
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)", // Сетка из двух колонок
//             gap: "16px", // Расстояние между кнопками
//             width: "100%",
//             alignItems: "center", // Выравнивание по вертикали
//           }}
//         >
//           <Button
//             type="small"
//             active={true}
//             label="Cancel"
//             color="--color-black"
//             onClick={onClose}
//             allert={
//               <h1
//                 style={{
//                   padding: "80px",
//                   textAlign: "center",
//                 }}
//               >
//                 Are you sure you want to leave the editing window?
//               </h1>
//             }
//           />

//           {rentalUserModeration === "accept" && (
//             <Button
//               type="small"
//               //   active={commentAccept.length >= 2}
//               active={true}
//               label={
//                 currentUser.rentalPointStatus.currentStatus ===
//                 "approved"
//                   ? "Save"
//                   : "Accept"
//               }
//               color="--color-success"
//               onClick={handleApply}
//               allert={
//                 <div
//                   style={{
//                     padding: "24px",
//                     boxSizing: "border-box",
//                     textAlign: "center",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     gap: "16px",
//                   }}
//                 >
//                   <h1>
//                     After approval of this rental point, users will be able to
//                     fulfill orders.
//                   </h1>
//                   <p className="p-light" style={{ width: "70%" }}>
//                     You can always change the status of the rental point in case
//                     of violation of any conditions or customer complaints.
//                   </p>
//                 </div>
//               }
//             />
//           )}

//           {rentalUserModeration === "reject" && (
//             <Button
//               type="small"
//               label="Reject"
//               color="--color-warning"
//               //   active={moderatorComment.length >= 2}
//               active={true}
//               onClick={handleApply}
//               allert={
//                 <div
//                   style={{
//                     padding: "24px",
//                     boxSizing: "border-box",
//                     textAlign: "center",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     gap: "16px",
//                   }}
//                 >
//                   <h1>
//                     Do you really want to reject the successful passage of the
//                     rental point with the following motivation?
//                   </h1>
//                   {/* <p className="p-light" style={{ width: "70%" }}>
//                     Motivation: &nbsp;{moderatorComment}
//                   </p> */}
//                 </div>
//               }
//             />
//           )}

//           {rentalUserModeration === "bann" && (
//             <Button
//               type="small"
//               label="Bann"
//               color="--color-alarm"
//               //   active={commentBanned.length >= 2}
//               active={true}
//               onClick={handleApply}
//               allert={
//                 <div
//                   style={{
//                     padding: "24px",
//                     boxSizing: "border-box",
//                     textAlign: "center",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     gap: "16px",
//                   }}
//                 >
//                   <h1>
//                     Do you really want to move the rental point to "bann"
//                     status?
//                   </h1>
//                   {/* <p className="p-light" style={{ width: "70%" }}>
//                     Motivation: &nbsp;{commentBanned}
//                   </p> */}
//                 </div>
//               }
//             />
//           )}
//         </div>
//       </ButtonsBar>
//     </>
//   );
// };

// export default WindowRentalPointModeration;
