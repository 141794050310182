import React from "react";

const InformBox = ({ children, style }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "var(--color-white)",
        borderRadius: "24px",
        padding: "24px",
        boxSizing: "border-box",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default InformBox;
