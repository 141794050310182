import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { setCatalogBookingDates } from "../../../../reducersToolkit/catalogSliceV2";
import ContentBox from "../../../Private/Login/Profile/WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../../../Private/Login/Profile/WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../../../Private/Login/Profile/WidgetsProfile/Buttons/Button";

const WindowCalendar = ({ onClose }) => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(0);

  const { isLoading, filters } = useSelector((state) => state.catalogSliceV4);

  useEffect(() => {
    setStartDay(filters.bookingDates.startDay || 0);
    setEndDay(filters.bookingDates.endDay || 0);
  }, [filters]);

  // Эффект для обновления dates когда defaultDateInterval изменяется
  useEffect(() => {
    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 7);
    if (startDay && endDay) {
      setDates([new Date(startDay), new Date(endDay)]);
    } else {
      setDates([startDate, endDate]);
    }
  }, [startDay, endDay]);

  const handleBookingDates = () => {
    if (dates.length >= 2) {
      dispatch(
        setCatalogBookingDates({
          startDay: dates[0].getTime(),
          endDay: dates[1].getTime(),
        })
      );
      onClose();
    } else {
      console.error("Dates array does not contain enough elements");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <DatePicker
          selected={dates[0]}
          onChange={(date) => {
            setDates(date); // вызов первой функции
            // dispath(setCurrentDateInterval(date)); // вызов второй функции
          }}
          startDate={dates[0]}
          endDate={dates[1]}
          selectsRange
          inline
          monthsShown={2}
          minDate={new Date()}
        />
      </ContentBox>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <>
            <Button
              type="small"
              color="--color-black"
              label="Cancel selections"
              //   onClick={}
              active={true}
            />
            <Button
              type="small"
              color="--color-primary"
              label="Apply"
              active={true}
              onClick={handleBookingDates}
              allert={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "48px",
                    padding: "48px",
                    textAlign: "center",
                  }}
                >
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2FlogoBikeTripster%2FbikeTripster.svg?alt=media&token=66931d08-c4f8-4754-9c10-c1d8233a2df7"
                    style={{
                      width: "48%",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <h1>What is Lorem Ipsum?</h1>
                    <p className="p-light">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                </div>
              }
            />
          </>
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowCalendar;
