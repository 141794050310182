import React, { useEffect, useState } from "react";
import AddData from "../WidgetsProfile/AddData/AddData";
import WidgetRentalPointsListV4 from "./WidgetRentalPointsListV4";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetModerationUserPreview from "./WidgetModerationUserPreview";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPointDetailModeration from "./WindowRentalPointDetailModerationV4";
import {
  setCurrentPreviewData,
  setCurrentRootData,
} from "../../../../../reducersToolkit/catalogSliceV4";
import { fetchPreviewDataV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import { subscribePreviewDocumentsThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4";
import WidgetUserModerationV4 from "./WidgetUserModerationV4";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";

const WindowUserDetailModerationV4 = ({ onClose, mode }) => {
  const dispatch = useDispatch();

  const {
    loading,
    tempRootData,
    tempPreviewData,
    rootNewData,
    previewNewData,
  } = useSelector((state) => state.catalogSliceV4);

  const [
    loading_moderation_rootUserCurrentData,
    setLoading_moderation_rootUserCurrentData,
  ] = useState(false);

  const [foundPreviewUser, setFoundPreviewUser] = useState(null);
  // const [selectedUserId, setSelectedUserId] = useState(null);

  // User Preview

  const [idPreviewDocument, setIdPreviewDocument] = useState(null);
  const [idRootDocument, setIdRootDocument] = useState(null);

  const [
    user_moderation_selectedPreviewCurrentData,
    setUser_moderation_selectedPreviewCurrentData,
  ] = useState(null);

  // User Root

  const [
    manager_selectedRootUserModeration,
    setManager_selectedRootUserModeration,
  ] = useState(null);
  const [newUserStatus, setNewUserStatus] = useState(null);
  const [newComments, setNewComments] = useState(null);
  // const [lastComment, setLastComment] = useState(null);

  //

  const [newRootData, setNewRootData] = useState({});
  const [newPreviewData, setNewPreviewData] = useState({});
  // const [rootUserData, setRootUserData] = useState({});
  // const [previewBikeData, setPreviewBikeData] = useState({});

  const [
    windowRentalPointDetailModeration,
    setWindowRentalPointDetailModeration,
  ] = useState(false);

  const [
    general_usersPreviewNotifications,
    setGeneral_usersPreviewNotifications,
  ] = useState([]);

  const [
    rentalPoints_subscribe_previewData,
    setRentalPoints_subscribe_previewData,
  ] = useState(null);

  const [renalPointState, setRenalPointState] = useState("moderation");

  /**
   *
   * Наполнение useState
   *
   */

  useEffect(() => {
    setManager_selectedRootUserModeration(
      tempRootData?.manager_selectedRootUserModeration || null
    );
    setNewUserStatus(
      tempRootData?.manager_selectedRootUserModeration?.stateUser?.status ||
        null
    );
    setNewComments(
      tempRootData?.manager_selectedRootUserModeration?.stateUser?.comments ||
        null
    );
  }, [tempRootData?.manager_selectedRootUserModeration]);

  useEffect(() => {
    setIdPreviewDocument(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.idPreviewDocument || null
    );

    setIdRootDocument(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.idRootDocument || null
    );

    setUser_moderation_selectedPreviewCurrentData(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData || null
    );

    setGeneral_usersPreviewNotifications(
      tempPreviewData?.general_usersPreviewNotifications || null
    );

    setRentalPoints_subscribe_previewData(
      tempPreviewData?.rentalPoints_subscribe_previewData || null
    );
  }, [
    tempPreviewData?.rentalPoints_subscribe_previewData,
    tempPreviewData.rentalPoint_manager_rentalPointsPrevirew_dataList,
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
    tempPreviewData?.general_usersPreviewNotifications,
  ]);

  useEffect(() => {
    //Выполнеяется поиск по general_usersPreviewNotifications т.к это данные подписки которые обновляются динамически
    const foundPreviewUser = general_usersPreviewNotifications.find(
      (user) => user.idRootDocument === idRootDocument
    );
    setFoundPreviewUser(foundPreviewUser);
  }, [general_usersPreviewNotifications, idRootDocument]);

  useEffect(() => {
    setLoading_moderation_rootUserCurrentData(
      loading?.loading_moderation_rootUserCurrentData || false
    );
  }, [loading?.loading_moderation_rootUserCurrentData]);

  /**
   *
   * Fetch функции
   *
   */

  useEffect(() => {
    if (idRootDocument) {
      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: idRootDocument,
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "manager_selectedRootUserModeration",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_selectedUserModeration",
          errorStateName: "currentError",
        })
      );

      // dispatch(
      //   fetchPreviewDataV4({
      //     // Путь до коллекции указывается из НЕ четного колличества вложенностей
      //     previewCollectionPath: `manager_rentalPointsPrevirew.userIds.${idRootDocument}`,
      //     //
      //     loadingStateName: "loading_manager_rentalPointsPrevirew",
      //     errorStateName: "currentError",
      //     // Сохраняет с state МАССИВ объектов как результат выполнения thunk
      //     previewCurrentDocumenState:
      //       "rentalPoint_manager_rentalPointsPrevirew_dataList",
      //   })
      // );
    }
  }, [dispatch, idRootDocument]);

  useEffect(() => {
    if (idRootDocument) {
      let unsubscribeRentalPointsPrevirew;

      const subscribeAndDispatch = async () => {
        const result = await dispatch(
          subscribePreviewDocumentsThunkV4({
            collectionPath: `manager_rentalPointsPrevirew.userIds.${idRootDocument}`,
            previewCurrentDocumenState: "rentalPoints_subscribe_previewData",
            excludedIds: ["metadata"],
            loadingStateName: "loading_subscribe_previewData",
            errorStateName: "currentError",
          })
        );

        // Проверяем, что функция отписки определена
        unsubscribeRentalPointsPrevirew = result.payload?.unsubscribe;
        if (typeof unsubscribeRentalPointsPrevirew === "function") {
          console.log("Функция отписки установлена успешно");
        } else {
          console.error("Не удалось получить функцию отписки");
        }
      };

      subscribeAndDispatch();

      // Логика для отписки при размонтировании
      return () => {
        if (typeof unsubscribeRentalPointsPrevirew === "function") {
          unsubscribeRentalPointsPrevirew();
          console.log("Отписка выполнена успешно");
        } else {
          console.error("Функция отписки была undefined");
        }

        dispatch(
          setCurrentPreviewData({
            currentPreviewDocumenState: "rentalPoints_subscribe_previewData",
            data: null,
          })
        );
      };
    }
  }, [dispatch, idRootDocument]);

  /**
   *
   * Формирование объектов Root и Preview
   *
   */
  useEffect(() => {
    setNewRootData({
      ...manager_selectedRootUserModeration,
      stateUser: {
        status: newUserStatus,
        comments: newComments,
      },
    });
  }, [manager_selectedRootUserModeration, newUserStatus, newComments]);

  useEffect(() => {
    setNewPreviewData({
      ...user_moderation_selectedPreviewCurrentData,
      status: newUserStatus,
    });
  }, [user_moderation_selectedPreviewCurrentData, newUserStatus]);

  /**
   *
   * CRUD функции
   *
   */

  const createRentalPoint = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "rentalPoint_create_createRerntalPoint",
        data: rootNewData.rootRentalPointData,
      })
    );
  };

  const updateUser = async () => {
    try {
      if (mode === "moderation") {
        if (idRootDocument) {
          dispatch(
            updateDataThunkV4({
              // Указываем id preview документа который будем менять
              idPreviewDocument: idPreviewDocument,
              // Указываем id root Элемента
              idRootDocument: idRootDocument,
              // Данные для обновления, как правило это объект схожий по структуре с документом в firestore

              rootData: newRootData,
              previewData: newPreviewData,

              // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
              // newFiles: {
              //   iconFiles: newPhotoFiles,
              //   /* Другие массивы файлов. Массивов файлов может быть несколько*/
              // },
              // Имя того массива файлов который будет использоваться для назначения иконки
              // iconFields: "iconFiles",
              // Путь до коллекции указывается из НЕ четного колличества вложенностей
              rootCollectionPath: "users",
              previewCollectionPath: "usersPreview",
              // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
              metadataDocumentPath: "usersPreview.metadata",
              /*************/
              // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
              rootCurrentDocumentState: "manager_selectedRootUserModeration",
              // Сохраняет в state МАССИВ объектов как результат выполнения thunk
              // previewCurrentDocumenState: "",
              // Эти состояния будут отображать состояния загрузки и ошибки
              /*************/
              loadingStateName: "loading_updateUserData",
              errorStateName: "currentError",
            })
          );
          onClose();
        }
      }
    } catch (error) {
      console.error("Ошибка при создании или обновлении страны:", error);
      onClose();
    }
  };

  /**
   *
   * Логика компонента
   *
   */

  const handleCreateRentalPoint = () => {
    createRentalPoint();
    setWindowRentalPointDetailModeration(true);
  };

  const handleUpdateUser = () => {
    updateUser();
    onClose();
  };

  const handleClose = () => {
    setWindowRentalPointDetailModeration(false);
  };
  return (
    <>
      {/* POPUP WINDOW */}

      <PopupDirrectory
        isOpen={windowRentalPointDetailModeration}
        onClose={handleClose}
        title="Create rental point moderation"
      >
        <WindowRentalPointDetailModeration
          mode="moderatorAdded"
          onClose={handleClose}
        />
      </PopupDirrectory>

      {/* POPUP WINDOW */}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          {/* -------------  User Preview ------------- */}
          <ContentBox
            position="one"
            label="User Preview"
            isLoading={loading_moderation_rootUserCurrentData}
          >
            <WidgetModerationUserPreview userPreview={foundPreviewUser} />
          </ContentBox>

          {/* ------------- Moderation ------------- */}
          <ContentBox position="start" label="Moderation">
            <WidgetUserModerationV4
              mode="moderation"
              currentUser={manager_selectedRootUserModeration}
              comments={newComments}
            />
          </ContentBox>
        </div>

        {/* ------------- Rental Points List ------------- */}
        <ContentBox label="Rental Points List">
          <AddData
            onClick={handleCreateRentalPoint}
            icon={<span className="material-symbols-outlined">add</span>}
            title="Create rental point"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          {rentalPoints_subscribe_previewData?.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <SegmentedControls
                options={["moderation", "activated", "deactivated"]}
                onSelect="moderation"
                selected={setRenalPointState}
              />
              <WidgetRentalPointsListV4
                renalPointState={renalPointState}
                rentalPointsList={rentalPoints_subscribe_previewData}
                mode="moderation"
              />
            </div>
          )}
        </ContentBox>
        {/* ------------- Rental Points Moderator Messages ------------- */}
        {/* <ContentBox>
          <WidgetModeratorMessages />
        </ContentBox> */}
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)", // Сетка из двух колонок
            gap: "16px", // Расстояние между кнопками
            width: "100%",
            alignItems: "center", // Выравнивание по вертикали
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
          />

          <Button
            type="small"
            active={true}
            label="Apply"
            onClick={handleUpdateUser}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowUserDetailModerationV4;
