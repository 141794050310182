import React from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";

const WindowOrderDateIntervalV4 = ({ onClose }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <h1>Dates</h1>
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={onClose}
            color="--color-black"
          />

          <Button
            type="small"
            label="Aplay"
            active={true}
            // onClick={() => handleCreateOrUpdateData()}
            // allert={
            //   <WidgetAlert
            //     title={`Are you sure you want to add the page to the directory?`}
            //   />
            // }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderDateIntervalV4;
