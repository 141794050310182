import React from "react";
import TitleBlockSection from "../WidgetsProfile/Titles/TitleBlockSection";

const WidgetOffer = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "48px",
      }}
    >
      <TitleBlockSection
        title="Offer"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien, ac rhoncus justo magna non turpis. Sed tristique odio eu magna convallis interdum. Pellentesque vitae tortor est."
      />
      <div className="offer-content-item">
        <p className="section-number p-light">1.</p>
        <p className="section-title p-light">Identification of the Parties:</p>
        <p className="section-description p-light">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien,
          ac rhoncus justo magna non turpis. Sed tristique odio eu magna
          convallis interdum. Pellentesque vitae tortor est. Ut at ligula vel
          nunc bibendum condimentum id et arcu. Aenean vehicula augue non nibh
          sollicitudin, at blandit est commodo. Proin faucibus, nunc nec dictum
          viverra, ipsum orci gravida purus, in pharetra justo ipsum nec risus.
          Morbi id est nec elit convallis interdum. Quisque sollicitudin justo
          sed diam vehicula hendrerit. Nullam eu risus volutpat, tincidunt enim
          nec, viverra nunc. Duis ut tristique lorem. Proin eget mauris ut velit
          commodo aliquam. Curabitur lacinia vel orci sed pharetra. Nulla nec ex
          dolor. Nullam scelerisque euismod felis, nec varius mi fringilla id.
        </p>

        <p className="definition p-light">Agent:</p>
        <p className="definition-description p-light">
          SIA MDA Capital Account: LV02PARX0032201580001 AS "Citadele banka"
          Republikas laukums 2A Riga, LV-1010, Latvia SWIFT code: PARXLV22
          EMAIL:info@biketripster.com, PHONE: 40203555120
        </p>
        <p className="definition p-light">Rental Service:</p>
        <p className="definition-description p-light">
          Any physical or legal entity renting out Transport Vehicles to
          Clients.
        </p>
        <p className="definition p-light">Client:</p>
        <p className="definition-description p-light">
          Any physical or legal entity expressing the intention to rent
          Transport Vehicles through the "Bike For Your Trip" Service.
        </p>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">2.</p>
        <p className="section-title p-light">Definitions:</p>

        <p className="section-description p-light">
          In addition to the terms defined anywhere in this agreement, the
          following definitions and their forms are used in this agreement
          unless explicitly stated otherwise:
        </p>

        <p className="definition p-light">Agent:</p>
        <p className="definition-description p-light">
          SIA MDA Capital Account: LV02PARX0032201580001 AS "Citadele banka"
          Republikas laukums 2A Riga, LV-1010, Latvia SWIFT code: PARXLV22
          EMAIL: info@biketripster.com, PHONE: 40203555120
        </p>

        <p className="definition p-light">Bike For Your Trip" Service:</p>
        <p className="definition-description p-light">
          Websites, mobile applications, and other digital platforms through
          which the Agent provides Booking services.
        </p>

        <p className="definition p-light">Rental Service:</p>
        <p className="definition-description p-light">
          Any individual or legal entity renting Vehicles to Clients.
        </p>

        <p className="definition p-light">Client:</p>
        <p className="definition-description p-light">
          Any individual or legal entity expressing the intention to rent
          Vehicles through the "Bike For Your Trip" Service.
        </p>

        <p className="definition p-light">Vehicle:</p>
        <p className="definition-description p-light">
          Under this Agency Agreement, Vehicles refer to motorcycles, quad
          bikes, bicycles, scooters, and other vehicles available for rent
          through the Rental Service.
        </p>

        <p className="definition p-light">Rental Period:</p>
        <p className="definition-description p-light">
          The number of days or hours for which a Vehicle is rented.
        </p>

        <p className="definition p-light">Rate:</p>
        <p className="definition-description p-light">
          The price for renting a specific Vehicle for a specific period.
        </p>

        <p className="definition p-light">Rental Cost:</p>
        <p className="definition-description p-light">
          The final cost of Vehicle rental services for the Client, including
          the rate for renting a specific Vehicle for a specific number of days,
          including all taxes and other government fees.
        </p>

        <p className="definition p-light">Booking:</p>
        <p className="definition-description p-light">
          The provision by the Agent of booking services for the rental of
          vehicles on behalf of Clients.
        </p>

        <p className="definition p-light">Additional Services:</p>
        <p className="definition-description p-light">
          Services related to the rental of Vehicles, including additional
          equipment, accessories, and more.
        </p>

        <p className="definition p-light">Advance Payment:</p>
        <p className="definition-description p-light">
          A certain percentage of the Rental Cost and the cost of Additional
          Services that "Bike For Your Trip" Service charges the Client at the
          time of booking. The Advance Payment is based on the full Rental Cost
          and the cost of Additional Services.
        </p>

        <p className="definition p-light">Rental Service Terms:</p>
        <p className="definition-description p-light">
          Terms of renting a Vehicle, which may include age criteria, type or
          category of driver's license, driver's experience criteria, the need
          for insurance, additional taxes, fees or charges, the need for and
          amount of the deposit, and more.
        </p>

        <p className="definition p-light">Product Card:</p>
        <p className="definition-description p-light">
          An electronic document containing detailed information about each
          Vehicle available for rent through the "Bike For Your Trip" Service.
        </p>

        <p className="definition p-light">Reservation:</p>
        <p className="definition-description p-light">
          Confirmation and guarantee by the Rental Service of the provision of
          its services, namely: providing a Vehicle for rent and providing
          Additional Services to the Client during the Rental Period.
        </p>

        <p className="definition p-light">Parties:</p>
        <p className="definition-description p-light">
          Refers collectively to the Agent and the Rental Service.
        </p>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">3.</p>
        <p className="section-title p-light">Subject of the Agreement:</p>
        <p className="section-description p-light">
          This Agency Agreement defines the terms of collaboration for the Agent
          to provide Booking services for the rental of Vehicles on behalf of
          Clients. Specifically:
        </p>

        <p className="item-number p-light">3.1.</p>
        <p className="item-description p-light">
          The Agent undertakes to carry out Booking of Vehicles on behalf of
          Clients using the "Bike For Your Trip" Service.
        </p>

        <p className="item-number p-light">3.2.</p>
        <p className="item-description p-light">
          The Agent agrees to provide Clients with full information about the
          rental conditions, Vehicles, Rates, and other essential details
          communicated to the Agent by the Rental Service through the "Bike For
          Your Trip" Service.
        </p>

        <p className="item-number p-light">3.3.</p>
        <p className="item-description p-light">
          In exchange for the services provided, the Agent is entitled to charge
          a commission from the Rental Cost and the cost of Additional Services
          in accordance with the terms set forth in this agreement.
        </p>

        <p className="item-number p-light">3.4.</p>
        <p className="item-description p-light">
          The English-language version of this Agreement shall be considered the
          original. In the event of discrepancies or any inconsistencies between
          this Agreement in English and in any other languages, the terms of
          this Agreement in English shall prevail unless local legislation
          requires otherwise.
        </p>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">4.</p>
        <p className="section-title p-light">Agent's Powers:</p>
        <p className="section-description p-light">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien,
          ac rhoncus justo magna non turpis. Sed tristique odio eu magna
          convallis interdum. Pellentesque vitae tortor est. Ut at ligula vel
          nunc bibendum condimentum id et arcu. Aenean vehicula augue non nibh
          sollicitudin, at blandit est commodo. Proin faucibus, nunc nec dictum
          viverra, ipsum orci gravida purus, in pharetra justo ipsum nec risus.
          Morbi id est nec elit convallis interdum. Quisque sollicitudin justo
          sed diam vehicula hendrerit. Nullam eu risus volutpat, tincidunt enim
          nec, viverra nunc. Duis ut tristique lorem. Proin eget mauris ut velit
          commodo aliquam. Curabitur lacinia vel orci sed pharetra. Nulla nec ex
          dolor. Nullam scelerisque euismod felis, nec varius mi fringilla id.
        </p>

        <p className="item-number p-light">4.1.</p>
        <p className="item-description p-light">
          The Agent is authorized to negotiate and conclude agreements for
          Booking Vehicles on behalf of the Client with the Rental Service.
        </p>

        <p className="item-number p-light">4.2.</p>
        <p className="item-description p-light">
          The Agent is entitled to sign agreements and other documents necessary
          for the Booking with various rental service providers.
        </p>

        <p className="item-number p-light">4.3.</p>
        <p className="item-description p-light">
          The Agent may make changes to Bookings and carry out cancellation
          procedures in accordance with the terms set by the Rental Service.
        </p>

        <p className="item-number p-light">4.4.</p>
        <p className="item-description p-light">
          The Agent is authorized to communicate with the Client regarding
          various aspects of Booking and the provision of Vehicles.
        </p>

        <p className="item-number p-light">4.5.</p>
        <p className="item-description p-light">
          The Agent is entitled to receive confirmations from the Rental Service
          regarding the execution of Reservations, including confirmation of the
          cost and rental conditions.
        </p>

        <p className="item-number p-light">4.6.</p>
        <p className="item-description p-light">
          The Agent has the right to charge Clients for the services provided
          for Booking and additional services.
        </p>

        <p className="item-number p-light">4.7.</p>
        <p className="item-description p-light">
          The Agent reserves the right to edit or exclude any information
          provided by the Rental Service that is incorrect, incomplete, or
          violates the terms of this agreement or legislation.
        </p>

        <p className="item-number p-light">4.8.</p>
        <p className="item-description p-light">
          The Agent reserves the right to translate and publish information
          provided by the Rental Service into other languages. Translations are
          considered the exclusive property of the Agent.
        </p>

        <p className="item-number p-light">4.9.</p>
        <p className="item-description p-light">
          The Agent reserves the right to re-moderate information provided by
          the Rental Service about itself or the Vehicle, as well as the right
          to refuse publication if the information contradicts the terms of this
          agreement, is inaccurate, or intentionally misleads the Client.
        </p>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">5.</p>
        <p className="section-title p-light">Powers of the Rental Service</p>
        <p className="section-description p-light">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien,
          ac rhoncus justo magna non turpis. Sed tristique odio eu magna
          convallis interdum. Pellentesque vitae tortor est. Ut at ligula vel
          nunc bibendum condimentum id et arcu. Aenean vehicula augue non nibh
          sollicitudin, at blandit est commodo. Proin faucibus, nunc nec dictum
          viverra, ipsum orci gravida purus, in pharetra justo ipsum nec risus.
          Morbi id est nec elit convallis interdum. Quisque sollicitudin justo
          sed diam vehicula hendrerit. Nullam eu risus volutpat, tincidunt enim
          nec, viverra nunc. Duis ut tristique lorem. Proin eget mauris ut velit
          commodo aliquam. Curabitur lacinia vel orci sed pharetra. Nulla nec ex
          dolor. Nullam scelerisque euismod felis, nec varius mi fringilla id.
        </p>

        <p className="item-number p-light">5.1.</p>
        <p className="item-description p-light">
          The Rental Service undertakes to provide the Agent with all necessary
          information about available Vehicles.
        </p>

        <p className="item-number p-light">5.2.</p>
        <p className="item-description p-light">
          Information about each Vehicle must include:
        </p>
        <ul className="unordered-list">
          <li>
            <p className="item-description p-light">Vehicle details;</p>
          </li>
          <li>
            <p className="item-description p-light">
              Actual photographs of the Vehicle;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Specifications of the Vehicle;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Accurate description of the Vehicle;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Additional services available for booking;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Detailed description of prices and Rates;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              All applicable taxes, fees, and charges;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Availability or unavailability for booking on specific dates.
            </p>
          </li>
        </ul>

        <p className="item-number p-light">5.3.</p>
        <p className="item-description p-light">
          Information about the Vehicle must not include:
        </p>
        <ul className="unordered-list">
          <li>
            <p className="item-description p-light">Phone numbers or faxes;</p>
          </li>
          <li>
            <p className="item-description p-light">Email addresses;</p>
          </li>
          <li>
            <p className="item-description p-light">
              Skype and other messengers or electronic addresses;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Links to social networks (Twitter, Facebook, etc.);
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Links to the Rental Service's website;
            </p>
          </li>
          <li>
            <p className="item-description p-light">Any similar information.</p>
          </li>
        </ul>

        <p className="item-number p-light">5.4.</p>
        <p className="item-description p-light">
          The Vehicle name must adhere to the following requirements:
        </p>
        <ul className="unordered-list">
          <li>
            <p className="item-description p-light">
              The name of the Vehicle should not use words such as "or,"
              "similar," and their synonyms and derivatives;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              The model of the Vehicle must be accurately and unequivocally
              stated.
            </p>
          </li>
        </ul>

        <p className="item-number p-light">5.5.</p>
        <p className="item-description p-light">
          Separate Product cards must be created for each similar model of
          Vehicles.
        </p>

        <p className="item-number p-light">5.6.</p>
        <p className="item-description p-light">
          The Rental Service terms must be posted by the Rental Service on "Bike
          For Your Trip" Service. The Rental Service terms must be accurate and
          comprehensive.
        </p>

        <p className="item-number p-light">5.7.</p>
        <p className="item-description p-light">
          The Rental Service guarantees the completeness, timeliness, and
          accuracy of the provided information, including the ability to rent
          Vehicles at specific times and the provision of Additional Services
          during those times.
        </p>

        <p className="item-number p-light">5.8.</p>
        <p className="item-description p-light">
          The Rental Service agrees to provide the Agent on the “Bike For Your
          Trip” Service with the same or lower Rates for the same Vehicle, on
          the same dates, with the same or better Rental Service terms, with the
          same or better Additional Services, with the same or better other
          terms and rules as those provided by the Rental Service on:
        </p>
        <ul className="unordered-list">
          <li>
            <p className="item-description p-light">Rental Service websites;</p>
          </li>
          <li>
            <p className="item-description p-light">
              Social networks of the Rental Service;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Rental Service applications or;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Phone centers, including reservation and customer support
              services, or;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              Directly at the Rental Service officel;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              As well as any Service “Bike For Your Trip” competitors, including
              any agency or intermediary involved in online or offline booking;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              And/or any other third party affiliated with the Rental Service in
              a business partnership or otherwise.
            </p>
          </li>
        </ul>

        <p className="item-number p-light">5.9.</p>
        <p className="item-description p-light">
          The Rental Service must publish information about Vehicles available
          for booking on "Bike For Your Trip" Service that is at least as
          comprehensive as that provided to any Service "Bike For Your Trip"
          competitors, including any online or offline booking, travel agency,
          or intermediary and/or any other third party affiliated with the
          Rental Service or otherwise connected to it.
        </p>

        <p className="item-number p-light">5.10.</p>
        <p className="item-description p-light">
          Restrictions and conditions, including Rental Costs, applicable to
          Vehicles whose information is posted on "Bike For Your Trip" Service,
          must at all times comply with the terms of this agreement and be
          understandable to all participating parties, including Clients.
        </p>

        <p className="item-number p-light">5.11.</p>
        <p className="item-description p-light">
          The Rental Service agrees to provide the Agent with equal access to
          all Vehicles and Rates of the Rental Service available for booking on
          the Rental Service website or third-party websites throughout the term
          of this agreement, both during low and high demand seasons.
        </p>

        <p className="item-number p-light">5.12.</p>
        <p className="item-description p-light">
          The information provided in the Rental Service terms must not
          contradict the terms of this agreement and the legislation of the
          country where the Vehicle rental takes place.
        </p>

        <p className="item-number p-light">5.13.</p>
        <p className="item-description p-light">
          The information provided in the Rental Service terms must adhere to
          the formats and standards set by the Agent.
        </p>

        <p className="item-number p-light">5.14.</p>
        <p className="item-description p-light">
          The Rental Service undertakes to independently update and refresh the
          provided information.
        </p>

        <p className="item-number p-light">5.15.</p>
        <p className="item-description p-light">
          The Rental Service is responsible for the accuracy and timeliness of
          the provided information.
        </p>

        <p className="item-number p-light">5.16.</p>
        <p className="item-description p-light">
          The information provided by the Rental Service for "Bike For Your
          Trip" Service remains the exclusive property of the Rental Service.
        </p>

        <p className="item-number p-light">5.17.</p>
        <p className="item-description p-light">
          Edited and translated texts are for the exclusive use of the Agent on
          "Bike For Your Trip" Service and should not be used in any form or
          manner by the Rental Service for other purposes and channels of
          distribution or sale.
        </p>

        <p className="item-number p-light">5.18.</p>
        <p className="item-description p-light">
          The Rental Service may edit the names or descriptive information about
          itself or the Vehicle.
        </p>

        <p className="item-number p-light">5.19.</p>
        <p className="item-description p-light">
          The Rental Service agrees to confirm Reservations of Vehicles made
          through the Agent by providing detailed details of the bookings.
        </p>

        <p className="item-number p-light">5.20.</p>
        <p className="item-description p-light">
          The Rental Service consents to the Agent interacting with Clients,
          providing them with necessary support and information.
        </p>

        <p className="item-number p-light">5.21.</p>
        <p className="item-description p-light">
          The Rental Service has the right to notify the Agent of changes in the
          conditions of Vehicle rental through "Bike For Your Trip" Service and
          coordinate these changes within the framework of applicable law.
        </p>

        <p className="item-number p-light">5.22.</p>
        <p className="item-description p-light">
          The Rental Service authorizes the Agent to resolve technical and
          organizational issues related to Booking Vehicles.
        </p>

        <p className="item-number p-light">5.23.</p>
        <p className="item-description p-light">
          The Rental Service undertakes to comply with all applicable laws and
          standards in the field of Vehicle rental in the country where the
          Vehicle rental takes place.
        </p>

        <p className="item-number p-light">5.24.</p>
        <p className="item-description p-light">
          The Rental Service agrees to pay a commission in accordance with the
          terms set forth in this agreement for successful Bookings made through
          the Agent.
        </p>

        <p className="item-number p-light">5.25.</p>
        <p className="item-description p-light">
          The Rental Service is obliged to include all taxes and fees in the
          Rate, including VAT, sales tax, and other similar state, governmental,
          regional, municipal, or local taxes, fees, and charges, provided that
          such taxes, fees, and charges can be calculated in advance without
          specifying detailed information.
        </p>

        <p className="item-number p-light">5.26.</p>
        <p className="item-description p-light">
          The Rental Service is responsible for paying all applicable taxes and
          fees related to the payment for Vehicle rental services in accordance
          with the legislation of the country where the Rental Service operates.
        </p>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">6.</p>
        <p className="section-title p-light">Commission Fee</p>
        <p className="section-description p-light">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien,
          ac rhoncus justo magna non turpis. Sed tristique odio eu magna
          convallis interdum. Pellentesque vitae tortor est. Ut at ligula vel
          nunc bibendum condimentum id et arcu. Aenean vehicula augue non nibh
          sollicitudin, at blandit est commodo. Proin faucibus, nunc nec dictum
          viverra, ipsum orci gravida purus, in pharetra justo ipsum nec risus.
          Morbi id est nec elit convallis interdum. Quisque sollicitudin justo
          sed diam vehicula hendrerit. Nullam eu risus volutpat, tincidunt enim
          nec, viverra nunc. Duis ut tristique lorem. Proin eget mauris ut velit
          commodo aliquam. Curabitur lacinia vel orci sed pharetra. Nulla nec ex
          dolor. Nullam scelerisque euismod felis, nec varius mi fringilla id.
        </p>

        <p className="item-number p-light">6.1.</p>
        <p className="item-description p-light">
          For providing services related to successful Booking of Vehicles on
          behalf of Clients, the Agent is entitled to charge a commission.
        </p>

        <p className="item-number p-light">6.2.</p>
        <p className="item-description p-light">
          The Commission rate is 15% of the Rental Cost and Additional Services,
          equal to the amount of the prepayment made by the Client during
          Booking.
        </p>

        <p className="item-number p-light">6.3.</p>
        <p className="item-description p-light">
          The Agent reserves the right to offer or not offer a discount to the
          Client up to 15% of the Rental Cost and Additional Services.
        </p>

        <p className="item-number p-light">6.4.</p>
        <p className="item-description p-light">
          The Commission is deducted from the total Rental Cost and the cost of
          Additional Services and can be settled with the Agent through offset.
        </p>

        <p className="item-number p-light">6.5.</p>
        <p className="item-description p-light">
          Any changes to the commission rates can only be made with mutual
          agreement between the parties.
        </p>

        <p className="item-number p-light">6.6.</p>
        <p className="item-description p-light">
          There is no provision for refunding the commission to the Rental
          Service.
        </p>

        <p className="item-number p-light">6.7.</p>
        <p className="item-description p-light">
          The Agent is responsible for paying all applicable taxes and fees
          associated with commission collection in accordance with the
          legislation of the country where the activity takes place.
        </p>

        <p className="item-number p-light">6.8.</p>
        <p className="item-description p-light">Commission payment occurs:</p>
        <ul className="unordered-list">
          <li>
            <p className="item-description p-light">
              After the Client makes a prepayment for the rental of the Vehicle
              and Additional Services at the time of confirming the Reservation
              by the Rental Service, or;
            </p>
          </li>
          <li>
            <p className="item-description p-light">
              After confirming the Reservation by the Rental Service at the time
              when the Client makes a prepayment for the rental of the Vehicle
              and Additional Services.
            </p>
          </li>
        </ul>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">7.</p>
        <p className="section-title p-light">Duration of the Agreement</p>
        <p className="section-description p-light">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien,
          ac rhoncus justo magna non turpis. Sed tristique odio eu magna
          convallis interdum. Pellentesque vitae tortor est. Ut at ligula vel
          nunc bibendum condimentum id et arcu. Aenean vehicula augue non nibh
          sollicitudin, at blandit est commodo. Proin faucibus, nunc nec dictum
          viverra, ipsum orci gravida purus, in pharetra justo ipsum nec risus.
          Morbi id est nec elit convallis interdum. Quisque sollicitudin justo
          sed diam vehicula hendrerit. Nullam eu risus volutpat, tincidunt enim
          nec, viverra nunc. Duis ut tristique lorem. Proin eget mauris ut velit
          commodo aliquam. Curabitur lacinia vel orci sed pharetra. Nulla nec ex
          dolor. Nullam scelerisque euismod felis, nec varius mi fringilla id.
        </p>

        <p className="item-number p-light">7.1.</p>
        <p className="item-description p-light">
          This agreement is concluded for a period of one year from the moment
          the Rental Service places any information regarding its location,
          name, contact details, Rental Service Terms, the availability,
          characteristics, descriptions, and Tariffs of its Vehicles, and other
          information.
        </p>

        <p className="item-number p-light">7.2.</p>
        <p className="item-description p-light">
          Upon expiration, this agreement automatically extends for an
          additional year if neither party has expressed a written desire to
          terminate it, and none of the conditions of this agreement have been
          violated by either party.
        </p>

        <p className="item-number p-light">7.3.</p>
        <p className="item-description p-light">
          The agreement may be terminated prematurely by mutual agreement of the
          parties.
        </p>

        <p className="item-number p-light">7.4.</p>
        <p className="item-description p-light">
          In the event of a decision to terminate this agreement, the party
          wishing to terminate it undertakes to notify the other party in
          writing no less than 30 (thirty) days before the anticipated
          termination date.
        </p>

        <p className="item-number p-light">7.5.</p>
        <p className="item-description p-light">
          The parties undertake to comply with all the terms of this agreement
          during its duration and any extensions if applicable.
        </p>

        <p className="item-number p-light">7.6.</p>
        <p className="item-description p-light">
          In the case of active Bookings, meaning if there are ongoing requests
          or reservations from Clients at the time the party expresses a desire
          to terminate the agreement, this agreement cannot be terminated until
          their completion.
        </p>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">8.</p>
        <p className="section-title p-light">Monopoly</p>
        <p className="section-description p-light">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien,
          ac rhoncus justo magna non turpis. Sed tristique odio eu magna
          convallis interdum. Pellentesque vitae tortor est. Ut at ligula vel
          nunc bibendum condimentum id et arcu. Aenean vehicula augue non nibh
          sollicitudin, at blandit est commodo. Proin faucibus, nunc nec dictum
          viverra, ipsum orci gravida purus, in pharetra justo ipsum nec risus.
          Morbi id est nec elit convallis interdum. Quisque sollicitudin justo
          sed diam vehicula hendrerit. Nullam eu risus volutpat, tincidunt enim
          nec, viverra nunc. Duis ut tristique lorem. Proin eget mauris ut velit
          commodo aliquam. Curabitur lacinia vel orci sed pharetra. Nulla nec ex
          dolor. Nullam scelerisque euismod felis, nec varius mi fringilla id.
        </p>

        <p className="item-number p-light">8.1.</p>
        <p className="item-description p-light">
          This agreement is not exclusive in this area.
        </p>

        <p className="item-number p-light">8.2.</p>
        <p className="item-description p-light">
          The Agent may enter into additional agency agreements with other
          parties if deemed necessary to enhance the sales opportunities for
          their services.
        </p>

        <p className="item-number p-light">8.3.</p>
        <p className="item-description p-light">
          The Rental Service may also enter into other agency agreements with
          other parties if deemed necessary to enhance the sales opportunities
          for its services.
        </p>
      </div>

      <div className="offer-content-item">
        <p className="section-number p-light">9.</p>
        <p className="section-title p-light">Liability of the Parties</p>
        <p className="section-description p-light">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien,
          ac rhoncus justo magna non turpis. Sed tristique odio eu magna
          convallis interdum. Pellentesque vitae tortor est. Ut at ligula vel
          nunc bibendum condimentum id et arcu. Aenean vehicula augue non nibh
          sollicitudin, at blandit est commodo. Proin faucibus, nunc nec dictum
          viverra, ipsum orci gravida purus, in pharetra justo ipsum nec risus.
          Morbi id est nec elit convallis interdum. Quisque sollicitudin justo
          sed diam vehicula hendrerit. Nullam eu risus volutpat, tincidunt enim
          nec, viverra nunc. Duis ut tristique lorem. Proin eget mauris ut velit
          commodo aliquam. Curabitur lacinia vel orci sed pharetra. Nulla nec ex
          dolor. Nullam scelerisque euismod felis, nec varius mi fringilla id.
        </p>

        <p className="section-subnumber p-light"> 9.1.</p>
        <p className="section-subtitle p-light"> Agent's Liability:</p>

        <p className="item-number p-light">9.1.1.</p>
        <p className="item-description p-light">
          This agreement is not exclusive in this area.The Agent undertakes to
          perform its duties under this agreement, including Booking of Vehicles
          and providing Clients with necessary information provided by the
          Rental Service, diligently and effectively.
        </p>

        <p className="item-number p-light">9.1.2.</p>
        <p className="item-description p-light">
          The Agent agrees to comply with all applicable laws and standards in
          the field of vehicle reservation and rental.
        </p>

        <p className="item-number p-light">9.1.3.</p>
        <p className="item-description p-light">
          The Agent commits to maintaining the confidentiality of information
          obtained from the Rental Service, Clients, and other parties in the
          course of performing its duties.
        </p>

        <p className="section-subnumber p-light">9.2.</p>
        <p className="section-subtitle p-light">Rental Service's Liability:</p>

        <p className="item-number p-light">9.2.1.</p>
        <p className="item-description p-light">
          The Rental Service agrees to provide the Agent with up-to-date
          information about the availability of Vehicles, their characteristics,
          Tariffs, and Rental Service Conditions.
        </p>

        <p className="item-number p-light">9.2.2.</p>
        <p className="item-description p-light">
          The Rental Service is responsible for confirming Reservations,
          complying with the Rental Service Conditions, and providing Vehicles
          that match the information provided on the "Bike For Your Trip"
          Service.
        </p>

        <p className="item-number p-light">9.2.3.</p>
        <p className="item-description p-light">
          The Rental Service agrees to provide exactly the Vehicle that the
          Client booked on the "Bike for your trip" Service, not any other
          vehicle of a similar class. In case it is impossible to provide this
          specific Vehicle, the Rental Service must notify the Client
          immediately.
        </p>

        <p className="item-number p-light">9.2.4.</p>
        <p className="item-description p-light">
          The Rental Service commits to promptly resolving technical issues
          related to Reservations and the provision of Vehicles, considering all
          legal rules and standards.
        </p>

        <p className="item-number p-light">9.2.5.</p>
        <p className="item-description p-light">
          If the Rental Service confirmed a Reservation but, for any reason,
          provided substandard rental services for the Vehicle and Additional
          Services, or did not provide rental services for the Vehicle and
          Additional Services in full, the Rental Service undertakes full
          responsibility for refunding the full or partial Rental Cost and the
          cost of Additional Services to the Client.
        </p>

        <p className="section-subnumber p-light">9.3.</p>
        <p className="section-subtitle p-light">Joint Responsibility:</p>

        <p className="item-number p-light">9.3.1.</p>
        <p className="item-description p-light">
          In case of disputes, the parties agree to engage in constructive
          negotiations to resolve issues related to the execution of this
          agreement.
        </p>

        <p className="item-number p-light">9.3.2.</p>
        <p className="item-description p-light">
          The parties jointly bear responsibility for complying with all
          conditions agreed upon in this agreement.
        </p>
      </div>
    </div>
  );
};

export default WidgetOffer;
