import React, { useEffect, useState } from "react";
import AvatarUser from "../WidgetsProfile/Avatars/AvatarUser";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
// import WindowUploadFiles from "../DirectoryV3/Directory/WindowUploadFiles";
import priorityFile from "../../../../../functions/priorityFile";
import { subscribeToDocument } from "../../../../../services/firebase/crudFirestore";
import WindowUploadFilesModeration from "../Moderation/WindowUploadFilesModeration";

const WidgetUserAvatar = ({
  currentRootUser,
  isLoading,
  fieldName,
  iconAddphoto,
  size = 80,
  mode,
  fileUrl,
}) => {
  const [windowUploadFilesModeration, setWindowUploadFilesModeration] =
    useState(false);

  // const [userAvatarUrl, setUserAvatarUrl] = useState([]);

  // useEffect(() => {
  //   const priorityFileselected = priorityFile(
  //     currentRootUser?.files?.[fieldName] || []
  //   );

  //   setUserAvatarUrl(priorityFileselected?.url || null);
  // }, [currentRootUser, fieldName]);

  const handleToggle = () => {
    setWindowUploadFilesModeration(true);
  };

  const handleClose = () => {
    setWindowUploadFilesModeration(false);
  };

  return (
    <>
      {/* POPUP WINDOW */}
      <PopupDirrectory
        isOpen={windowUploadFilesModeration}
        onClose={handleClose}
        title="Add useer avarar Moderation"
      >
        <WindowUploadFilesModeration onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOW */}

      <div
        style={{
          // width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          position: "relative",
        }}
      >
        <AvatarUser size={size} url={fileUrl} isLoading={isLoading} />
        {iconAddphoto && (
          <span
            className="material-symbols-outlined"
            onClick={handleToggle}
            style={{
              position: "absolute",
              bottom: "8px",
              cursor: "pointer",
              backgroundColor: "var(--color-white)",
              // backgroundColor: "violet",
              padding: "8px",
              borderRadius: "80px",
            }}
          >
            photo_camera
          </span>
        )}
      </div>
    </>
  );
};

export default WidgetUserAvatar;
