import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"; // Импортируем хук для отслеживания пути
import WidgetUserPreview from "./UserProfile/WidgetUserPreview";
import WidgetOrders from "./OrdersV3/WidgetOrders";
import WidgetDirectory from "./DirectoryV3/WidgetDirectory";
import WidgetRentalOffer from "./Offer/WidgetRentalOffer.js";
import WidgetRentalPointsV4 from "./RentalPointsV3/WidgetRentalPointsV4.js";
import WidgetModeration from "./Moderation/WidgetModeration.js";

const UIProfileV3 = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { loading, tempRootData, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [
    general_usersPreviewNotifications,
    setGeneral_usersPreviewNotifications,
  ] = useState(null);

  const [widgetSignInLoadingFetchUsers, setWidgetSignInLoadingFetchUsers] =
    useState(false);
  const [
    loading_admin_subscribe_previewData,
    setLoading_admin_subscribePreviewData,
  ] = useState(false);
  const [
    loading_update_auth_userRootCurrentData,
    setLoading_update_auth_userRootCurrentData,
  ] = useState(false);

  const {
    isLoading: isLoadingOrders,
    orders,
    userOrders,
  } = useSelector((state) => state.catalogSliceV4);
  const { isLoading, rentalPointsList } = useSelector(
    (state) => state.catalogSliceV4
  );

  // Обновляем локальные состояния при изменении данных в store
  useEffect(() => {
    setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
    setUserId(tempRootData?.user_auth_rootCurrentData?.id || null);
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
  }, [tempRootData.user_auth_rootCurrentData]);

  useEffect(() => {
    setGeneral_usersPreviewNotifications(
      tempPreviewData?.general_usersPreviewNotifications || null
    );
  }, [tempPreviewData.general_usersPreviewNotifications]);

  useEffect(() => {
    setWidgetSignInLoadingFetchUsers(
      loading?.loading_fetchUsers_widgetSignIn || false
    );
    setLoading_admin_subscribePreviewData(
      loading?.loading_admin_subscribe_previewData || false
    );
    setLoading_update_auth_userRootCurrentData(
      loading?.loading_update_auth_userRootCurrentData || false
    );
  }, [
    loading.loading_fetchUsers_widgetSignIn,
    loading.loading_admin_subscribe_previewData,
    loading.loading_update_auth_userRootCurrentData,
  ]);

  // Подписка на коллекцию "usersPreview" для админов
  // useEffect(() => {
  //   if (userRole === "admin") {
  //     let unsubscribe;

  //     const subscribeAndDispatch = async () => {
  //       const result = dispatch(
  //         subscribePreviewDocumentsThunkV4({
  //           collectionPath: "usersPreview",
  //           previewCurrentDocumenState: "users_admin_subscribe_previewData",
  //           excludedIds: ["metadata"],
  //           loadingStateName: "loading_admin_subscribe_previewData",
  //           errorStateName: "currentError",
  //         })
  //       );

  //       unsubscribe = result.payload?.unsubscribe; // Получаем функцию отписки
  //     };

  //     subscribeAndDispatch();

  //     // Отписка при уходе с раздела профиля
  //     return () => {
  //       if (unsubscribe && location.pathname !== "/profile") {
  //         // Проверяем, что мы уходим с раздела
  //         dispatch(
  //           setCurrentPreviewData({
  //             currentPreviewDocumenState: "users_admin_subscribe_previewData",
  //             data: null,
  //           })
  //         );
  //         console.log("Отписка от подписки на документы Firestore 100");
  //         unsubscribe(); // Отписываемся от изменений
  //       }
  //     };
  //   }

  // }, [dispatch, userRole, location.pathname]); // Добавляем зависимость от текущего пути

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        {userRole === "admin" && (
          <>
            <WidgetUserPreview
              isLoading={
                widgetSignInLoadingFetchUsers ||
                loading_update_auth_userRootCurrentData
              }
              user={user_auth_rootCurrentData}
            />

            <WidgetModeration
              usersModeration={general_usersPreviewNotifications}
            />

            <WidgetOrders orders={orders} isLoading={isLoadingOrders} />

            <WidgetDirectory />
          </>
        )}

        {userRole === "rental" && (
          <>
            <WidgetUserPreview
              isLoading={widgetSignInLoadingFetchUsers}
              user={user_auth_rootCurrentData}
            />
            <WidgetRentalPointsV4
              isLoading={isLoading}
              rentalPointsList={rentalPointsList}
            />
            <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} />
            <WidgetDirectory />
          </>
        )}

        {userRole === "client" && (
          <>
            <WidgetUserPreview
              isLoading={widgetSignInLoadingFetchUsers}
              user={user_auth_rootCurrentData}
            />
            <WidgetRentalOffer user={user_auth_rootCurrentData} />

            <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} />
          </>
        )}
      </div>
    </div>
  );
};

export default UIProfileV3;

// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import WidgetUserPreview from "./UserProfile/WidgetUserPreview";
// import WidgetOrders from "./OrdersV3/WidgetOrders";
// import WidgetDirectory from "./DirectoryV3/WidgetDirectory";
// import WidgetRentalOffer from "./Offer/WidgetRentalOffer.js";
// import WidgetRentalPointsV4 from "./RentalPointsV3/WidgetRentalPointsV4.js";
// import WidgetModeration from "./Moderation/WidgetModeration.js";
// import { subscribePreviewDocumentsThunkV4 } from "../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4.js";
// import { setCurrentPreviewData } from "../../../../reducersToolkit/catalogSliceV4.js";
// import Preloader from "../../../UIElements/Preloader.js";

// const UIProfileV3 = () => {
//   const dispatch = useDispatch();

//   const { loading, tempRootData, tempPreviewData } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
//   const [userId, setUserId] = useState(null);
//   const [userRole, setUserRole] = useState(null);
//   const [
//     users_admin_subscribe_previewData,
//     setUsers_admin_subscribe_previewData,
//   ] = useState(null);

//   const [widgetSignInLoadingFetchUsers, setWidgetSignInLoadingFetchUsers] =
//     useState(false);
//   const [
//     loading_admin_subscribe_previewData,
//     setLoading_admin_subscribePreviewData,
//   ] = useState(false);
//   const [
//     loading_update_auth_userRootCurrentData,
//     setLoading_update_auth_userRootCurrentData,
//   ] = useState(false);

//   const {
//     isLoading: isLoadingOrders,
//     orders,
//     userOrders,
//   } = useSelector((state) => state.catalogSliceV4);
//   const { isLoading, rentalPointsList } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   // Обновляем локальные состояния при изменении данных в store
//   useEffect(() => {
//     setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
//     setUserId(tempRootData?.user_auth_rootCurrentData?.id || null);
//     setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
//   }, [tempRootData.user_auth_rootCurrentData]);

//   useEffect(() => {
//     setUsers_admin_subscribe_previewData(
//       tempPreviewData?.users_admin_subscribe_previewData || null
//     );
//   }, [tempPreviewData.users_admin_subscribe_previewData]);

//   useEffect(() => {
//     setWidgetSignInLoadingFetchUsers(
//       loading?.loading_fetchUsers_widgetSignIn || false
//     );
//     setLoading_admin_subscribePreviewData(
//       loading?.loading_admin_subscribe_previewData || false
//     );
//     setLoading_update_auth_userRootCurrentData(
//       loading?.loading_update_auth_userRootCurrentData || false
//     );
//   }, [
//     loading.loading_fetchUsers_widgetSignIn,
//     loading.loading_admin_subscribe_previewData,
//     loading.loading_update_auth_userRootCurrentData,
//   ]);

//   // Подписка на коллекцию "usersPreview" для админов
//   useEffect(() => {
//     if (userRole === "admin") {
//       let unsubscribe;

//       const subscribeAndDispatch = async () => {
//         const result = dispatch(
//           subscribePreviewDocumentsThunkV4({
//             collectionPath: "usersPreview",
//             previewCurrentDocumenState: "users_admin_subscribe_previewData",
//             excludedIds: ["metadata"],
//             loadingStateName: "loading_admin_subscribe_previewData",
//             errorStateName: "currentError",
//           })
//         );

//         unsubscribe = result.payload?.unsubscribe; // Получаем функцию отписки
//       };

//       subscribeAndDispatch();

//       // Отписка при размонтировании компонента
//       return () => {
//         if (unsubscribe) {
//           dispatch(
//             setCurrentPreviewData({
//               currentPreviewDocumenState: "users_admin_subscribe_previewData",
//               data: null,
//             })
//           );
//           console.log("Отписка от подписки на документы Firestore");
//           unsubscribe(); // Отписываемся от изменений
//         }
//       };
//     }
//   }, [dispatch, userRole]);

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         width: "100%",
//         boxSizing: "border-box",
//       }}
//     >
//       <div
//         style={{
//           paddingTop: "80px",
//           maxWidth: "var(--width-mobile-content)",
//           width: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "flex-start",
//           gap: "8px",
//           boxSizing: "border-box",
//         }}
//       >
//         {userRole === "admin" && (
//           <>
//             {loading_admin_subscribe_previewData && <Preloader />}
//             <WidgetUserPreview
//               isLoading={
//                 widgetSignInLoadingFetchUsers ||
//                 loading_update_auth_userRootCurrentData
//               }
//               user={user_auth_rootCurrentData}
//             />

//             <WidgetModeration
//               isLoading={
//                 loading_admin_subscribe_previewData ||
//                 loading_update_auth_userRootCurrentData
//               }
//               usersModeration={users_admin_subscribe_previewData}
//             />

//             <WidgetOrders orders={orders} isLoading={isLoadingOrders} />

//             <WidgetDirectory />
//           </>
//         )}

//         {userRole === "rental" && (
//           <>
//             <WidgetUserPreview
//               isLoading={widgetSignInLoadingFetchUsers}
//               user={user_auth_rootCurrentData}
//             />
//             <WidgetRentalPointsV4
//               isLoading={isLoading}
//               rentalPointsList={rentalPointsList}
//             />
//             <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} />
//             <WidgetDirectory />
//           </>
//         )}

//         {userRole === "client" && (
//           <>
//             <WidgetUserPreview
//               isLoading={widgetSignInLoadingFetchUsers}
//               user={user_auth_rootCurrentData}
//             />
//             <WidgetRentalOffer user={user_auth_rootCurrentData} />
//             <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} />
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default UIProfileV3;

// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux/es/hooks/useSelector";
// import WidgetUserPreview from "./UserProfile/WidgetUserPreview";
// import WidgetOrders from "./OrdersV3/WidgetOrders";
// import WidgetDirectory from "./DirectoryV3/WidgetDirectory";
// import { useDispatch } from "react-redux";
// import WidgetRentalOffer from "./Offer/WidgetRentalOffer.js";
// import WidgetRentalPointsV4 from "./RentalPointsV3/WidgetRentalPointsV4.js";
// import WidgetModeration from "./Moderation/WidgetModeration.js";
// import { subscribePreviewDocumentsThunkV4 } from "../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4.js";
// import { setCurrentPreviewData } from "../../../../reducersToolkit/catalogSliceV4.js";

// const UIProfileV3 = () => {
//   const dispatch = useDispatch();

//   const { loading, tempRootData, tempPreviewData } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   console.log("UIProfileV3 tempPreviewData", tempRootData, tempPreviewData);

//   const [userOrdersIds, setUserOrdersIds] = useState(null);

//   const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
//   const [userId, setUserId] = useState(null);
//   const [userRole, setUserRole] = useState(null);
//   const [
//     users_admin_subscribe_previewData,
//     setUsers_admin_subscribe_previewData,
//   ] = useState(null);

//   /** LOADING */
//   const [widgetSignInLoadingFetchUsers, setWidgetSignInLoadingFetchUsers] =
//     useState(false);

//   const [
//     loading_admin_subscribe_previewData,
//     setLoading_admin_subscribePreviewData,
//   ] = useState(false);

//   const [
//     loading_update_auth_userRootCurrentData,
//     setLoading_update_auth_userRootCurrentData,
//   ] = useState(false);

//   /** */
//   const {
//     isLoading: isLoadingOrders,
//     orders,
//     userOrders,
//   } = useSelector((state) => state.catalogSliceV4);

//   const { isLoading, rentalPointsList } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   useEffect(() => {
//     setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
//     setUserId(tempRootData?.user_auth_rootCurrentData?.id || null);
//     setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
//   }, [tempRootData.user_auth_rootCurrentData]);

//   useEffect(() => {
//     setUsers_admin_subscribe_previewData(
//       tempPreviewData?.users_admin_subscribe_previewData || null
//     );
//   }, [tempPreviewData.users_admin_subscribe_previewData]);

//   useEffect(() => {
//     setWidgetSignInLoadingFetchUsers(
//       loading?.loading_fetchUsers_widgetSignIn || false
//     );
//     setLoading_admin_subscribePreviewData(
//       loading?.loading_admin_subscribe_previewData || false
//     );
//     setLoading_update_auth_userRootCurrentData(
//       loading?.loading_update_auth_userRootCurrentData || false
//     );
//   }, [
//     loading.loading_fetchUsers_widgetSignIn,
//     loading.loading_admin_subscribe_previewData,
//     loading.loading_update_auth_userRootCurrentData,
//   ]);
//   console.log(
//     "UIProfileV3 users_admin_subscribe_previewData",
//     users_admin_subscribe_previewData
//   );
//   useEffect(() => {
//     if (userRole === "admin") {
//       let unsubscribe;

//       const subscribeAndDispatch = async () => {
//         // Подписываемся на коллекцию и сохраняем функцию отписки
//         const result = dispatch(
//           subscribePreviewDocumentsThunkV4({
//             // Путь к коллекции в firestore
//             collectionPath: "usersPreview",
//             // Название стейта в который сохраняется МАССИВ
//             previewCurrentDocumenState: "users_admin_subscribe_previewData",
//             // Id документов которые будут исключены из обработки
//             excludedIds: ["metadata"],
//             // Название стейтов для отслеживания загрузки
//             loadingStateName: "loading_admin_subscribe_previewData",
//             // Название стейтов для отслеживания ошибок
//             errorStateName: "currentError",
//           })
//         );

//         // Разворачиваем результат и получаем функцию отписки
//         unsubscribe = result.payload?.unsubscribe;
//       };

//       subscribeAndDispatch();

//       // Логика для отписки при размонтировании компонента
//       return () => {
//         if (unsubscribe) {
//           dispatch(
//             setCurrentPreviewData({
//               currentPreviewDocumenState: "users_admin_subscribe_previewData",
//               data: null,
//             })
//           );
//           console.log("Отписка от подписки на документы Firestore");
//           unsubscribe();
//         }
//       };
//     }
//   }, [dispatch, userRole]);

//   useEffect(() => {
//     if (userRole === "admin") {
//       // let unsubscribe;
//       // const subscribeAndDispatch = async () => {
//       //   // Подписываемся на коллекцию и сохраняем функцию отписки
//       //   const result = await dispatch(
//       //     subscribeDocumentsThunkV4({
//       //       collectionPath: "usersPreview",
//       //       currentStatePath: "myCollection",
//       //       loadingStateName: "myLoadingState",
//       //       errorStateName: "myErrorState",
//       //     })
//       //   );
//       //   // Разворачиваем результат и получаем функцию отписки, если она была возвращена
//       //   unsubscribe = result.payload?.unsubscribe;
//       // };
//       // subscribeAndDispatch();
//       // // Логика для отписки при размонтировании компонента
//       // return () => {
//       //   if (unsubscribe) {
//       //     unsubscribe(); // Вызываем функцию отписки
//       //   }
//       // };
//       // Подписываемся на всех пользователей
//       // const unsubscribeUsers = subscribeToDocument(
//       //   "users",
//       //   null,
//       //   null,
//       //   async (data) => {
//       //     dispatch(setUsersList(data));
//       //     const user = data.find((user) => user.id === userId);
//       //     dispatch(fetchRentalPointsThunkV2(user.rentalPointsIds || []));
//       //   }
//       // );
//       // const unsubscribeRentalPoints = subscribeToDocument(
//       //   "rentalPoints",
//       //   null,
//       //   null,
//       //   async (data) => {
//       //     dispatch(setRentalPointsList(data));
//       //   }
//       // );
//       // const unsubscribeBikes = subscribeToDocument(
//       //   "bikes",
//       //   null,
//       //   null,
//       //   async (data) => {
//       //     dispatch(setBikesList(data));
//       //   }
//       // );
//       // const unsubscribeOrders = subscribeToDocument(
//       //   "orders",
//       //   null,
//       //   null,
//       //   async (data) => {
//       //     dispatch(setOrdersList(data));
//       //   }
//       // );
//       // return () => {
//       //   console.log("unsubscribe all users, bikes, rentalPoints, orders");
//       //   // unsubscribeUsers();
//       //   // unsubscribeRentalPoints();
//       //   // unsubscribeBikes();
//       //   // unsubscribeOrders();
//       // };
//     }
//   }, [userId, userOrdersIds, userRole, dispatch]);

//   // switch (currentUser.role) {
//   //   case "rental":
//   //     console.log("UIProfileV3", "rental");
//   //     break;
//   //   case "admin":
//   //     console.log("UIProfileV3", "admin");
//   //     break;
//   //   case "manager":
//   //     console.log("UIProfileV3", "manager");
//   //     break;
//   //   default:
//   //     console.log("UIProfileV3", "default");
//   // }
//   // Подписка на коллекции: users - для получкения списка id точек проката
//   // useEffect(() => {
//   //   const unsubscribe = subscribeToDocument(
//   //     "users",
//   //     userId,
//   //     null,
//   //     async (data) => {
//   //       dispatch(fetchRentalPointsThunkV2(data.rentalPointsIds));
//   //     }
//   //   );
//   //   return () => {
//   //     console.log("unsubscribe users");
//   //     unsubscribe();
//   //   };
//   // }, [userId, dispatch]);

//   // Подписываемся на всех пользователей, чтобы обновлять список пользователей в online
//   // useEffect(() => {
//   //   const unsubscribe = subscribeToDocument(
//   //     "users",
//   //     null,
//   //     null,
//   //     async (data) => {
//   //       // dispatch(setUsers(data));
//   //       // dispatch(fetchModerationDataThunkV2());
//   //     }
//   //   );
//   //   return () => {
//   //     console.log("unsubscribe all users");
//   //     unsubscribe();
//   //   };
//   // }, [dispatch]);

//   // useEffect(() => {
//   //   dispatch(fetchDirectoryThunk());
//   // }, [dispatch]);

//   // const currentSideBarItem = useSelector(
//   //   (state) => state.stateGUISlice.currentSideBarItem
//   // );

//   // const ChapterUi = () => {
//   //   switch (currentSideBarItem) {
//   //     case undefined:
//   //     case "/rentalPoints":
//   //     // return <UIRentalPointV3 />;
//   //     case "/orders":
//   //       return <UIOrders />;
//   //     case "/bikes":
//   //       return <UIBikes />;
//   //     case "/brand":
//   //       return <UIBrand />;
//   //     case "/currency":
//   //       return <UICurrency />;
//   //     case "/category":
//   //       return <UIСategoriesBike />;
//   //     case "/country":
//   //       return <UICountry />;
//   //     case "/city":
//   //       return <UICity />;
//   //     case "/faq":
//   //       return <UIFAQ />;
//   //     case "/editNews":
//   //       return <UINews />;
//   //     case "/stories":
//   //       return <UIStories />;
//   //     case "/chatbotGPT":
//   //       // Плагин для firebase
//   //       return <ChatbotGPTV2 />;

//   //     // Интеграция с GPT
//   //     // return <OpenAi />;

//   //     // Интеграция с ассистентом
//   //     // return <BikeTripsterAssistant />;

//   //     case "/generateJSON":
//   //       return <GeterateJSON />;
//   //     case "/translater":
//   //       return <Translater />;
//   //     default:
//   //       return (
//   //         <div
//   //           style={{
//   //             // width: "100%",
//   //             // height: "100vh",
//   //             display: "flex",
//   //             alignItems: "center",
//   //             justifyContent: "center",
//   //           }}
//   //         >
//   //           <p className="p-light">Page not found.</p>
//   //         </div>
//   //       );
//   //   }
//   // };

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         width: "100%",
//         boxSizing: "border-box",
//       }}
//     >
//       {/* <div
//         style={{
//           position: "fixed",
//           top: "80px",
//           left: "0",
//           height: "100%",
//         }}
//       >
//         <SideBarV3 />
//       </div> */}

//       <div
//         style={{
//           paddingTop: "80px",
//           maxWidth: "var(--width-mobile-content)",
//           width: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "flex-start",
//           gap: "8px",
//           boxSizing: "border-box",
//         }}
//       >
//         {userRole === "admin" && (
//           <>
//             <WidgetUserPreview
//               isLoading={
//                 widgetSignInLoadingFetchUsers ||
//                 loading_update_auth_userRootCurrentData
//               }
//               user={user_auth_rootCurrentData}
//             />

//             <WidgetModeration
//               isLoading={
//                 loading_admin_subscribe_previewData ||
//                 loading_update_auth_userRootCurrentData
//               }
//               usersModeration={users_admin_subscribe_previewData}
//             />

//             <WidgetOrders orders={orders} isLoading={isLoadingOrders} />

//             <WidgetDirectory />
//           </>
//         )}

//         {userRole === "rental" && (
//           <>
//             <WidgetUserPreview
//               isLoading={widgetSignInLoadingFetchUsers}
//               user={user_auth_rootCurrentData}
//             />
//             <WidgetRentalPointsV4
//               isLoading={isLoading}
//               rentalPointsList={rentalPointsList}
//             />
//             <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} />
//             <WidgetDirectory />
//           </>
//         )}

//         {userRole === "client" && (
//           <>
//             <WidgetUserPreview
//               isLoading={widgetSignInLoadingFetchUsers}
//               user={user_auth_rootCurrentData}
//             />
//             <WidgetRentalOffer user={user_auth_rootCurrentData} />
//             <WidgetOrders orders={userOrders} isLoading={isLoadingOrders} />
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default UIProfileV3;
