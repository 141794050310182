export function isDataStale(lastUpdated) {
  if (!lastUpdated) return true;

  const twelveHoursInMillis = 12 * 60 * 60 * 1000; // 12 часов в миллисекундах
  const currentTime = Date.now();

  // Проверяем, прошло ли больше 12 часов с момента последнего обновления
  const isStale = currentTime - lastUpdated > twelveHoursInMillis;
  console.log(
    `Проверка устаревания: lastUpdated = ${new Date(
      lastUpdated
    ).toISOString()}, текущие время = ${new Date(
      currentTime
    ).toISOString()}, прошло 12 часов = ${isStale ? "да" : "нет"}`
  );

  return isStale;
}

// Проверка приоритетного URL или первого URL в случае отсутствия приоритетного
export const getIconFileUrl = (files) => {
  if (!files || files.length === 0) return ""; // Если массив пуст или отсутствует

  const priorityFile = files.find((file) => file.priority === true); // Находим файл с приоритетом
  return priorityFile ? priorityFile.url : files[0].url; // Если приоритетный есть - берем его, иначе первый элемент
};
