import React, { useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import WidgetAlert from "../WidgetsProfile/Alerts/WidgetAlert";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderFiltersBikesListV4 from "./WindowOrderFiltersBikesListV4";
import WindowOrderDetailBikeInfoV4 from "./WindowOrderDetailBikeInfoV4";
import WindowOrderClientV4 from "./WindowOrderClientV4";

const WindowOrderCreateNewOrderV4 = ({ onClose }) => {
  // Windows
  const [windowOrderFiltersBikesListV4, setWindowOrderFiltersBikesListV4] =
    useState(false);
  const [windowOrderForUserV4, setWindowOrderForUserV4] = useState(false);

  const [windowOrderDetailBikeInfoV4, setWindowOrderDetailBikeInfoV4] =
    useState(false);
  /**
   *
   * Логика компонента
   *
   */

  const handleWindowOrderDetailBikeInfoV4 = () => {
    setWindowOrderDetailBikeInfoV4(true);
  };

  const handleWindowOrderFiltersBikesListV4 = () => {
    setWindowOrderFiltersBikesListV4(true);
  };
  const handleClose = () => {
    setWindowOrderFiltersBikesListV4(false);
    setWindowOrderDetailBikeInfoV4(false);
    setWindowOrderForUserV4(false);
  };

  const handleOpenWindowOrderForUserV4 = () => {
    setWindowOrderForUserV4(true);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowOrderForUserV4}
        onClose={handleClose}
        title={`Order for user`}
      >
        <WindowOrderClientV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderFiltersBikesListV4}
        onClose={handleClose}
        title={`Create order`}
      >
        <WindowOrderFiltersBikesListV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderDetailBikeInfoV4}
        onClose={handleClose}
        title={`Create order`}
      >
        <WindowOrderDetailBikeInfoV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          // marginBottom: "72px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <ContentBox position="one">
            <h1 onClick={handleOpenWindowOrderForUserV4}>Client data</h1>
          </ContentBox>
          <ContentBox position="start">
            <h1>Client orders</h1>
            <h5>Send a link to the order for payment by the user</h5>
          </ContentBox>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <ContentBox position="end">
            <h1 onClick={handleWindowOrderFiltersBikesListV4}>Filters</h1>
          </ContentBox>
          <ContentBox position="one">
            <h1>Bikes List</h1>
            <h5 onClick={handleWindowOrderDetailBikeInfoV4}>Bike item</h5>
          </ContentBox>
        </div>
      </div>
      {/* <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={() => onClose()}
            color="--color-black"
          />

          <Button
            type="small"
            label="Save"
            active={true}
            // onClick={() => handleCreateOrUpdateData()}
            allert={
              <WidgetAlert
                title={`Are you sure you want to add the page to the directory?`}
              />
            }
          />
        </div>
      </ButtonsBar> */}
    </>
  );
};

export default WindowOrderCreateNewOrderV4;
